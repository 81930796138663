.mb-card {
    transition: 0.3s all linear;
    color: @mb-card-color;
    border-radius: @mb-card-border-radius;
    overflow: hidden;

    background-color: @mb-card-background;
    border: @mb-card-border-width solid @mb-card-border-color;

    &-image {
        position: relative;
        border: @mb-card-image-border-width solid @mb-card-image-border-color;
        border-radius: @mb-card-image-border-radius;
        box-shadow: @mb-card-image-shadow;
        overflow: hidden;

        span {
            position: absolute;
            right: @mb-card-image__inner-spacing;
            bottom: @mb-card-image__inner-spacing;
            font-size: @mb-card-image__inner-font-size;
            line-height: @mb-card-image__inner-line-height;
        }

        &--success {
            border-color: @mb-card-image-color--success;

            span {
                color: @mb-card-image-color--success;
            }
        }

        &--error {
            border-color: @mb-card-image-color--error;

            span {
                color: @mb-card-image-color--error;
            }
        }
    }

    &-on-light {

        &-elevated {
            color: @mb-card-color;
            border-radius: @mb-card-border-radius;
            box-shadow: @mb-card-shadow--elevated;
            border: @mb-card-border-width solid  @mb-card-border-color--elevated;

            &:hover,
            &:focus {
                border-color: @mb-card-border-color--elevated-hover;
                transition: 0.3s all linear;
            }

            &:hover {
                box-shadow: @mb-card-shadow--elevated-hover;
                color: @mb-mark-color;
            }
        }

        &-default {
            background-color: @mb-card-background--inactive;
            border: @mb-card-border-width solid @mb-card-border-color--inactive;

            &:hover,
            &:focus {
                transition: 0.3s all linear;
            }

            &:hover {
                background-color: @mb-card-background--hover;
            }

            &:focus {
                border-color: @mb-card-border-color--hover;
            }
        }
    }
}