// Overrides for properties defined in mixin
.@{ant-prefix}-select {
    &-focused {
        &.@{ant-prefix}-select-single:not(.@{ant-prefix}-select-customize-input) .@{ant-prefix}-select-selector {
            box-shadow: none;
        }
    }
}

.@{ant-prefix}-input-affix-wrapper,
.@{ant-prefix}-input {
    &:focus,
    &-focused {
        box-shadow: none;
    }
}