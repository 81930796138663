@mb-drawer-side-margins: @mb-spacing[xl];
@mb-drawer-max-width-desktop: 450px;
@mb-drawer-max-width-tablet: 66.66%;
@mb-drawer-spacing: @mb-spacing[xl];

.ant-drawer {

    // Overriding set width so the drawer could be responsive.
    // Actual width is set as max-width.
    &-content-wrapper {
        width: 100% !important;
        max-width: calc(100% - @mb-drawer-side-margins);

        .ant-drawer-header {
            padding: @mb-drawer-spacing;
            text-align: center;
            border-radius: 0;
        }

        .ant-drawer-close {
            position: absolute;
            padding: 0;
            margin: 0;
            top: @mb-drawer-spacing;
            right: @mb-drawer-spacing;
        }

        @media screen and (min-width: @screen-md) {
            max-width: @mb-drawer-max-width-tablet;
        }

        @media screen and (min-width: @screen-lg) {
            max-width: @mb-drawer-max-width-desktop;
        }
    }
}