// Custom menu styling that makes links look like tabs
// Used as part of the page header
.mb-tab-menu {
    line-height: 0;

    &:not(.ant-menu-dark) {

        // Default item styling - only first level since tabs aren't expected to have submenus
        .@{menu-prefix-cls}-item {
            padding: @mb-menu-tab-spacing-y 0;
            margin-right: @mb-menu-tab-spacing-between;
            margin-left: 0 !important;
            color: @mb-menu-tab-color;
            font-weight: @mb-menu-tab-font-weight;
            font-size: @mb-menu-tab-font-size;
            line-height: @mb-menu-tab-line-height;
            border-bottom: @mb-menu-tab-indicator-size solid transparent !important;
            transition: @mb-menu-tab-transition;

            // Hover and selected styles
            &:hover,
            &-selected {
                color: @mb-menu-tab-color--active;
                border-bottom: @mb-menu-tab-indicator-size solid @mb-menu-tab-indicator-color--active  !important;
                transition: @mb-menu-tab-transition;
            }

            &.@{menu-prefix-cls}-item-selected {
                &:hover {
                    cursor: initial;
                }
            }

            // Disabled items - !important used because Ant uses !important, too :(
            &.@{menu-prefix-cls}-item-disabled {
                color: @mb-menu-tab-color--disabled  !important;
                border-color: transparent;

                &:hover {
                    color: @mb-menu-tab-color--disabled  !important;
                    border-color: transparent;
                }
            }
        }

        // Hide Ant's default active tab indicator
        >.@{menu-prefix-cls}-item,
        >.@{menu-prefix-cls}-submenu {

            &:hover,
            &-active,
            &-open,
            &-selected {
                &::after {
                    display: none;
                }
            }
        }
    }

    // When there are more tabs than visible, this item appears
    &.@{menu-prefix-cls}-horizontal:not(.@{menu-prefix-cls}-dark)>.@{menu-prefix-cls}-overflowed-submenu {
        line-height: @mb-menu-tab__overflow-icon-size;
        margin: 0;
        padding: 0 @mb-menu-tab__overflow-icon-spacing-x;
        top: 0;

        .mbi-dots {
            color: @mb-menu-tab__overflow-icon-color;
        }
    }
}

// Used on charts
.mb-tab-menu--pills {
    &.ant-menu.ant-menu-horizontal:not(.ant-menu-dark) {
        border: none;
        margin-top: @mb-spacing[xl];

        >.ant-menu {

            &-item,
            &-submenu {
                top: 0;
                margin-top: 0;
                padding: 0 @mb-spacing[md];
                line-height: @btn-height-base;
                color: @mb-gray[500];
                font-weight: 500;

                &:after {
                    display: none;
                }
            }

            &-item-selected {
                background-color: @mb-cobalt[100];
                border-radius: @mb-spacing[xxs];
                color: @mb-primary-blue-color;
            }
        }
    }

    &.ant-radio-group {
        line-height: @btn-height-base;

        .ant-radio-button-wrapper {
            border: none;
            color: @mb-gray[500];
            font-weight: 500;

            &:before {
                display: none;
            }

            &,
            &:first-child,
            &:last-child {
                border-radius: @mb-spacing[xxs];
            }

            &-checked:not(.ant-radio-button-wrapper-disabled) {
                background-color: @mb-cobalt[100];
                color: @mb-primary-blue-color;
            }

            &:not(.ant-radio-button-wrapper-disabled) {

                &:hover,
                &:focus-within {
                    box-shadow: none;
                    border: none;
                }
            }
        }
    }
}