// Adjustments for radio component
.@{ant-prefix}-radio {
    &-wrapper {
        line-height: @mb-radio-border-line-height;
        align-items: flex-start;

        .@{ant-prefix}-radio {
            line-height: inherit;
            top: 0;

            &-inner {
                box-shadow: @mb-radio-shadow;
            }
        }

        &:hover {
            cursor: pointer;

            .@{ant-prefix}-radio,
            .@{ant-prefix}-radio-inner {
                border-color: @mb-radio-border-color--hover;
            }

            .@{ant-prefix}-radio-checked {
                .@{ant-prefix}-radio-inner {
                    border-color: transparent;
                    box-shadow: @mb-radio-shadow--checked-hover;
                }
            }
        }
    }

    &-input:focus+.@{ant-prefix}-radio {
        box-shadow: @mb-radio-button-outline--focus;

        &-inner {
            border-color: @mb-radio-border-color--focus;
        }
    }

    &-checked {
        .@{ant-prefix}-radio-inner {
            border-color: @mb-radio-background--checked  !important;
            background-color: @mb-radio-background--checked;

            &:after {
                background-color: @mb-radio__knob-background--checked;
            }
        }

        .@{ant-prefix}-radio-input:focus+.@{ant-prefix}-radio-inner {
            border-color: @mb-radio-border-color--checked-focus;
        }
    }
}

.@{form-prefix-cls} {

    input[type='file'],
    input[type='radio'],
    input[type='checkbox'] {
        &:focus {
            outline: none;
            border-color: @mb-radio-border-color--focus;
        }
    }
}

.ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon, 
.ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon, 
.ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon, 
.ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon {
    line-height: 14px;
}