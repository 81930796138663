// Adjustments for tags
.@{tag-prefix-cls} {
    border-radius: @mb-tag-border-radius;
    background-color: @mb-tag-background;
    border-color: @mb-tag-border-color;
    font-size: @mb-tag-font-size;
    line-height: @mb-tag-line-height;
    padding: @mb-tag-spacing-y @mb-tag-spacing-x;

    [class^="mbi-"],
    [class*=" mbi-"] {
        float: right;
        font-size: @mb-text-paragraph-size;
        line-height: @mb-text-caption-line-height;
        margin-left: @mb-spacing[xxs];
    }

    span {
        float: right;
    }

    // Success variant
    &.@{tag-prefix-cls}-success {
        background-color: @mb-tag-background--success;
        color: @mb-tag-color--error;
    }

    // Error variant
    &.@{tag-prefix-cls}-error {
        background-color: @mb-tag-background--error;
        color: @mb-tag-color--error;
    }

    // Temporary warning variant
    &.@{tag-prefix-cls}-warning {
        background-color: @mb-tag-background--warning;
        color: @mb-tag-color--error;
    }

    // Temporary info/processing variant
    &.@{tag-prefix-cls}-processing {
        background-color: @mb-tag-background--processing;
        color: @mb-tag-color--error;
    }
}