.@{radio-prefix-cls}-button-wrapper {
    border-color: @mb-radio-btn-border-color;

    &:not(:first-child) {
        &::before {
            background-color: @mb-radio-btn-background;
        }
    }

    &:hover {
        color: @mb-radio-btn-color--hover;
        border-color: @mb-radio-btn-border-color--hover;
    }

    .@{radio-group-prefix-cls}-solid &-checked:not(&-disabled),
    .@{radio-group-prefix-cls} &-checked:not(&-disabled) {
        background: @mb-radio-btn-background--checked;
        border-color: @mb-radio-btn-background--checked;
        color: @mb-radio-btn-color--checked;

        &:hover {
            color: @mb-radio-btn-background--hover-checked;
            box-shadow: @mb-radio-btn-shadow--hover-checked;
        }

        &:active {
            box-shadow: none;
        }

        &:focus-within {
            box-shadow: @mb-radio-btn-shadow--hover-checked;
            border: @mb-radio-btn-border-width--focus-checked solid @mb-radio-btn-border-color--focus-checked;
        }
    }

    &-disabled {
        color: @mb-radio-btn-color;
        background-color: transparent;
        border-color: @mb-radio-btn-border-color;
        cursor: not-allowed;
        opacity: 0.5;

        &:first-child {
            border-left-color: @border-color-base;
        }

        &:hover {
            background: transparent;
            color: @mb-radio-btn-color;
        }

    }

    &-disabled&-checked {
        color: @mb-radio-btn-color--checked;
        background-color: @mb-radio-btn-background--checked;
        border-color: @mb-radio-btn-border-color--checked;
        box-shadow: none;
        opacity: 0.5;
    }
}


 .@{radio-prefix-cls}-wrapper {
    &-disabled {
        .@{radio-prefix-cls} {
            &.@{radio-prefix-cls}-disabled {
                .@{radio-prefix-cls}-inner {
                    background-color: @mb-gray[200];
                    border-color: @mb-gray[300] !important;
                }
                &.@{radio-prefix-cls}-checked {
                    .@{radio-prefix-cls}-inner {
                        background-color: @mb-cobalt[300];
                        border-color: transparent !important;
                    }
                }
            }
        }
    }
} 