.@{timeline-prefix-cls} {
    &-item {
        &-head {
            width: @mb-timeline__head-size;
            height: @mb-timeline__head-size;
            border: none;
            background-color: @mb-timeline__head-background;
        }

        &-tail {
            left: @mb-timeline__tail-position;
            border-color: @mb-timeline__tail-color;
        }

        &-content {
            top: -(@mb-text-paragraph-line-height - @mb-timeline__head-size) / 2;
            margin-left: @mb-timeline__content-spacing-x;
        }
    }
}