// Modal adjustments
.@{dialog-prefix-cls} {
    // Width fix for dialogs
    width: 100% !important;
    max-width: @mb-modal-width + @mb-spacing[xl] * 2;
    // Top and bottom margins on mobile devices are small than on larger screens
    max-height: calc(100vh - (@mb-modal-page-margin-mobile * 2));
    top: @mb-modal-page-margin-mobile;
    display: flex;
    padding: 0 @mb-modal-spacing;
    margin: 0 auto;

    // CS-1801 bugfix - if a screen height is under 350px on mobile devices, 
    // there is no max height so header and footer are no longer pinned
    @media screen and (max-height: 350px) and (max-width: @screen-md) {
        min-height: @mb-modal-min-height-mobile;
        max-height: none;
        padding-bottom: @mb-modal-page-margin-mobile;
    }

    // Adjust positioning and make max width/height bigger for tablet and desktop screens
    @media screen and (min-width: @screen-md) {
        max-height: calc(100vh - (@mb-modal-page-margin * 2));
        top: @mb-modal-page-margin;
    }

    &-content {
        display: flex;
        flex-direction: column;
        height: inherit;
        width: 100%;
        border-radius: 8px;
    }

    // Modal header
    &-header {
        padding: @mb-modal-spacing;
        border: none;
        line-height: @mb-modal__title-line-height;
        font-size: @mb-modal__title-font-size;
        border-radius: 8px 8px 0px 0px;

        +.@{dialog-prefix-cls}-body {
            padding-top: 0;
        }
    }

    // Modal footer
    &-footer {
        padding-top: @mb-modal__footer-spacing;
        flex-direction: column-reverse;
        display: flex;
        justify-content: center;
        border-radius: 0px 0px 8px 8px;

        .@{ant-prefix}-btn {
            min-width: @mb-modal__footer-btn-min-width;
            margin-top: @mb-modal__footer-spacing;

            +.@{ant-prefix}-btn {
                margin: 0;
            }
        }

        @media screen and (min-width: @screen-md) {
            flex-direction: row;
            align-items: center;

            .@{ant-prefix}-btn {
                margin-top: 0;

                +.@{ant-prefix}-btn {
                    margin-left: @mb-modal__footer-spacing;
                }
            }
        }
    }

    &-header,
    &-footer {
        text-align: center;
        background-color: @mb-modal-background;

        &:extend(.mb-flex__child-sm);
    }

    // Modal body
    &-body {
        padding-bottom: @mb-spacing[xs];
        background-color: @mb-modal-background;
        &:extend(.mb-flex__child-lg);
        flex-basis: auto;
        overflow-y: auto;
    }

    // Modal title
    &-title {
        padding: 0 @mb-modal-spacing;
    }

    // Close icon
    &-close {
        right: @mb-modal-spacing;
        top: @mb-modal-spacing + 2px;

        &-x {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}