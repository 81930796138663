// These adjustments include some spaghetti code so we can
// have tooltips with arrows *and* borders.
// The original arrow is transparent but has a shadow that 
// imitates borders (spread and blur set to 1, offset 
// coordinates set to 0). We then utilize tooltip's *inner*
// child and give it the after pseudoelement that has the 
// has the same look and placement as the original arrow.
// This ensures the Shadow Arrow stays under original
// bubble, and the Light Arrow is on top so it overlays the
// border on the bubble.
.@{tooltip-prefix-cls} {
    @tooltip-arrow-shadow-width: 1px;
    @tooltip-arrow-rotate-width: sqrt(@tooltip-arrow-width * @tooltip-arrow-width * 2)+@tooltip-arrow-shadow-width * 2;

    &-arrow {

        // Set original arrow color to transparent
        &-content {
            background-color: transparent;
        }
    }

    // Change bubble's style to match Figma
    &-inner {
        position: relative;
        box-shadow: @mb-tooltip-light-shadow;
        border: @mb-tooltip-light-border-width solid @mb-tooltip-light-border-color;
        padding: @mb-tooltip-light-spacing;
        font-size: @mb-tooltip-light-font-size;
        line-height: @mb-tooltip-light-line-height;
        border-radius: @mb-tooltip-border-radius;

        // Define the light mimic arrow
        &:after {
            content: "";
            display: block;
            z-index: @zindex-tooltip + 1;
            position: absolute;
            top: auto;
            right: auto;
            bottom: auto;
            left: auto;
            width: @tooltip-arrow-rotate-width - 2px;
            height: @tooltip-arrow-rotate-width - 2px;
            background-color: @tooltip-arrow-color;
            pointer-events: auto;
        }
    }

    // Remove shadow from the original arrow and replace it
    // with one that imitates borders
    &-placement-top &-arrow,
    &-placement-topLeft &-arrow,
    &-placement-topRight &-arrow,
    &-placement-right &-arrow,
    &-placement-rightTop &-arrow,
    &-placement-rightBottom &-arrow,
    &-placement-left &-arrow,
    &-placement-leftTop &-arrow,
    &-placement-leftBottom &-arrow,
    &-placement-bottom &-arrow,
    &-placement-bottomLeft &-arrow,
    &-placement-bottomRight &-arrow {

        &-content {
            box-shadow: @mb-tooltip__arrow-shadow;
        }
    }

    // Placements for top, top-left and top-right arrows
    &-placement-top &-inner:after,
    &-placement-topLeft &-inner:after,
    &-placement-topRight &-inner:after {
        bottom: 0;
    }

    &-placement-top &-inner:after {
        left: 50%;
        transform: translate(-50%, calc(50% - 1px)) rotate(45deg);
    }

    &-placement-topLeft &-inner:after {
        left: 0;
        transform: translate(calc(100% + 2px), calc(50% - 1px)) rotate(45deg);
    }

    &-placement-topRight &-inner:after {
        right: 0;
        transform: translate(calc(-100% - 2px), calc(50% - 1px)) rotate(45deg);
    }

    // Placements for right, right-top and right-bottom arrows
    &-placement-right &-inner:after,
    &-placement-rightTop &-inner:after,
    &-placement-rightBottom &-inner:after {
        right: calc(100% + 1px);
        transform: translateX(calc(50% + 1px)) rotate(45deg);
    }

    &-placement-right &-inner:after {
        top: 50%;
        transform: translate(calc(50% + 1px), calc(-50% + 1px)) rotate(45deg);
    }

    &-placement-rightTop &-inner:after {
        top: @tooltip-arrow-offset-vertical + 1px;
    }

    &-placement-rightBottom &-inner:after {
        bottom: @tooltip-arrow-offset-vertical + 1px;
    }

    // Placements for left, left-top and left-bottom arrows
    &-placement-left &-inner:after,
    &-placement-leftTop &-inner:after,
    &-placement-leftBottom &-inner:after {
        left: calc(100% + 1px);
        transform: translateX(calc(-50% - 1px)) rotate(45deg);
    }

    &-placement-left &-inner:after {
        top: 50%;
        transform: translate(calc(-50% - 1px), calc(-50% + 1px)) rotate(45deg);
    }

    &-placement-leftTop &-inner:after {
        top: @tooltip-arrow-offset-vertical + 1px;
    }

    &-placement-leftBottom &-inner:after {
        bottom: @tooltip-arrow-offset-vertical + 1px;
    }

    // Placements for bottom, bottom-left and bottom-right arrows
    &-placement-bottom &-inner:after,
    &-placement-bottomLeft &-inner:after,
    &-placement-bottomRight &-inner:after {
        top: 0;
    }

    &-placement-bottom &-inner:after {
        left: 50%;
        transform: translate(-50%, calc(-50% + 1px)) rotate(45deg);
    }

    &-placement-bottomLeft &-inner:after {
        left: 0;
        transform: translate(calc(100% + 2px), calc(-50% + 1px)) rotate(45deg);
    }

    &-placement-bottomRight &-inner:after {
        right: 0;
        transform: translate(calc(-100% - 2px), calc(-50% + 1px)) rotate(45deg);
    }
}