@mb-responsive-table-prefix: mb-res-table;

// Responsive table
.@{mb-responsive-table-prefix} {
    margin-bottom: @mb-responsive-table__header-bottom-margin;
    box-shadow: @mb-responsive-table__header-shadow;

    // Table header cell
    &__header {
        &-cell {
            display: flex;
            position: relative;
            padding: @mb-responsive-table__header-spacing;
            background-color: @mb-responsive-table__header-background;
            color: @mb-responsive-table__header-color;
            font-size: @mb-responsive-table__header-font-size;
            font-weight: @mb-responsive-table__header-font-weight;
            text-transform: @mb-responsive-table__header-text-transform;

            // Divider between cells
            +.@{mb-responsive-table-prefix}__header-cell {
                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    width: @mb-responsive-table__header-divider-width;
                    height: @mb-responsive-table__header-divider-height;
                    left: 0;
                    top: calc(calc(100% - @mb-responsive-table__header-divider-height) / 2);
                    background: @mb-responsive-table__header-divider-color;
                }
            }

            .ant-typography {
                color: inherit;
            }

            // Apply border radius to first and last cell
            &:first-of-type {
                border-radius: @mb-responsive-table__header-border-radius 0 0 @mb-responsive-table__header-border-radius;
            }

            &:last-of-type {
                border-radius: 0 @mb-responsive-table__header-border-radius @mb-responsive-table__header-border-radius 0;
            }
        }

        // Center cell text vertically
        &-title {
            line-height: @mb-responsive-table__header-height;
            height: auto;
            max-width: 100%;
            font-size: inherit;

            .mb-flex__child-sm();
        }

        // Inner wrapper - needed to align items as wanted and make ellipsis work
        &-inner-wrapper {
            position: relative;
            max-width: 100%;

            // Add padding that takes up as much space as the sort arrows - only added to sortable headers
            .@{mb-responsive-table-prefix}__header-title {
                padding-right: @mb-responsive-table__header-height + @mb-responsive-table__header-sort-spacing;
            }
        }
    }

    // Table header sort controls
    &__sort {
        position: absolute;
        right: 0;
        top: 50%;
        display: flex;
        flex-direction: column;
        height: @mb-responsive-table__header-height;
        transform: translateY(-50%);

        // Table header buttons that contain icons
        &-button {
            color: @mb-responsive-table__header-sort-color;
            line-height: @mb-responsive-table__header-height / 2;
            overflow: hidden;
            transition: @mb-responsive-table__header-sort-transition;
        }

        // Table header sort icons
        &-icon {
            display: block;
            width: @mb-responsive-table__header-height;

            &[class^="mbi-"],
            &[class*=" mbi-"] {
                line-height: @mb-responsive-table__header-height / 2;
                font-size: @mb-responsive-table__header-sort-arrow-size;
                text-align: center;
            }
        }

        // Active sort button
        &--active {
            color: @mb-responsive-table__header-sort-color--active;
        }

        // Icon cover on hover
        &-button:hover,
        &--active:hover {
            color: @mb-responsive-table__header-sort-color--hover;
        }
    }

    // Modifying class that aligns sort icons horizontally instead of vertically
    &--horizontal-sort {

        // Center cell text vertically
        .@{mb-responsive-table-prefix}__header-inner-wrapper {
            position: relative;
            max-width: 100%;

            // Added extra padding to fit both icons to the header
            .@{mb-responsive-table-prefix}__header-title {
                padding-right: @mb-responsive-table__header-height * 2 + @mb-responsive-table__header-sort-spacing;
            }
        }

        // Change flex direction for the sort control (container that holds buttons)
        .@{mb-responsive-table-prefix}__sort {
            flex-direction: row;

            // Adjust icon heights and line heights for icons and their buttons
            &-icon {
                width: @mb-responsive-table__header-height;

                &[class^="mbi-"],
                &[class*=" mbi-"] {
                    line-height: @mb-responsive-table__header-height;
                }
            }

            &-button {
                line-height: @mb-responsive-table__header-height;
            }
        }
    }

    // Show sort buttons only when header is hovered
    &--sort-on-hover {

        // Desktop size - 1200px
        @media screen and (min-width: @screen-xl) {
            .@{mb-responsive-table-prefix}__sort {
                visibility: hidden;
                opacity: 0;
                transition: .1s all linear;
            }

            .@{mb-responsive-table-prefix}__header:hover {

                .@{mb-responsive-table-prefix}__sort {
                    visibility: visible;
                    opacity: 1;
                }
            }

            // For sort-on-hover variant, hide the padding under the arrows until the header is hovered
            .@{mb-responsive-table-prefix}__header-inner-wrapper {
                .@{mb-responsive-table-prefix}__header-title {
                    padding-right: 0;

                    // Hover off transition
                    transition: .1s all .2s linear;
                }
            }

            // For sort-on-hover variant, add padding-right on hover and show arrows
            .@{mb-responsive-table-prefix}__header:hover,
            .@{mb-responsive-table-prefix}__header-cell--active {
                .@{mb-responsive-table-prefix}__header-inner-wrapper .@{mb-responsive-table-prefix}__header-title {
                    padding-right: @mb-responsive-table__header-height + @mb-responsive-table__header-sort-spacing;

                    // On hover transition
                    transition: .1s all linear;
                }

                .@{mb-responsive-table-prefix}__sort {
                    visibility: visible;
                    opacity: 1;

                    // On hover transition
                    transition: .1s all .15s linear;
                }
            }

            // Pin sort on cells where sort is active
            .@{mb-responsive-table-prefix}__header-cell--active {

                .@{mb-responsive-table-prefix}__sort {
                    visibility: visible;
                    opacity: 1;
                }
            }

            /////////////////////////////////////////

            // For sort-on-hover variant, add padding-right on hover and show arrows
            &.@{mb-responsive-table-prefix}--horizontal-sort {

                .@{mb-responsive-table-prefix}__header:hover,
                .@{mb-responsive-table-prefix}__header-cell--active {
                    .@{mb-responsive-table-prefix}__header-inner-wrapper .@{mb-responsive-table-prefix}__header-title {
                        padding-right: @mb-responsive-table__header-height * 2 + @mb-responsive-table__header-sort-spacing;
                    }
                }
            }
        }
    }

    // Remove default link text decoration on hover and focus
    &__row {

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }
}