.ant-list-item-action {
    margin-bottom: 0;

    > li:last-of-type {
        padding-right: 0;
    }

    .ant-btn-text {
        color: @primary-color;
    }
}