// Custom styles for Ant messages
.@{message-prefix-cls} {
    top: 0;
    padding: @mb-message-spacing;

    .@{message-prefix-cls} {
        &-notice {
            text-align: right;

            +.@{message-prefix-cls}-notice {
                padding-top: @mb-message__notice-spacing;
            }

            &-content {
                box-shadow: none;
                padding: 0;
                text-align: left;
                max-width: @mb-message__notice-max-width;
            }
        }

        &-custom-content {
            padding: @mb-message__content-spacing;
            display: flex;
            align-items: flex-start;
            font-size: @mb-message__font-size;
            line-height: @mb-message__line-height;
            box-shadow: @mb-message__shadow;

            // CS-1848 Word-break added so long words in messages don't overflow
            span {
                word-break: break-word;
            }
        }
    }
}