.mb-flex__child-lg() {
    flex-basis: 0;
    flex-shrink: 1;
    flex-grow: 1;
    min-height: 0;
    min-width: 0
}

.mb-flex__child-sm() {
    flex-basis: auto;
    flex-shrink: 0;
    flex-grow: 0;
    min-height: 0;
    min-width: 0
}

.mb-flex__child--nested() {
    flex-basis: auto;
}