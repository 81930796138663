@mb-nav-classname: mb-nav;




// Main responsive navigation - mobile first, expanded by default
.@{mb-nav-classname} {
    position: fixed;
    z-index: 990; // setting z-index just under modals according to Ant variables
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    max-height: @mb-nav-collapsed-size;
    background-color: @mb-nav-background;
    color: @mb-nav-color;
    transition: all @animation-duration-base;
    flex-wrap: wrap;
    padding: @mb-nav-spacing-y-mobile @mb-nav-spacing-x-mobile;

    .@{mb-nav-classname}__item-wrapper {
        margin-top: @mb-nav__item-wrapper-spacing-between-y;
        padding: 0 @mb-nav__item-wrapper-spacing-x;
    }

    // Badge next to navigation items
    .@{badge-prefix-cls} {

        .@{badge-prefix-cls}-count {
            background-color: @mb-nav__badge-background;
            color: @mb-nav__badge-color;
            border-color: @mb-nav__badge-border-color;
            box-shadow: @mb-nav__badge-shadow;
            padding: 0;
            height: @mb-nav__badge-size;

            // Fix height for numbers in badges
            .ant-scroll-number-only,
            .ant-scroll-number-only>p.ant-scroll-number-only-unit {
                height: 100%;
            }

            &.ant-scroll-number {
                display: flex;
                justify-content: center;
            }

            p {
                font-size: @mb-nav__badge-font-size;
                line-height: @mb-nav__badge-line-height;
                font-weight: @mb-nav__badge-font-weight;
            }

            .ant-scroll-number-only-unit {
                color: inherit;
            }
        }

        &:not(.@{badge-prefix-cls}-not-a-wrapper) {
            .@{badge-prefix-cls}-count {
                display: none;
            }
        }
    }

    // Style and default background picture for profile picture avatars
    .ant-avatar-sm {
        background-image: @mb-nav__profile-avatar-image-url;
        color: inherit;
        width: @mb-nav__profile-avatar-size;
        height: @mb-nav__profile-avatar-size;
    }

    .mb-logo-navigation {
        margin: 0;
    }

    // Button for expanding navigation on desktop
    &__button-expand {
        opacity: 0;
        visibility: hidden;
        position: absolute;
        top: @mb-nav__expand-position-y;
        left: 100%;
        transform: translateX(-50%);

        &.@{btn-prefix-cls} {
            width: @mb-nav__expand-width;
            padding: @mb-nav__expand-spacing;
            border-radius: 50%;
            text-align: center;

            .mbi-chevron-left {
                font-size: @mb-nav__expand-font-size;
                line-height: @mb-nav__expand-line-height;
                transition: @mb-nav__expand-transition;
            }

            &,
            &:hover,
            &:focus {
                color: @mb-nav__expand-color--hover;
                background-color: @mb-nav__expand-background--hover;
                border: @mb-nav__expand-color-border-width--hover solid @mb-nav__expand-color-border-color--hover;
                box-shadow: @mb-nav__expand-color-shadow--hover;
            }
        }
    }

    // Expanded menu on mobile view
    &__content {
        position: fixed;
        top: @mb-nav__menu-mobile-position-y;
        left: @mb-nav__menu-mobile-position-x;
        bottom: 0;
        width: 100%;
        max-height: 100%;
        background-color: inherit;
        overflow-y: auto;
        transition: @mb-nav__menu-mobile-transition;

        &-item {
            transition: @mb-nav__menu-item-mobile-transition;
        }

        .@{mb-nav-classname}__item+.@{mb-nav-classname}__item {
            margin-top: @mb-nav__menu-item-mobile-spacing-between-y;
        }

        .@{mb-nav-classname}__divider {
            margin-left: @mb-nav__menu-divider-mobile-spacing-x;
            margin-right: @mb-nav__menu-divider-mobile-spacing-x;
        }
    }

    &__header,
    &__content {
        // .mb-flex__child-lg
        flex-basis: 0;
        flex-shrink: 1;
        flex-grow: 1;
        min-height: 0;
        min-width: 0;
    }

    &__header {
        position: relative;
        padding-left: @mb-nav__header-mobile-spacing-x;
    }

    .@{mb-nav-classname}__header .@{mb-nav-classname}__item-tooltip {
        margin-top: 0;
    }

    &__footer {
        background-color: @mb-nav__footer-mobile-background;
        height: 100%;
        display: flex;
        flex-direction: row;

        // .mb-flex__child-sm
        flex-basis: auto;
        flex-shrink: 0;
        flex-grow: 0;
        min-height: 0;
        min-width: 0;

        .@{mb-nav-classname}__item-icon {
            margin-right: 0;
        }

        .@{mb-nav-classname}__group {
            padding: 0;

            &-inner {
                position: fixed;
                top: @mb-nav__footer-mobile-menu-position-y;
                right: @mb-nav__footer-mobile-menu-position-x;
                width: 100%;
                height: auto;
                max-height: 0;
                padding: 0 @mb-nav__item-wrapper-spacing-x;
                transition: all @animation-duration-base;
                overflow-y: auto;

                .@{mb-nav-classname}__item {
                    margin-left: @mb-nav__item-wrapper-spacing-x;
                    margin-right: @mb-nav__item-wrapper-spacing-x;
                }

                &-title {
                    display: block;
                }
            }

            .@{mb-nav-classname}__item {
                +.@{mb-nav-classname}__item {
                    margin-top: @mb-nav__item-wrapper-spacing-between-y;
                }
            }

            &--opened {

                .@{mb-nav-classname}__group-inner {
                    padding: @mb-nav__item-wrapper-spacing-x;
                    max-height: 100vh;
                }
            }

            >.@{mb-nav-classname}__item {
                opacity: 1;
                visibility: visible;
            }

            &--external {
                .@{mb-nav-classname}__group-inner {
                    right: auto;
                    height: 100%;
                    max-height: 0;
                }

                &.@{mb-nav-classname}__group--opened {
                    max-height: calc(100vh - @mb-nav-collapsed-size);
                }
            }
        }

    }

    &__group {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;

        &-inner {
            position: relative;
            max-height: 0;
            padding: 0 @mb-spacing[md];
            background-color: @mb-nav__group-background;
            transition: @mb-nav__group-transition-open;

            .@{mb-nav-classname}__item-wrapper {
                padding: 0;
            }

            &-title,
            .@{mb-nav-classname}__item {
                opacity: 0;
                visibility: hidden;
                transition: @mb-nav__group-item-transition-open;
            }

            &-title {
                display: none;
                color: @mb-nav__group-title-color;
                font-weight: @mb-nav__group-title-font-weight;
                font-size: @mb-nav__group-title-font-size;
                line-height: @mb-nav__group-title-line-height;
                padding: @mb-nav__group-title-spacing;
            }

            .@{mb-nav-classname}__item {
                padding-left: @mb-nav__group-item-spacing-x;
                padding-right: @mb-nav__group-item-spacing-x;
            }
        }

        &.@{mb-nav-classname}__group--opened {

            .@{mb-nav-classname}__group-inner {
                max-height: 100vh;
                padding: @mb-nav__group-spacing-y @mb-nav__group-spacing-x;
                margin-top: @mb-nav__group-spacing-between;
                transition: @mb-nav__group-transition-close;
            }

            .@{mb-nav-classname}__group-inner-title,
            .@{mb-nav-classname}__item {
                opacity: 1;
                visibility: visible;
                transition: @mb-nav__group-item-transition-close;
            }
        }

        +.@{mb-nav-classname}__item {
            margin-top: @mb-nav__group-item-spacing-between;
        }
    }

    .@{mb-nav-classname}__item-tooltip {
        display: flex;
    }

    &__item {
        position: relative;
        display: flex;
        align-items: flex-start;
        width: 100%;
        padding: @mb-nav__item-spacing-y @mb-nav__item-spacing-x;
        background-color: @mb-nav__item-background;
        border-radius: @mb-nav__item-border-radius;
        color: @mb-nav__item-color;
        transition: all @animation-duration-base;
        font-weight: @mb-nav__item-font-weight;
        text-decoration: @mb-nav__item-text-decoration;

        &:visited,
        &:hover,
        &:active,
        &--active {
            color: @mb-nav__item-color--hover;
            background-color: @mb-nav__item-background--hover;
        }

        // Apply text decoration to navigation items
        a& {
            text-decoration: @mb-nav__item-text-decoration;
        }

        >span {
            display: flex;
        }

        &.@{mb-nav-classname}__item--selected {
            color: @mb-nav__item-color--selected;
            background-color: @mb-nav__item-background--selected;
        }

        &-label {
            display: none;
        }

        &.@{mb-nav-classname}__item--with-label {
            align-items: center;
            padding-top: 0;
            padding-bottom: 0;

            .@{mb-nav-classname}__item-text {
                flex-direction: column;
            }

            .@{mb-nav-classname}__item-expand {
                align-items: center;
            }

            .@{mb-nav-classname}__item-label {
                display: block;
                color: @mb-nav__item-label-color;
                font-size: @mb-nav__item-label-font-size;
                line-height: @mb-nav__item-label-line-height;
                font-weight: @mb-nav__item-label-font-weight;
            }
        }

        &-icon,
        &-expand,
        &-badge {
            display: flex;
            // .mb-flex__child-sm
            flex-basis: auto;
            flex-shrink: 0;
            flex-grow: 0;
            min-height: 0;
            min-width: 0;
        }

        &-icon {
            position: relative;
            margin-right: @mb-nav__item-icon-spacing-between;
            font-size: @mb-nav__item-icon-size;

            &--collapsed {
                display: none;
            }
        }

        &-badge {
            padding-left: @mb-nav__item-badge-spacing-between;
            margin-top: @mb-spacing[xxs] * 0.5;
        }

        &--search {
            padding: 0;

            &:hover {
                background-color: transparent;
            }
        }

        &-input {
            width: 100%;

            .ant-input-affix-wrapper,
            .ant-input {
                background-color: @mb-nav__item-search-background;
                color: @mb-nav__item-search-color;
            }

            .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled) {
                width: 100%;
                padding: @mb-spacing[xxs] * 1.25 @mb-spacing[xs];
                border-color: @mb-nav__item-search-border-color;
                font-size: @mb-nav__item-search-font-size;
                line-height: @mb-nav__item-search-line-height;
                border-radius: @mb-nav__item-search-border-radius;
                box-shadow: @mb-input-shadow;
                outline: 3px solid transparent;
                border: 1px solid @mb-gray[200];

                &:hover {
                    outline-color: @mb-gray[700];
                    border-color: @mb-gray[400];
                }
                &.ant-input-affix-wrapper-focused, &:focus {
                    outline-color: @mb-cobalt[900];
                    border-color: @mb-primary-blue-color;
                }
            }

            .ant-input-prefix {
                margin-right: @mb-nav__item-search-icon-spacing-between;
                color: @mb-nav__item-search-color;
            }

            .ant-input {
                border-radius: inherit;
                line-height: inherit;
            }

            ::placeholder,
            :-ms-input-placeholder,
            ::-ms-input-placeholder {
                color: @mb-nav__item-search-placeholder-color;
            }
        }

        &-expand {
            color: @mb-nav__group-expand-color;

            .anticon {
                font-size: @mb-nav__group-expand-font-size;
                line-height: @mb-nav__group-expand-line-height;
            }
        }

        &-text {

            // .mb-flex__child-lg
            flex-basis: 0;
            flex-shrink: 1;
            flex-grow: 1;
            min-height: 0;
            min-width: 0;
        }

        &--logo {
            display: block;
            padding: 0;

            &:visited,
            &:hover,
            &:active,
            &--active {
                background-color: transparent;

                .mb-logo-navigation {
                    color: @mb-nav__item-logo-color;
                    background-color: @mb-nav__item-logo-background;
                }
            }
        }

        &--badge-responsive {
            .@{mb-nav-classname}__item-badge {
                .@{badge-prefix-cls}.@{badge-prefix-cls}-not-a-wrapper {
                    position: absolute;
                    bottom: 50%;
                    left: 50%;
                }
            }
        }
    }

    &__divider {
        background-color: @mb-nav__divider-border-color;
        height: @mb-nav__divider-border-width;

        +.@{mb-nav-classname}__item {
            margin-top: 0;
        }
    }

    &__hidden {
        &\@xxs {
            display: none;
        }
    }

    // Collapsed navigation on mobile screens and up
    &--collapsed {
        max-height: @mb-nav-collapsed-size;
        transition: all @animation-duration-base;

        .@{mb-nav-classname} {
            &__button-expand {
                .mbi-chevron-left {
                    transform: rotate(180deg);
                    transition: all @animation-duration-base;
                }
            }

            &__content {
                max-height: 0;
                padding: 0;
                overflow-y: auto;
                overflow-x: hidden;
                transition: 0.3s max-height 0.25s linear, 0.3s padding 0.25s linear;

                &-item {
                    opacity: 0;
                    visibility: hidden;
                    transition: 0.2s opacity linear, 0.2s visibility linear;
                }
            }

            &__item {
                &-icon {
                    &--collapsed {
                        display: flex;
                    }

                    &--expanded {
                        display: none;
                    }
                }
            }
        }
        .ant-input-affix-wrapper {
            &:hover, &-focused {
                outline-color: transparent;
                border-color: transparent;
            }
        }
    }

    @media screen and (max-width: @screen-lg-max) {

        &__header,
        &__footer {
            .@{mb-nav-classname}__item-wrapper {
                padding: 0;
                margin-top: 0;
            }

            .@{mb-nav-classname}__group--opened {
                .@{mb-nav-classname}__group-inner {
                    margin-top: 0;
                }
            }
        }

        .@{mb-nav-classname}__item--search {
            .ant-input-affix-wrapper {
                padding: 8px;
            }

            .ant-input {
                font-size: @mb-nav__item-search-font-size;
            }
        }

        .@{mb-nav-classname}__item:hover {
            transition: none;
        }
    }

    @media screen and (min-width: @screen-md) {

        // 768px
        .@{mb-nav-classname} {
            &__content {
                left: auto;
                right: 0;
                bottom: 0;
                max-width: @mb-nav-expanded-tablet-size;
            }

            &__hidden {
                &\@xxs {
                    display: flex;
                }

                &\@xs {
                    display: none;
                }
            }

            .@{mb-nav-classname}__group {

                &--opened {
                    .@{mb-nav-classname}__group-inner {
                        padding: @mb-nav__item-wrapper-spacing-x;
                    }
                }
            }

            &__footer {

                .@{mb-nav-classname}__group {
                    &-inner {
                        position: fixed;
                        top: @mb-nav-collapsed-size;
                        width: 100%;
                        max-width: @mb-nav-expanded-tablet-size;
                        margin-top: 0;
                        padding: 0 @mb-nav__footer-dropdown-spacing-x;
                        height: auto;
                        box-shadow: @mb-nav__footer-dropdown-shadow;

                        &-title {
                            display: block;
                        }


                        .@{mb-nav-classname}__item,
                        .@{mb-nav-classname}__group {

                            +.@{mb-nav-classname}__item,
                            +.@{mb-nav-classname}__group {
                                margin-top: @mb-nav__footer-dropdown-item-spacing-between;
                                margin-left: @mb-nav__footer-dropdown-item-spacing;
                            }
                        }
                    }

                    &--opened {
                        .@{mb-nav-classname}__group-inner {
                            padding: @mb-nav__footer-dropdown-spacing-y @mb-nav__footer-dropdown-spacing-x;
                            max-height: calc(100vh - @mb-nav-collapsed-size);

                            .@{mb-nav-classname}__item-group-title,
                            .@{mb-nav-classname}__item {
                                opacity: 1;
                                visibility: visible;
                            }
                        }
                    }
                }
            }
        }
    }

    // Desktop size - 1200px
    @media screen and (min-width: @screen-xl) {
        flex-direction: column;
        max-width: @mb-nav-expanded-size;
        max-height: 100%;
        padding: 0;
        transition: .1s all linear;

        .@{mb-nav-classname}__item-tooltip {
            .@{mb-nav-classname}__item-wrapper {
                margin-top: 0;
            }
        }

        .@{mb-nav-classname}__item-content {
            min-width: 200px;
        }

        &:hover {
            .@{mb-nav-classname}__button-expand {
                opacity: 1;
                visibility: visible;
            }
        }

        .@{mb-nav-classname} {

            &__header,
            &__footer {
                height: auto;
                width: 100%;
            }

            &__header {
                max-height: none;
                padding: @mb-nav__header-desktop-spacing-y 0;
                // .mb-flex__child-sm
                flex-basis: auto;
                flex-shrink: 0;
                flex-grow: 0;
                min-height: 0;
                min-width: 0;
            }

            &__content {
                position: relative;
                top: auto;
                right: auto;
                bottom: auto;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                max-width: 100%;
                max-height: none;
                overflow-x: hidden;

                &-item {
                    // .mb-flex__child-sm
                    flex-basis: auto;
                    flex-shrink: 0;
                    flex-grow: 0;
                    min-height: 0;
                    min-width: 0;
                }

                .@{mb-nav-classname}__item {
                    margin-left: 0;
                    margin-right: 0;
                }
            }

            &__group {
                .@{mb-nav-classname}__group-inner {
                    z-index: -1;
                    visibility: hidden;
                    opacity: 0;
                    position: fixed;
                    right: auto;
                    left: 0;
                    transform: translateX(-100%);
                    max-height: 100%;
                    width: @mb-nav__group-desktop-width;
                    padding: @mb-nav__group-desktop-spacing-y @mb-nav__group-desktop-spacing-x;
                    margin: 0;
                    box-shadow: @mb-nav__group-desktop-shadow;
                    transition: .3s left ease-out, .3s transform ease-out;

                    &-title {
                        padding-left: @mb-nav__group-title-spacing-x;
                        padding-right: @mb-nav__group-title-spacing-x;
                    }

                    &-inner {
                        transition: .2s opacity .2s linear, .2s visibility .2s linear;
                    }
                }

                &--opened {
                    .@{mb-nav-classname}__group-inner {
                        visibility: visible;
                        opacity: 1;
                        left: @mb-nav-expanded-size;
                        transform: translateX(0);
                        transition: .3s left ease-out, .3s transform ease-out;
                    }

                    .@{mb-nav-classname}__item-expand {
                        .anticon {
                            transform: rotate(0deg);
                        }
                    }
                }
            }

            &__footer {
                flex-direction: column;
                padding-top: 0;
                padding: 0 0 @mb-nav__footer-dropdown-spacing-y;

                .@{mb-nav-classname}__group {
                    max-height: 100%;

                    &.@{mb-nav-classname}__group--opened {
                        .@{mb-nav-classname}__group-inner {
                            padding: @mb-nav__footer-dropdown-desktop-spacing-y @mb-nav__footer-dropdown-desktop-spacing-x;
                        }

                        .@{mb-nav-classname}__item {
                            margin-left: 0;
                            margin-right: 0;
                        }
                    }

                    &-inner-title {
                        display: none;
                    }
                }
            }

            &__item {
                position: relative;

                +.@{mb-nav-classname}__item {
                    margin-top: @mb-nav__item-spacing-between;
                }

                &-icon {
                    margin-right: @mb-nav__item-icon-spacing-between;
                }

                // Expanding item content - text and arrows
                &-content {
                    visibility: visible;
                    opacity: 1;
                    max-width: 100%;
                    max-height: 100%;
                    transition: .3s opacity .2s linear;
                }

                &--logo {
                    margin-bottom: 0;
                }

                &--clear-margin {
                    margin-top: 0;
                }

                &-text {
                    display: block;
                }

                &-expand {
                    display: flex;
                }

                &--badge-responsive {
                    .@{mb-nav-classname}__item-badge {
                        .@{badge-prefix-cls}.@{badge-prefix-cls}-not-a-wrapper {
                            position: relative;
                            bottom: auto;
                            left: auto;
                        }
                    }
                }
            }

            &__divider {
                +.@{mb-nav-classname}__item {
                    margin-top: 0;

                    &.@{mb-nav-classname}__hidden\@sm+.@{mb-nav-classname}__item {
                        margin-top: 0;
                    }
                }
            }

            &__hidden {

                &\@xxs,
                &\@xs {
                    display: flex;
                }

                &\@sm {
                    display: none;
                }
            }
        }


        &.@{mb-nav-classname}--collapsed {
            max-width: @mb-nav-collapsed-size;
            transition: .1s all 0.2s linear; //@animation-duration-base;

            .@{mb-nav-classname} {

                &__content {
                    max-height: none;

                    &-item {
                        opacity: 1;
                        visibility: visible;
                    }
                }

                &__group {
                    &-inner-title {
                        display: block;
                    }

                    &--opened {
                        .@{mb-nav-classname}__group-inner {
                            left: @mb-nav-collapsed-size;
                        }
                    }
                }

                &__footer {
                    .@{mb-nav-classname}__item-icon {
                        margin-right: 0;
                    }
                }

                &__group-inner,
                .@{mb-nav-classname}__footer .@{mb-nav-classname}__group-inner {
                    left: @mb-nav-collapsed-size;

                    .@{mb-nav-classname}__item-content {
                        visibility: visible;
                        opacity: 1;
                        max-width: 100%;
                        max-height: 100%;
                    }

                    .@{mb-nav-classname}__item {

                        &-text,
                        &-expand {
                            display: block;
                        }
                    }
                }

                &__item {
                    .@{badge-prefix-cls}.@{badge-prefix-cls}-not-a-wrapper {
                        position: absolute;
                        bottom: 50%;
                        left: 50%;
                    }

                    &-icon {
                        margin-right: 0;
                        transition: .3s margin-right .1s linear;
                    }

                    &-content {
                        visibility: hidden;
                        opacity: 0;
                        max-width: 0;
                        max-height: 0;
                    }

                    &-text {
                        display: none;
                    }

                    &-input {
                        width: 100%;

                        .ant-input-affix-wrapper {
                            border-color: transparent;
                        }

                        .ant-input-affix-wrapper,
                        .ant-input {
                            background-color: transparent;
                        }
                    }

                    &--search {

                        &:hover {
                            background-color: @mb-nav__item-search-background--hover;
                            cursor: pointer;
                        }
                    }

                    &-badge {
                        padding: 0;
                    }
                }

                .@{badge-prefix-cls}:not(.@{badge-prefix-cls}-not-a-wrapper) {
                    .@{badge-prefix-cls}-count {
                        display: block;
                    }
                }
            }
        }
    }
}

// Tooltip visible on desktop when item is hovered
@media screen and (min-width: @screen-xl) {

    .mb-tooltip--dark&:not(.ant-tooltip-hidden) {
        display: block;
    }
}