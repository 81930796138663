@mb-list-item-min-height: 32px;
@mb-list-item-spacing-y: @mb-spacing[xxs];
@mb-list-item-spacing-x: @mb-spacing[md];
@mb-list-item-border-radius: @mb-spacing[xs];
@mb-list-item-background: @mb-gray[50];

@mb-list-item-background--primary: fade(@mb-primary-blue-color, 15%);
@mb-list-item-background--quinary: @mb-background-quinary-color;

@mb-list-item-background--elevated: @mb-background-primary-color;
@mb-list-item-border-width--elevated: 1px;
@mb-list-item-border-color--elevated: @mb-fill-primary-color;
@mb-list-item-shadow: @mb-shadow-dark-xxs;

// List items
///////////////////////////////////////////////////////////
// List item overrides
.mb-list-item {
    display: flex;
    align-items: center;
    min-height: @mb-list-item-min-height;
    padding: @mb-list-item-spacing-y @mb-list-item-spacing-x;
    font-weight: normal;
    border-radius: @mb-list-item-border-radius;
    background-color: @mb-list-item-background;

    +.mb-list-item {
        margin-top: @mb-list-item-spacing-y;
    }

    // Style variants according to Figma
    &--quinary {
        background-color: @mb-list-item-background--quinary;
    }

    &--primary {
        background-color: @mb-list-item-background--primary;
    }

    &--elevated {
        background-color: @mb-list-item-background--elevated;
        border: @mb-list-item-border-width--elevated solid @mb-list-item-border-color--elevated;
        box-shadow: @mb-list-item-shadow;
    }

    .ant-avatar {
        margin-right: @mb-spacing[md] / 2;
    }

    // Temporary visual fix until icons are set
    .ant-btn.ant-btn-icon-only .anticon-close svg {
        width: 12px;
        height: 12px;
    }

    // Empty state for list items
    &--empty {
        padding: @mb-spacing[md] @mb-spacing[md] @mb-spacing[xxs] * 2.5 @mb-spacing[md];
        flex-direction: column;
        min-height: 80px;
        border: 1px solid @mb-fill-quaternary-color;
        text-align: center;

        p {
            margin-bottom: @mb-spacing[xs];
        }
    }
}