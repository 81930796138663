@mb-header-classname: mb-header;

// Custom headers
/////////////////////////////////////////////////////////////////
.mb-content {

    // Custom content header for pages
    &-header {
        display: flex;
        align-items: center;
        height: @mb-content-header-height;
        width: 100%;
        padding: 0;
        border-bottom: @mb-content-header-border-width solid @mb-content-header-border-color;

        // Light theme 
        background-color: @mb-content-header-background;

        >.mb-sidebar-filter {
            border-right-color: transparent;
        }

        .mb-container {
            height: 100%;
        }

        &--filter {
            padding: @mb-content-header__filter-padding;
            border-bottom: none;

            // Light theme
            background-color: @mb-content-header__filter-background;
        }

        &--small {
            height: @mb-content-header-height--sm;
        }
    }

    // Custom content subheader for pages
    &-subheader {
        display: flex;
        align-items: center;
        height: @mb-subheader-height;
        width: 100%;

        // Light theme
        background-color: @mb-subheader-background;

        // Variant with border at the bottom
        &--border {
            border-bottom: @mb-subheader-border-width solid @mb-subheader-border-width;
        }

        .mb-sidebar-filter {
            background-color: transparent;
            border-right: none;
        }
    }
}

// New subheader
.mb-subheader {
    display: flex;
    align-items: center;
    height: @mb-subheader-height;
    width: 100%;

    // Light theme - default
    background-color: @mb-subheader-background;

    // Variant with border at the bottom
    &--bordered {
        border-bottom: @mb-subheader-border-width solid @mb-subheader-border-color;
    }

    &--primary {
        background-color: @mb-primary-variant-color[10];
    }

    &--info {
        background-color: @mb-info-variant-color[10];
    }

    &--success {
        background-color: @mb-success-variant-color[10];
    }

    &--warning {
        background-color: @mb-warning-variant-color[10];
    }

    &--error {
        background-color: @mb-error-variant-color[10];
    }

    .mb-container {

        &,
        >.ant-row {
            height: 100%;
        }
    }
}

// Back button for headers
.mb-header-back {
    display: flex;
    width: @mb-back-size;
    height: @mb-back-size;

    &:before {
        .mbi-chevron-left();
        line-height: inherit;
        font-size: @mb-back__icon-size;
    }

    @media screen and (min-width: @screen-sm) {
        &:before {
            .mbi-arrow-left();
            line-height: inherit;
        }
    }
}

// New headers
.@{mb-header-classname} {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    min-height: @mb-header-height;
    padding-top: @mb-header-spacing-y;

    &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        height: 1px;
        border-bottom: @mb-header-border-width solid @mb-header-border-color;
    }

    .ant-tabs {
        &-nav {
            margin-bottom: 0;
        }

        &-tab {
            padding: @mb-header__tab-spacing;
            margin-right: @mb-header__tab-space-between;

            &-btn {
                color: @mb-header__tab-color;
                font-size: @mb-header__tab-font-size;
                line-height: @mb-header__tab-line-height;

                &.ant-tabs-tab-active .ant-tabs-tab-btn {
                    font-weight: @mb-header__tab-font-weight;
                }
            }
        }



        &-top,
        &-bottom {

            &,
            >div {
                &:before {
                    border-bottom: none;
                }

                >.ant-tabs-nav {
                    .ant-tabs-ink-bar {
                        height: @mb-header__tab-highlight-size;
                    }
                }
            }
        }
    }

    &__main {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }

    &__title {
        display: block;
        align-items: center;
        font-size: @mb-header__title-font-size;
        line-height: @mb-header__title-line-height;
        color: @mb-header__title-color;

        // .mb-flex__child-sm
        flex-basis: auto;
        flex-shrink: 0;
        flex-grow: 0;
        min-height: 0;
        min-width: 0;

        &-icon {
            color: inherit;
            font-size: inherit;
            line-height: inherit;
            display: inline-flex;
            margin-right: @mb-header__title-icon-spacing-between;
        }

        &-status {
            display: flex;

            .ant-badge-status {
                &-dot {
                    margin-left: @mb-header__divider-spacing-x;
                }

                &-text {
                    margin-left: 0;
                }
            }
        }

        &-divider {
            padding: 0 @mb-header__divider-spacing-x;
        }

        &--breadcrumbs {
            color: @mb-breadcrumbs__secondary-color;

            .@{mb-header-classname}__title {

                &-current {
                    color: @mb-breadcrumbs__primary-color;
                }
            }
        }
    }

    &__favorite {
        align-self: flex-end;

        .ant-tag {
            display: flex;
            flex-direction: row-reverse;
            margin-top: @mb-header__tag-spacing-between;
            margin-bottom: 0;
            background-color: @mb-header__tag-background;

            span {
                font-size: @mb-header__tag-font-size;
                margin: 0;
            }

            // .anticon {
            //     font-size: @mb-text-paragraph-size;
            //     line-height: @mb-text-paragraph-line-height;
            //     margin-left: @mb-spacing[xxs];
            // }
        }

        &:hover {
            .ant-tag {
                background-color: @mb-header__tag-background--hover;
            }
        }

        &--favorited {
            .ant-tag {
                background-color: @mb-header__tag-background--active;

                .anticon {
                    color: @mb-header__tag-color--active;
                }
            }

            &:hover {
                .ant-tag {
                    background-color: @mb-header__tag-color--active-hover;
                }
            }
        }
    }

    @media screen and (min-width: 575px) {
        display: flex;

        .@{mb-header-classname}__main {
            flex-direction: row;
            align-items: center;
        }

        .@{mb-header-classname}__title {
            display: flex;

            // .mb-flex__child-lg
            flex-basis: 0;
            flex-shrink: 1;
            flex-grow: 1;
            min-height: 0;
            min-width: 0;
        }

        .@{mb-header-classname}__favorite {
            margin-top: 0;
            align-self: unset;

            .ant-tag {
                margin-top: 0;
            }
        }
    }
}