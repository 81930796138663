// Custom class for primary message
.mb-message-notice--primary {

    [class^="mbi-"],
    [class*=" mbi-"] {
        color: @mb-message__icon-color--primary;
    }
}

// Custom class for default message
.mb-message-notice--default {

    [class^="mbi-"],
    [class*=" mbi-"] {
        color: @mb-message__icon-color--default;
    }
}