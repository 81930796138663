// Styles that can be applied to containers
///////////////////////////////////////////////////////////
.mb-container {

    ///////////////////////////////////////////////////////////
    // Container backgrounds defined by shades of primary color
    // ---- no difference between light and dark variants ----
    &-background-primary {
        &-10 {
            background-color: @mb-primary-variant-color[10];
        }

        &-25 {
            background-color: @mb-primary-variant-color[25];
        }

        &-50 {
            background-color: @mb-primary-variant-color[50];
        }

        &-75 {
            background-color: @mb-primary-variant-color[75];
        }
    }

    ///////////////////////////////////////////////////////////
    // Container backgrounds defined by shades of error color
    &-background-error {

        // Light
        &-light {
            &-10 {
                background-color: @mb-error-variant-color[10];
            }

            &-25 {
                background-color: @mb-error-variant-color[25];
            }

            &-50 {
                background-color: @mb-error-variant-color[50];
            }

            &-75 {
                background-color: @mb-error-variant-color[75];
            }
        }
    }

    ///////////////////////////////////////////////////////////
    // Container backgrounds defined by shades of warning color
    &-background-warning {

        // Light
        &-light {
            &-10 {
                background-color: @mb-warning-variant-color[10];
            }

            &-25 {
                background-color: @mb-warning-variant-color[25];
            }

            &-50 {
                background-color: @mb-warning-variant-color[50];
            }

            &-75 {
                background-color: @mb-warning-variant-color[75];
            }
        }
    }

    ///////////////////////////////////////////////////////////
    // Container backgrounds defined by shades of success color
    &-background-success {

        // Light
        &-light {
            &-10 {
                background-color: @mb-success-variant-color[10];
            }

            &-25 {
                background-color: @mb-success-variant-color[25];
            }

            &-50 {
                background-color: @mb-success-variant-color[50];
            }

            &-75 {
                background-color: @mb-success-variant-color[75];
            }
        }
    }

    ///////////////////////////////////////////////////////////
    // Container backgrounds defined by shades of success color
    &-background-info {

        // Light
        &-light {
            &-10 {
                background-color: @mb-info-variant-color[10];
            }

            &-25 {
                background-color: @mb-info-variant-color[25];
            }

            &-50 {
                background-color: @mb-info-variant-color[50];
            }

            &-75 {
                background-color: @mb-info-variant-color[75];
            }
        }
    }

    ///////////////////////////////////////////////////////////
    // Container backgrounds defined by background colors
    &-background-primary {
        background-color: @mb-background-primary-color;
    }

    &-background-quaternary {
        background-color: @mb-background-tertiary-color; //@mb-background-quaternary-color;
    }

    &-background-quinary {
        background-color: @mb-background-quinary-color;
    }

    &-background-senary {
        background-color: @mb-background-senary-color;
    }

    ///////////////////////////////////////////////////////////
    // Brand gradients
    &-gradient-01 {
        background: linear-gradient(281.23deg, @mb-cobalt[600] -32.28%, @mb-cobalt[200] 31.6%, @mb-lime[100] 115.24%);
    }
}