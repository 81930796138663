// Dropdown for Ant menu
.ant-menu-submenu-popup {
    width: 100% !important;
    max-width: @mb-menu__dropdown-max-width;
    border-radius: @mb-menu__dropdown-border-radius;
    box-shadow: none;

    .ant-menu.ant-menu-sub {
        border-radius: @mb-menu__dropdown-border-radius;
        box-shadow: @mb-menu__dropdown-shadow;
    }

    .ant-menu-vertical.ant-menu-sub .ant-menu-item {
        margin: 0;
        padding: @mb-menu__dropdown-item-spacing-y @mb-menu__dropdown-item-spacing-x;
        height: @mb-menu__dropdown-item-height;
        line-height: @mb-menu__dropdown-item-line-height;

        &:first-of-type {
            margin-top: @mb-menu__dropdown-item-spacing-y;
        }

        &:last-of-type {
            margin-bottom: @mb-menu__dropdown-item-spacing-y * 2;
        }

        &.ant-menu-item-active,
        &.ant-menu-item-selected,
        &:hover {
            background: @mb-menu__dropdown-item-background--hover;
        }
    }

    .ant-menu-vertical.ant-menu-sub {
        .ant-menu-item-group {
            .ant-menu-item-group-title {
                padding: @mb-menu__dropdown-item-spacing-y / 2 @mb-menu__dropdown-item-spacing-x @mb-menu__dropdown-item-spacing-y;
                font-size: @mb-menu__dropdown-submenu-title-font-size;
                line-height: @mb-menu__dropdown-submenu-title-line-height;
                margin-top: @mb-spacing[xs];
            }

            .ant-menu-item-group-list .ant-menu-item:first-of-type {
                margin-top: 0;
            }
        }
    }
}