.mb-switch--inline {
    &.ant-row.ant-form-item {
        flex-direction: row;
        align-items: center;

        .ant-form-item-label {
            padding: 0;

            // .mb-flex-child-lg
            flex-basis: 0;
            flex-shrink: 1;
            flex-grow: 1;
            min-height: 0;
            min-width: 0
        }

        .ant-form-item-control {
            // .mb-flex-child-sm
            flex-basis: auto;
            flex-shrink: 0;
            flex-grow: 0;
            min-height: 0;
            min-width: 0
        }
    }
}