// Customizations for layout
///////////////////////////////////////////////////////////////////
// Customized layout
.mb-site-layout {

    &,
    .ant-layout-content {
        min-height: auto;
        height: 100%;

        @media screen and (min-width: 768px) {
            min-height: auto;
            height: 100%;
        }
    }
}


// Customized layout content classes
.mb-layout-content {
    padding-top: @mb-nav-collapsed-size;

    @media screen and (min-width: @screen-xl) {
        padding-top: 0;
        padding-left: @mb-nav-collapsed-size;
    }

    &--expanded-nav {

        @media screen and (min-width: @screen-xxl) {
            padding-top: 0;
            padding-left: @mb-nav-expanded-size;
        }
    }
}

// Customized sider (sidebar with filters)
.mb-sidebar {

    /////////////////////////////////////////////////////////
    // TODO: Define as FAB
    // Button that toggles sidebar on/off - not part of sidebar
    &-toggle {
        opacity: 1;
        visibility: visible;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50%;
        right: @mb-sidebar__toggle-spacing-between;
        padding: 0;
        width: @mb-sidebar__toggle-size;
        height: @mb-sidebar__toggle-size;
        background: @mb-sidebar__toggle-background;
        border: @mb-sidebar__toggle-border-size solid @mb-sidebar__toggle-border-color;
        border-radius: 50%;
        box-shadow: @mb-sidebar__toggle-shadow;
        transform: translateY(-50%);
        transition: all @animation-duration-base linear;

        >span {
            display: block;
            font-size: @mb-sidebar__toggle-font-size;
        }

        &:after {
            content: "";
            display: block;
            opacity: 0;
            visibility: hidden;
            position: absolute;
            top: 0;
            right: 0;
            width: @mb-sidebar__toogle-indicator-size;
            height: @mb-sidebar__toogle-indicator-size;
            border-radius: 50%;
            background-color: @mb-sidebar__toogle-indicator-background;
            box-shadow: @mb-sidebar__toogle-indicator-shadow;
            transition: all @animation-duration-base linear;
        }

        &:hover,
        &:focus {
            cursor: pointer;
            border-color: @mb-sidebar__toggle-border-color--hover;
        }

        &:hover {
            box-shadow: @mb-sidebar__toggle-shadow--hover;
        }

        &:focus {
            outline: none;
        }

        &--hidden {
            opacity: 0;
            visibility: hidden;
            transition: all @animation-duration-base linear;
        }

        &--active {
            &:after {
                opacity: 1;
                visibility: visible;
                transition: all @animation-duration-base linear;
            }
        }

        @media screen and (min-width: @screen-sm) {
            right: @mb-sidebar__toggle-spacing-between * 2;
        }


        @media screen and (min-width: (@screen-xxl + 100)) {
            // 576px
            top: @mb-sidebar__toggle-tablet-position-x;
            right: @mb-sidebar__toggle-tablet-position-y;
            left: auto;
            transform: none;
        }
    }

    /////////////////////////////////////////////////////////
    // Close button for sidebar
    &-filter__close {
        opacity: 1;
        visibility: visible;
        position: absolute;
        top: @mb-sidebar__close-position-y;
        right: @mb-sidebar__close-position-x;
        height: @mb-sidebar__close-icon-size;
        width: @mb-sidebar__close-icon-size;
        padding: 0;
        color: @mb-sidebar__close-icon-color;
        background-color: @mb-sidebar__close-icon-background;
        border: none;
        transition: @animation-duration-fast all linear;

        &:hover {
            color: @mb-sidebar__close-icon-color--hover;
        }

        &:focus {
            outline: none;
        }

        .mbi-x {
            line-height: @mb-sidebar__close-icon-size;
            font-size: @mb-sidebar__close-icon-line-height;
        }
    }

    /////////////////////////////////////////////////////////
    // Style for sidebar filters
    &-filter {
        opacity: 1;
        visibility: visible;
        position: absolute;
        right: auto;
        bottom: 0;
        right: 0;
        top: 0;
        display: flex;
        width: 100% !important;
        min-width: @mb-sidebar-width  !important;
        max-width: @mb-sidebar-width  !important;
        padding: 0;
        margin: 0;
        box-shadow: @mb-sidebar-shadow;

        // Sidebars are displayed above navigation on mobile and tablet
        // screen sizes and below navigation on desktop sizes
        z-index: @zindex-modal - 5;

        @media screen and (min-width: @screen-xl) {
            z-index: @zindex-modal - 20;
        }


        // Light theme
        background: @mb-sidebar-filter-background;
        border-left: @mb-sidebar-filter-border-width solid @mb-sidebar-filter-border-color;

        // Transition
        transition: @mb-sidebar-filter-transition-open; // opening

        &__inner {
            opacity: 1;
            visibility: visible;
            display: flex;
            flex-direction: column;
            height: 100%;
            width: 100%;
            overflow: hidden;

            transition: @animation-duration-fast all .3s linear;
        }

        .ant-layout-sider-children {
            display: flex;
            flex-direction: column;
        }

        hr {
            margin: 0 @mb-spacing[xl];
            border: none;
            border-top: @mb-sidebar-filter__divider-border-width solid @mb-sidebar-filter__divider-border-color;
        }

        .mb-content-header--filter {
            background-color: transparent;
        }

        .@{layout-prefix-cls} {

            &-header,
            &-footer {
                height: auto;
                background-color: inherit;
                padding: @mb-sidebar-filter-spacing;

                // Flex child sm
                flex-basis: auto;
                flex-shrink: 0;
                flex-grow: 0;
                min-height: 0;
                min-width: 0
            }

            &-header {
                line-height: initial;
            }

            &-content {
                padding: @mb-sidebar-filter-spacing;
                overflow-y: auto;

                // Flex child lg
                flex-basis: 0;
                flex-shrink: 1;
                flex-grow: 1;
                min-height: 0;
                min-width: 0
            }

            &-footer {
                position: relative;

                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    left: @mb-sidebar-filter-spacing;
                    right: @mb-sidebar-filter-spacing;
                    height: @mb-sidebar-filter__divider-border-width;
                    background-color: @mb-sidebar-filter__divider-border-color;
                }
            }
        }

        .@{layout-prefix-cls}-header,
        .@{layout-prefix-cls}-content,
        .@{layout-prefix-cls}-footer,
        hr {
            opacity: 1;
            visibility: visible;
            transition: 0.2s opacity 0.3s linear, 0.2s visibility 0.3s linear;
        }

        .ant-layout-sider-trigger {
            position: absolute;
            top: @mb-sidebar__trigger-position-y;
            right: @mb-sidebar__trigger-position-x;
            max-width: @mb-sidebar__trigger-size;
            height: @mb-sidebar__trigger-size;
            background-color: transparent;
            color: @mb-sidebar__trigger-color;
            text-align: center;
            line-height: @mb-sidebar__trigger-line-height;
            width: @mb-sidebar__trigger-size  !important;
            border-radius: 50%;
            border: @mb-sidebar__trigger-border-width solid transparent;
            box-shadow: none;
        }

        &.ant-layout-sider-collapsed {
            bottom: 100%;
            width: @mb-sidebar-filter-width--collapsed;
            min-width: @mb-sidebar-filter-width--collapsed;
            max-width: @mb-sidebar-filter-width--collapsed;
            flex-basis: @mb-sidebar-filter-width--collapsed;
            transition: 0.2s all 0.3s linear;

            .@{layout-prefix-cls}-header,
            .@{layout-prefix-cls}-content,
            .@{layout-prefix-cls}-footer,
            hr {
                opacity: 0;
                visibility: hidden;
                transition: opacity 0.2s linear, 0.2s visibility linear;
            }

            .ant-layout-sider-trigger {
                border: @mb-sidebar__trigger-border-width solid @mb-sidebar__trigger-border-color;
                box-shadow: @mb-sidebar__trigger-shadow;
            }
        }

        &--collapsed {
            opacity: 0;
            visibility: hidden;
            bottom: 0;
            min-width: 0 !important;
            max-width: 0 !important;
            transition: @mb-sidebar-filter-transition-close; // closing

            .mb-sidebar-filter__inner,
            .mb-sidebar-filter__close {
                opacity: 0;
                visibility: hidden;
                transition: @animation-duration-fast all linear; // closing
            }
        }

        // Input backgrounds inside of filters
        .ant-input,
        .ant-picker,
        .ant-input-number,
        .ant-select:not(.ant-select-customize-input) .ant-select-selector,
        .ant-radio-wrapper:not(.ant-radio-wrapper-checked).ant-radio-inner,
        .ant-checkbox-wrapper:not(.ant-checkbox-wrapper-checked).ant-checkbox-inner {
            background-color: @mb-sidebar-filter__input-background;
        }
    }
}