@mb-select__icon-size: @mb-default-icon-line-height;
@mb-select__icon-color: @mb-text-tertiary-color;
@mb-select__icon-spacing: @mb-spacing[xs];

@mb-select__selector-spacing: @mb-select__icon-spacing * 2 + @mb-select__icon-size;

// Adjustments for select inputs
//-----------------------------------------------------------
.ant-dropdown {

    a,
    a:hover,
    a:focus {
        text-decoration: none;
    }
}


// Primary select dropdowns with no borders - used on charts
.mb-select--primary {
    color: @mb-primary-blue-color;
    margin-left: -7px;
    box-shadow: none;

    .ant-select-single.ant-select-show-arrow {

        .ant-select-selection-item,
        .ant-select-selection-placeholder {
            padding-right: 8px;
        }
    }

    &.ant-select.ant-select-show-arrow .ant-select-selector {
        padding-right: 18px;
    }

    .ant-select-arrow {
        color: @mb-primary-blue-color;
    }

    &-dropdown {
        min-width: 180px !important;
    }

    &:hover,
    &.ant-select-focused {
        &:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
            outline: transparent;
        }
    }
}