// Adjustments for tables
//-----------------------------------------------------------------
// Empty table alignment
.@{table-prefix-cls} {
    .@{ant-prefix}-empty-description {
        text-align: center;
    }
}

// Fix for link paddings in tables
.@{table-prefix-cls} {

    a,
    .@{ant-prefix}-link,
    .@{btn-prefix-cls} {
        padding-left: 0;
        padding-right: 0;

        +a,
        +.@{ant-prefix}-link,
        +.@{btn-prefix-cls} {
            margin-left: @mb-table__link-space-between;
        }
    }
}

// Hide table header dividers
.@{table-prefix-cls} {
    &-thead>tr>th:not(:last-child):not(.@{table-prefix-cls}-selection-column):not(.@{table-prefix-cls}-row-expand-icon-cell):not([colspan])::before {
        display: none;
    }
}