// Images for error pages and big segments
.mb-error {
    position: relative;
    width: 100%;
    background-color: transparent;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;

    &:before {
        content: "";
        display: block;
    }

    &--not-found {
        background-image: @mb-error-img-url-not-found;

        &:before {
            padding-top: @mb-error-img-ratio-not-found;
        }
    }

    &--unauthenticated {
        background-image: @mb-error-img-url-unauthenticated;

        &:before {
            padding-top: @mb-error-img-ratio-unauthenticated;
        }
    }

    &--unauthorized {
        background-image: @mb-error-img-url-unauthorized;

        &:before {
            padding-top: @mb-error-img-ratio-unauthorized;
        }
    }

    &--init {
        background-image: @mb-error-img-url-init;

        &:before {
            padding-top: @mb-error-img-ratio-init;
        }
    }
}

// Error segment definitions
.mb-error {

    // Used for large segments
    &-container {
        height: calc(100vh - @mb-nav-collapsed-size);

        @media and (min-width: @screen-xl) {
            height: 100vh;
        }
    }

    // Small segments - icon and text with an optional button
    &-sm {
        display: flex;
        align-items: center;

        .mb-error__icon {
            padding-right: @mb-error__icon-spacing;

            .mbi-alert-circle {
                font-size: @mb-error__icon-font-size;
                line-height: @mb-error__icon-line-height;
                color: @mb-error__icon-color;
            }
        }

        .mb-error__content {
            .ant-btn {
                padding: 0;
                height: auto;
            }
        }

        &--vertical {
            flex-direction: column;
            justify-content: center;

            .mb-error__icon {
                padding-right: 0
            }

            .mb-error__content {
                display: flex;
                flex-direction: column;
                padding-top: @mb-error__content-spacing-y;

                .ant-btn {
                    padding-top: @mb-error__content-spacing-y;
                }
            }
        }
    }
}