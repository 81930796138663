// Adjustments for notifications
.@{notification-prefix-cls} {
    &-notice {
        box-shadow: @mb-notification-shadow;
        background-color: @mb-notification-background;
        font-size: @mb-notification-font-size;
        line-height: @mb-notification-line-height;
        padding: @mb-notification-spacing;
        width: @mb-notification-width;

        // Description adjustments
        &-description {
            color: @mb-notification__description-color;
        }

        // CS-1848 Word-break added so long words in messages don't overflow
        &-description,
        &-message {
            word-break: break-word;
        }

        // Custom closing button
        &-btn {
            float: left;
            margin-left: @mb-notification__icon-close-size + @mb-notification__icon-spacing-between;
            margin-top: @mb-notification__icon-spacing-y;
        }

        // Close button adjustments
        &-close,
        .@{notification-prefix-cls}-close-x {
            display: block;
            width: @mb-notification__icon-close-size;
            height: @mb-notification__icon-close-size;
            text-align: center;
            line-height: @mb-notification__icon-close-size - 2px;
            color: @mb-notification__icon-close-color;
        }

        &-close {
            top: @mb-notification__icon-spacing-y;
            right: @mb-notification__icon-spacing-between;

            &-icon {
                line-height: @mb-notification__icon-close-size;
            }
        }

        // Removing right padding from title
        &-closable .ant-notification-notice-message {
            padding-right: 0;
        }
    }
}