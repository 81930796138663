.mbi-star-f() {
    .mb-icon-font-definition();
    content: "\e900";
}

.mbi-icon-2fa() {
    .mb-icon-font-definition();
    content: "\100df";
}

.mbi-icon-3d-cube-sphere() {
    .mb-icon-font-definition();
    content: "\100e0";
}

.mbi-a-b() {
    .mb-icon-font-definition();
    content: "\100e1";
}

.mbi-access-point() {
    .mb-icon-font-definition();
    content: "\100e2";
}

.mbi-access-point-off() {
    .mb-icon-font-definition();
    content: "\100e3";
}

.mbi-accessible() {
    .mb-icon-font-definition();
    content: "\100e4";
}

.mbi-activity() {
    .mb-icon-font-definition();
    content: "\100e5";
}

.mbi-ad() {
    .mb-icon-font-definition();
    content: "\100e6";
}

.mbi-adjustments() {
    .mb-icon-font-definition();
    content: "\100e7";
}

.mbi-adjustments-alt() {
    .mb-icon-font-definition();
    content: "\100e8";
}

.mbi-adjustments-horizontal() {
    .mb-icon-font-definition();
    content: "\100e9";
}

.mbi-aerial-lift() {
    .mb-icon-font-definition();
    content: "\100ea";
}

.mbi-affiliate() {
    .mb-icon-font-definition();
    content: "\100eb";
}

.mbi-alarm() {
    .mb-icon-font-definition();
    content: "\100ec";
}

.mbi-alert-circle() {
    .mb-icon-font-definition();
    content: "\100ed";
}

.mbi-alert-octagon() {
    .mb-icon-font-definition();
    content: "\100ee";
}

.mbi-alert-triangle() {
    .mb-icon-font-definition();
    content: "\100ef";
}

.mbi-alien() {
    .mb-icon-font-definition();
    content: "\100f0";
}

.mbi-align-center() {
    .mb-icon-font-definition();
    content: "\100f1";
}

.mbi-align-justified() {
    .mb-icon-font-definition();
    content: "\100f2";
}

.mbi-align-left() {
    .mb-icon-font-definition();
    content: "\100f3";
}

.mbi-align-right() {
    .mb-icon-font-definition();
    content: "\100f4";
}

.mbi-ambulance() {
    .mb-icon-font-definition();
    content: "\100f5";
}

.mbi-anchor() {
    .mb-icon-font-definition();
    content: "\100f6";
}

.mbi-antenna-bars-1() {
    .mb-icon-font-definition();
    content: "\100f7";
}

.mbi-antenna-bars-2() {
    .mb-icon-font-definition();
    content: "\100f8";
}

.mbi-antenna-bars-3() {
    .mb-icon-font-definition();
    content: "\100f9";
}

.mbi-antenna-bars-4() {
    .mb-icon-font-definition();
    content: "\100fa";
}

.mbi-antenna-bars-5() {
    .mb-icon-font-definition();
    content: "\100fb";
}

.mbi-aperture() {
    .mb-icon-font-definition();
    content: "\100fc";
}

.mbi-apps() {
    .mb-icon-font-definition();
    content: "\100fd";
}

.mbi-archive() {
    .mb-icon-font-definition();
    content: "\100fe";
}

.mbi-arrow-back() {
    .mb-icon-font-definition();
    content: "\100ff";
}

.mbi-arrow-back-up() {
    .mb-icon-font-definition();
    content: "\10100";
}

.mbi-arrow-bar-down() {
    .mb-icon-font-definition();
    content: "\10101";
}

.mbi-arrow-bar-left() {
    .mb-icon-font-definition();
    content: "\10102";
}

.mbi-arrow-bar-right() {
    .mb-icon-font-definition();
    content: "\10103";
}

.mbi-arrow-bar-to-down() {
    .mb-icon-font-definition();
    content: "\10104";
}

.mbi-arrow-bar-to-left() {
    .mb-icon-font-definition();
    content: "\10105";
}

.mbi-arrow-bar-to-right() {
    .mb-icon-font-definition();
    content: "\10106";
}

.mbi-arrow-bar-to-up() {
    .mb-icon-font-definition();
    content: "\10107";
}

.mbi-arrow-bar-up() {
    .mb-icon-font-definition();
    content: "\10108";
}

.mbi-arrow-bottom-bar() {
    .mb-icon-font-definition();
    content: "\10109";
}

.mbi-arrow-bottom-circle() {
    .mb-icon-font-definition();
    content: "\1010a";
}

.mbi-arrow-bottom-square() {
    .mb-icon-font-definition();
    content: "\1010b";
}

.mbi-arrow-bottom-tail() {
    .mb-icon-font-definition();
    content: "\1010c";
}

.mbi-arrow-down() {
    .mb-icon-font-definition();
    content: "\1010d";
}

.mbi-arrow-down-circle() {
    .mb-icon-font-definition();
    content: "\1010e";
}

.mbi-arrow-down-left() {
    .mb-icon-font-definition();
    content: "\1010f";
}

.mbi-arrow-down-left-circle() {
    .mb-icon-font-definition();
    content: "\10110";
}

.mbi-arrow-down-right() {
    .mb-icon-font-definition();
    content: "\10111";
}

.mbi-arrow-down-right-circle() {
    .mb-icon-font-definition();
    content: "\10112";
}

.mbi-arrow-forward() {
    .mb-icon-font-definition();
    content: "\10113";
}

.mbi-arrow-forward-up() {
    .mb-icon-font-definition();
    content: "\10114";
}

.mbi-arrow-left() {
    .mb-icon-font-definition();
    content: "\10115";
}

.mbi-arrow-left-bar() {
    .mb-icon-font-definition();
    content: "\10116";
}

.mbi-arrow-left-circle() {
    .mb-icon-font-definition();
    content: "\10117";
}

.mbi-arrow-left-square() {
    .mb-icon-font-definition();
    content: "\10118";
}

.mbi-arrow-left-tail() {
    .mb-icon-font-definition();
    content: "\10119";
}

.mbi-arrow-loop-left() {
    .mb-icon-font-definition();
    content: "\1011a";
}

.mbi-arrow-loop-right() {
    .mb-icon-font-definition();
    content: "\1011b";
}

.mbi-arrow-narrow-down() {
    .mb-icon-font-definition();
    content: "\1011c";
}

.mbi-arrow-narrow-left() {
    .mb-icon-font-definition();
    content: "\1011d";
}

.mbi-arrow-narrow-right() {
    .mb-icon-font-definition();
    content: "\1011e";
}

.mbi-arrow-narrow-up() {
    .mb-icon-font-definition();
    content: "\1011f";
}

.mbi-arrow-ramp-left() {
    .mb-icon-font-definition();
    content: "\10120";
}

.mbi-arrow-ramp-right() {
    .mb-icon-font-definition();
    content: "\10121";
}

.mbi-arrow-right() {
    .mb-icon-font-definition();
    content: "\10122";
}

.mbi-arrow-right-bar() {
    .mb-icon-font-definition();
    content: "\10123";
}

.mbi-arrow-right-circle() {
    .mb-icon-font-definition();
    content: "\10124";
}

.mbi-arrow-right-square() {
    .mb-icon-font-definition();
    content: "\10125";
}

.mbi-arrow-right-tail() {
    .mb-icon-font-definition();
    content: "\10126";
}

.mbi-arrow-top-bar() {
    .mb-icon-font-definition();
    content: "\10127";
}

.mbi-arrow-top-circle() {
    .mb-icon-font-definition();
    content: "\10128";
}

.mbi-arrow-top-square() {
    .mb-icon-font-definition();
    content: "\10129";
}

.mbi-arrow-top-tail() {
    .mb-icon-font-definition();
    content: "\1012a";
}

.mbi-arrow-up() {
    .mb-icon-font-definition();
    content: "\1012b";
}

.mbi-arrow-up-circle() {
    .mb-icon-font-definition();
    content: "\1012c";
}

.mbi-arrow-up-left() {
    .mb-icon-font-definition();
    content: "\1012d";
}

.mbi-arrow-up-left-circle() {
    .mb-icon-font-definition();
    content: "\1012e";
}

.mbi-arrow-up-right() {
    .mb-icon-font-definition();
    content: "\1012f";
}

.mbi-arrow-up-right-circle() {
    .mb-icon-font-definition();
    content: "\10130";
}

.mbi-arrow-wave-left-down() {
    .mb-icon-font-definition();
    content: "\10131";
}

.mbi-arrow-wave-left-up() {
    .mb-icon-font-definition();
    content: "\10132";
}

.mbi-arrow-wave-right-down() {
    .mb-icon-font-definition();
    content: "\10133";
}

.mbi-arrow-wave-right-up() {
    .mb-icon-font-definition();
    content: "\10134";
}

.mbi-arrows-diagonal() {
    .mb-icon-font-definition();
    content: "\10135";
}

.mbi-arrows-diagonal-2() {
    .mb-icon-font-definition();
    content: "\10136";
}

.mbi-arrows-down() {
    .mb-icon-font-definition();
    content: "\10137";
}

.mbi-arrows-down-up() {
    .mb-icon-font-definition();
    content: "\10138";
}

.mbi-arrows-horizontal() {
    .mb-icon-font-definition();
    content: "\10139";
}

.mbi-arrows-join() {
    .mb-icon-font-definition();
    content: "\1013a";
}

.mbi-arrows-join-2() {
    .mb-icon-font-definition();
    content: "\1013b";
}

.mbi-arrows-left() {
    .mb-icon-font-definition();
    content: "\1013c";
}

.mbi-arrows-left-down() {
    .mb-icon-font-definition();
    content: "\1013d";
}

.mbi-arrows-left-right() {
    .mb-icon-font-definition();
    content: "\1013e";
}

.mbi-arrows-maximize() {
    .mb-icon-font-definition();
    content: "\1013f";
}

.mbi-arrows-minimize() {
    .mb-icon-font-definition();
    content: "\10140";
}

.mbi-arrows-right() {
    .mb-icon-font-definition();
    content: "\10141";
}

.mbi-arrows-right-down() {
    .mb-icon-font-definition();
    content: "\10142";
}

.mbi-arrows-right-left() {
    .mb-icon-font-definition();
    content: "\10143";
}

.mbi-arrows-sort() {
    .mb-icon-font-definition();
    content: "\10144";
}

.mbi-arrows-split() {
    .mb-icon-font-definition();
    content: "\10145";
}

.mbi-arrows-split-2() {
    .mb-icon-font-definition();
    content: "\10146";
}

.mbi-arrows-up() {
    .mb-icon-font-definition();
    content: "\10147";
}

.mbi-arrows-up-down() {
    .mb-icon-font-definition();
    content: "\10148";
}

.mbi-arrows-up-left() {
    .mb-icon-font-definition();
    content: "\10149";
}

.mbi-arrows-up-right() {
    .mb-icon-font-definition();
    content: "\1014a";
}

.mbi-arrows-vertical() {
    .mb-icon-font-definition();
    content: "\1014b";
}

.mbi-artboard() {
    .mb-icon-font-definition();
    content: "\1014c";
}

.mbi-aspect-ratio() {
    .mb-icon-font-definition();
    content: "\1014d";
}

.mbi-at() {
    .mb-icon-font-definition();
    content: "\1014e";
}

.mbi-atom() {
    .mb-icon-font-definition();
    content: "\1014f";
}

.mbi-atom-2() {
    .mb-icon-font-definition();
    content: "\10150";
}

.mbi-award() {
    .mb-icon-font-definition();
    content: "\10151";
}

.mbi-backhoe() {
    .mb-icon-font-definition();
    content: "\10152";
}

.mbi-backspace() {
    .mb-icon-font-definition();
    content: "\10153";
}

.mbi-ball-american-football() {
    .mb-icon-font-definition();
    content: "\10154";
}

.mbi-ball-basketball() {
    .mb-icon-font-definition();
    content: "\10155";
}

.mbi-ball-bowling() {
    .mb-icon-font-definition();
    content: "\10156";
}

.mbi-ball-football() {
    .mb-icon-font-definition();
    content: "\10157";
}

.mbi-ball-football-off() {
    .mb-icon-font-definition();
    content: "\10158";
}

.mbi-ball-tennis() {
    .mb-icon-font-definition();
    content: "\10159";
}

.mbi-ball-volleyball() {
    .mb-icon-font-definition();
    content: "\1015a";
}

.mbi-ban() {
    .mb-icon-font-definition();
    content: "\1015b";
}

.mbi-bandage() {
    .mb-icon-font-definition();
    content: "\1015c";
}

.mbi-barcode() {
    .mb-icon-font-definition();
    content: "\1015d";
}

.mbi-basket() {
    .mb-icon-font-definition();
    content: "\1015e";
}

.mbi-battery() {
    .mb-icon-font-definition();
    content: "\1015f";
}

.mbi-battery-1() {
    .mb-icon-font-definition();
    content: "\10160";
}

.mbi-battery-2() {
    .mb-icon-font-definition();
    content: "\10161";
}

.mbi-battery-3() {
    .mb-icon-font-definition();
    content: "\10162";
}

.mbi-battery-4() {
    .mb-icon-font-definition();
    content: "\10163";
}

.mbi-battery-automotive() {
    .mb-icon-font-definition();
    content: "\10164";
}

.mbi-battery-charging() {
    .mb-icon-font-definition();
    content: "\10165";
}

.mbi-battery-off() {
    .mb-icon-font-definition();
    content: "\10166";
}

.mbi-bed() {
    .mb-icon-font-definition();
    content: "\10167";
}

.mbi-bell() {
    .mb-icon-font-definition();
    content: "\10168";
}

.mbi-bell-off() {
    .mb-icon-font-definition();
    content: "\10169";
}

.mbi-bell-ringing() {
    .mb-icon-font-definition();
    content: "\1016a";
}

.mbi-bell-ringing-2() {
    .mb-icon-font-definition();
    content: "\1016b";
}

.mbi-bike() {
    .mb-icon-font-definition();
    content: "\1016c";
}

.mbi-binary() {
    .mb-icon-font-definition();
    content: "\1016d";
}

.mbi-biohazard() {
    .mb-icon-font-definition();
    content: "\1016e";
}

.mbi-blockquote() {
    .mb-icon-font-definition();
    content: "\1016f";
}

.mbi-bluetooth() {
    .mb-icon-font-definition();
    content: "\10170";
}

.mbi-bluetooth-connected() {
    .mb-icon-font-definition();
    content: "\10171";
}

.mbi-bluetooth-off() {
    .mb-icon-font-definition();
    content: "\10172";
}

.mbi-bold() {
    .mb-icon-font-definition();
    content: "\10173";
}

.mbi-bolt() {
    .mb-icon-font-definition();
    content: "\10174";
}

.mbi-bolt-off() {
    .mb-icon-font-definition();
    content: "\10175";
}

.mbi-bone() {
    .mb-icon-font-definition();
    content: "\10176";
}

.mbi-book() {
    .mb-icon-font-definition();
    content: "\10177";
}

.mbi-bookmark() {
    .mb-icon-font-definition();
    content: "\10178";
}

.mbi-bookmark-off() {
    .mb-icon-font-definition();
    content: "\10179";
}

.mbi-bookmarks() {
    .mb-icon-font-definition();
    content: "\1017a";
}

.mbi-border-all() {
    .mb-icon-font-definition();
    content: "\1017b";
}

.mbi-border-bottom() {
    .mb-icon-font-definition();
    content: "\1017c";
}

.mbi-border-horizontal() {
    .mb-icon-font-definition();
    content: "\1017d";
}

.mbi-border-inner() {
    .mb-icon-font-definition();
    content: "\1017e";
}

.mbi-border-left() {
    .mb-icon-font-definition();
    content: "\1017f";
}

.mbi-border-none() {
    .mb-icon-font-definition();
    content: "\10180";
}

.mbi-border-outer() {
    .mb-icon-font-definition();
    content: "\10181";
}

.mbi-border-radius() {
    .mb-icon-font-definition();
    content: "\10182";
}

.mbi-border-right() {
    .mb-icon-font-definition();
    content: "\10183";
}

.mbi-border-style() {
    .mb-icon-font-definition();
    content: "\10184";
}

.mbi-border-top() {
    .mb-icon-font-definition();
    content: "\10185";
}

.mbi-border-vertical() {
    .mb-icon-font-definition();
    content: "\10186";
}

.mbi-box() {
    .mb-icon-font-definition();
    content: "\10187";
}

.mbi-box-margin() {
    .mb-icon-font-definition();
    content: "\10188";
}

.mbi-box-model() {
    .mb-icon-font-definition();
    content: "\10189";
}

.mbi-box-multiple() {
    .mb-icon-font-definition();
    content: "\1018a";
}

.mbi-box-multiple-0() {
    .mb-icon-font-definition();
    content: "\1018b";
}

.mbi-box-multiple-1() {
    .mb-icon-font-definition();
    content: "\1018c";
}

.mbi-box-multiple-2() {
    .mb-icon-font-definition();
    content: "\1018d";
}

.mbi-box-multiple-3() {
    .mb-icon-font-definition();
    content: "\1018e";
}

.mbi-box-multiple-4() {
    .mb-icon-font-definition();
    content: "\1018f";
}

.mbi-box-multiple-5() {
    .mb-icon-font-definition();
    content: "\10190";
}

.mbi-box-multiple-6() {
    .mb-icon-font-definition();
    content: "\10191";
}

.mbi-box-multiple-7() {
    .mb-icon-font-definition();
    content: "\10192";
}

.mbi-box-multiple-8() {
    .mb-icon-font-definition();
    content: "\10193";
}

.mbi-box-multiple-9() {
    .mb-icon-font-definition();
    content: "\10194";
}

.mbi-box-padding() {
    .mb-icon-font-definition();
    content: "\10195";
}

.mbi-braces() {
    .mb-icon-font-definition();
    content: "\10196";
}

.mbi-brackets() {
    .mb-icon-font-definition();
    content: "\10197";
}

.mbi-brand-airbnb() {
    .mb-icon-font-definition();
    content: "\10198";
}

.mbi-brand-android() {
    .mb-icon-font-definition();
    content: "\10199";
}

.mbi-brand-apple() {
    .mb-icon-font-definition();
    content: "\1019a";
}

.mbi-brand-apple-arcade() {
    .mb-icon-font-definition();
    content: "\1019b";
}

.mbi-brand-appstore() {
    .mb-icon-font-definition();
    content: "\1019c";
}

.mbi-brand-asana() {
    .mb-icon-font-definition();
    content: "\1019d";
}

.mbi-brand-behance() {
    .mb-icon-font-definition();
    content: "\1019e";
}

.mbi-brand-bing() {
    .mb-icon-font-definition();
    content: "\1019f";
}

.mbi-brand-bitbucket() {
    .mb-icon-font-definition();
    content: "\101a0";
}

.mbi-brand-booking() {
    .mb-icon-font-definition();
    content: "\101a1";
}

.mbi-brand-chrome() {
    .mb-icon-font-definition();
    content: "\101a2";
}

.mbi-brand-codepen() {
    .mb-icon-font-definition();
    content: "\101a3";
}

.mbi-brand-codesandbox() {
    .mb-icon-font-definition();
    content: "\101a4";
}

.mbi-brand-css3() {
    .mb-icon-font-definition();
    content: "\101a5";
}

.mbi-brand-deviantart() {
    .mb-icon-font-definition();
    content: "\101a6";
}

.mbi-brand-discord() {
    .mb-icon-font-definition();
    content: "\101a7";
}

.mbi-brand-disqus() {
    .mb-icon-font-definition();
    content: "\101a8";
}

.mbi-brand-docker() {
    .mb-icon-font-definition();
    content: "\101a9";
}

.mbi-brand-dribbble() {
    .mb-icon-font-definition();
    content: "\101aa";
}

.mbi-brand-edge() {
    .mb-icon-font-definition();
    content: "\101ab";
}

.mbi-brand-facebook() {
    .mb-icon-font-definition();
    content: "\101ac";
}

.mbi-brand-figma() {
    .mb-icon-font-definition();
    content: "\101ad";
}

.mbi-brand-firefox() {
    .mb-icon-font-definition();
    content: "\101ae";
}

.mbi-brand-flickr() {
    .mb-icon-font-definition();
    content: "\101af";
}

.mbi-brand-foursquare() {
    .mb-icon-font-definition();
    content: "\101b0";
}

.mbi-brand-framer() {
    .mb-icon-font-definition();
    content: "\101b1";
}

.mbi-brand-github() {
    .mb-icon-font-definition();
    content: "\101b2";
}

.mbi-brand-gitlab() {
    .mb-icon-font-definition();
    content: "\101b3";
}

.mbi-brand-google() {
    .mb-icon-font-definition();
    content: "\101b4";
}

.mbi-brand-google-analytics() {
    .mb-icon-font-definition();
    content: "\101b5";
}

.mbi-brand-google-drive() {
    .mb-icon-font-definition();
    content: "\101b6";
}

.mbi-brand-google-play() {
    .mb-icon-font-definition();
    content: "\101b7";
}

.mbi-brand-gravatar() {
    .mb-icon-font-definition();
    content: "\101b8";
}

.mbi-brand-hipchat() {
    .mb-icon-font-definition();
    content: "\101b9";
}

.mbi-brand-html5() {
    .mb-icon-font-definition();
    content: "\101ba";
}

.mbi-brand-instagram() {
    .mb-icon-font-definition();
    content: "\101bb";
}

.mbi-brand-kickstarter() {
    .mb-icon-font-definition();
    content: "\101bc";
}

.mbi-brand-kotlin() {
    .mb-icon-font-definition();
    content: "\101bd";
}

.mbi-brand-linkedin() {
    .mb-icon-font-definition();
    content: "\101be";
}

.mbi-brand-medium() {
    .mb-icon-font-definition();
    content: "\101bf";
}

.mbi-brand-messenger() {
    .mb-icon-font-definition();
    content: "\101c0";
}

.mbi-brand-netflix() {
    .mb-icon-font-definition();
    content: "\101c1";
}

.mbi-brand-open-source() {
    .mb-icon-font-definition();
    content: "\101c2";
}

.mbi-brand-opera() {
    .mb-icon-font-definition();
    content: "\101c3";
}

.mbi-brand-pagekit() {
    .mb-icon-font-definition();
    content: "\101c4";
}

.mbi-brand-patreon() {
    .mb-icon-font-definition();
    content: "\101c5";
}

.mbi-brand-paypal() {
    .mb-icon-font-definition();
    content: "\101c6";
}

.mbi-brand-pinterest() {
    .mb-icon-font-definition();
    content: "\101c7";
}

.mbi-brand-pocket() {
    .mb-icon-font-definition();
    content: "\101c8";
}

.mbi-brand-producthunt() {
    .mb-icon-font-definition();
    content: "\101c9";
}

.mbi-brand-python() {
    .mb-icon-font-definition();
    content: "\101ca";
}

.mbi-brand-reddit() {
    .mb-icon-font-definition();
    content: "\101cb";
}

.mbi-brand-safari() {
    .mb-icon-font-definition();
    content: "\101cc";
}

.mbi-brand-sass() {
    .mb-icon-font-definition();
    content: "\101cd";
}

.mbi-brand-sentry() {
    .mb-icon-font-definition();
    content: "\101ce";
}

.mbi-brand-shazam() {
    .mb-icon-font-definition();
    content: "\101cf";
}

.mbi-brand-sketch() {
    .mb-icon-font-definition();
    content: "\101d0";
}

.mbi-brand-skype() {
    .mb-icon-font-definition();
    content: "\101d1";
}

.mbi-brand-slack() {
    .mb-icon-font-definition();
    content: "\101d2";
}

.mbi-brand-snapchat() {
    .mb-icon-font-definition();
    content: "\101d3";
}

.mbi-brand-soundcloud() {
    .mb-icon-font-definition();
    content: "\101d4";
}

.mbi-brand-spotify() {
    .mb-icon-font-definition();
    content: "\101d5";
}

.mbi-brand-steam() {
    .mb-icon-font-definition();
    content: "\101d6";
}

.mbi-brand-stripe() {
    .mb-icon-font-definition();
    content: "\101d7";
}

.mbi-brand-tabler() {
    .mb-icon-font-definition();
    content: "\101d8";
}

.mbi-brand-tailwind() {
    .mb-icon-font-definition();
    content: "\101d9";
}

.mbi-brand-telegram() {
    .mb-icon-font-definition();
    content: "\101da";
}

.mbi-brand-tidal() {
    .mb-icon-font-definition();
    content: "\101db";
}

.mbi-brand-tiktok() {
    .mb-icon-font-definition();
    content: "\101dc";
}

.mbi-brand-tinder() {
    .mb-icon-font-definition();
    content: "\101dd";
}

.mbi-brand-tumblr() {
    .mb-icon-font-definition();
    content: "\101de";
}

.mbi-brand-twitch() {
    .mb-icon-font-definition();
    content: "\101df";
}

.mbi-brand-twitter() {
    .mb-icon-font-definition();
    content: "\101e0";
}

.mbi-brand-unsplash() {
    .mb-icon-font-definition();
    content: "\101e1";
}

.mbi-brand-vimeo() {
    .mb-icon-font-definition();
    content: "\101e2";
}

.mbi-brand-vk() {
    .mb-icon-font-definition();
    content: "\101e3";
}

.mbi-brand-whatsapp() {
    .mb-icon-font-definition();
    content: "\101e4";
}

.mbi-brand-windows() {
    .mb-icon-font-definition();
    content: "\101e5";
}

.mbi-brand-yahoo() {
    .mb-icon-font-definition();
    content: "\101e6";
}

.mbi-brand-ycombinator() {
    .mb-icon-font-definition();
    content: "\101e7";
}

.mbi-brand-youtube() {
    .mb-icon-font-definition();
    content: "\101e8";
}

.mbi-briefcase() {
    .mb-icon-font-definition();
    content: "\101e9";
}

.mbi-brightness() {
    .mb-icon-font-definition();
    content: "\101ea";
}

.mbi-brightness-2() {
    .mb-icon-font-definition();
    content: "\101eb";
}

.mbi-brightness-down() {
    .mb-icon-font-definition();
    content: "\101ec";
}

.mbi-brightness-half() {
    .mb-icon-font-definition();
    content: "\101ed";
}

.mbi-brightness-up() {
    .mb-icon-font-definition();
    content: "\101ee";
}

.mbi-browser() {
    .mb-icon-font-definition();
    content: "\101ef";
}

.mbi-brush() {
    .mb-icon-font-definition();
    content: "\101f0";
}

.mbi-bucket() {
    .mb-icon-font-definition();
    content: "\101f1";
}

.mbi-bug() {
    .mb-icon-font-definition();
    content: "\101f2";
}

.mbi-building() {
    .mb-icon-font-definition();
    content: "\101f3";
}

.mbi-building-arch() {
    .mb-icon-font-definition();
    content: "\101f4";
}

.mbi-building-bank() {
    .mb-icon-font-definition();
    content: "\101f5";
}

.mbi-building-bridge() {
    .mb-icon-font-definition();
    content: "\101f6";
}

.mbi-building-bridge-2() {
    .mb-icon-font-definition();
    content: "\101f7";
}

.mbi-building-carousel() {
    .mb-icon-font-definition();
    content: "\101f8";
}

.mbi-building-castle() {
    .mb-icon-font-definition();
    content: "\101f9";
}

.mbi-building-church() {
    .mb-icon-font-definition();
    content: "\101fa";
}

.mbi-building-community() {
    .mb-icon-font-definition();
    content: "\101fb";
}

.mbi-building-cottage() {
    .mb-icon-font-definition();
    content: "\101fc";
}

.mbi-building-factory() {
    .mb-icon-font-definition();
    content: "\101fd";
}

.mbi-building-fortress() {
    .mb-icon-font-definition();
    content: "\101fe";
}

.mbi-building-hospital() {
    .mb-icon-font-definition();
    content: "\101ff";
}

.mbi-building-lighthouse() {
    .mb-icon-font-definition();
    content: "\10200";
}

.mbi-building-monument() {
    .mb-icon-font-definition();
    content: "\10201";
}

.mbi-building-pavilon() {
    .mb-icon-font-definition();
    content: "\10202";
}

.mbi-building-skyscraper() {
    .mb-icon-font-definition();
    content: "\10203";
}

.mbi-building-store() {
    .mb-icon-font-definition();
    content: "\10204";
}

.mbi-building-warehouse() {
    .mb-icon-font-definition();
    content: "\10205";
}

.mbi-bulb() {
    .mb-icon-font-definition();
    content: "\10206";
}

.mbi-bulb-off() {
    .mb-icon-font-definition();
    content: "\10207";
}

.mbi-bulldozer() {
    .mb-icon-font-definition();
    content: "\10208";
}

.mbi-bus() {
    .mb-icon-font-definition();
    content: "\10209";
}

.mbi-businessplan() {
    .mb-icon-font-definition();
    content: "\1020a";
}

.mbi-calculator() {
    .mb-icon-font-definition();
    content: "\1020b";
}

.mbi-calendar() {
    .mb-icon-font-definition();
    content: "\1020c";
}

.mbi-calendar-event() {
    .mb-icon-font-definition();
    content: "\1020d";
}

.mbi-calendar-minus() {
    .mb-icon-font-definition();
    content: "\1020e";
}

.mbi-calendar-off() {
    .mb-icon-font-definition();
    content: "\1020f";
}

.mbi-calendar-plus() {
    .mb-icon-font-definition();
    content: "\10210";
}

.mbi-calendar-stats() {
    .mb-icon-font-definition();
    content: "\10211";
}

.mbi-calendar-time() {
    .mb-icon-font-definition();
    content: "\10212";
}

.mbi-camera() {
    .mb-icon-font-definition();
    content: "\10213";
}

.mbi-camera-minus() {
    .mb-icon-font-definition();
    content: "\10214";
}

.mbi-camera-off() {
    .mb-icon-font-definition();
    content: "\10215";
}

.mbi-camera-plus() {
    .mb-icon-font-definition();
    content: "\10216";
}

.mbi-camera-rotate() {
    .mb-icon-font-definition();
    content: "\10217";
}

.mbi-camera-selfie() {
    .mb-icon-font-definition();
    content: "\10218";
}

.mbi-capture() {
    .mb-icon-font-definition();
    content: "\10219";
}

.mbi-car() {
    .mb-icon-font-definition();
    content: "\1021a";
}

.mbi-caravan() {
    .mb-icon-font-definition();
    content: "\1021b";
}

.mbi-cardboards() {
    .mb-icon-font-definition();
    content: "\1021c";
}

.mbi-caret-down() {
    .mb-icon-font-definition();
    content: "\1021d";
}

.mbi-caret-left() {
    .mb-icon-font-definition();
    content: "\1021e";
}

.mbi-caret-right() {
    .mb-icon-font-definition();
    content: "\1021f";
}

.mbi-caret-up() {
    .mb-icon-font-definition();
    content: "\10220";
}

.mbi-cash() {
    .mb-icon-font-definition();
    content: "\10221";
}

.mbi-cash-banknote() {
    .mb-icon-font-definition();
    content: "\10222";
}

.mbi-cash-banknote-off() {
    .mb-icon-font-definition();
    content: "\10223";
}

.mbi-cast() {
    .mb-icon-font-definition();
    content: "\10224";
}

.mbi-ce() {
    .mb-icon-font-definition();
    content: "\10225";
}

.mbi-certificate() {
    .mb-icon-font-definition();
    content: "\10226";
}

.mbi-charging-pile() {
    .mb-icon-font-definition();
    content: "\10227";
}

.mbi-chart-arcs() {
    .mb-icon-font-definition();
    content: "\10228";
}

.mbi-chart-arcs-3() {
    .mb-icon-font-definition();
    content: "\10229";
}

.mbi-chart-area() {
    .mb-icon-font-definition();
    content: "\1022a";
}

.mbi-chart-area-line() {
    .mb-icon-font-definition();
    content: "\1022b";
}

.mbi-chart-arrows() {
    .mb-icon-font-definition();
    content: "\1022c";
}

.mbi-chart-arrows-vertical() {
    .mb-icon-font-definition();
    content: "\1022d";
}

.mbi-chart-bar() {
    .mb-icon-font-definition();
    content: "\1022e";
}

.mbi-chart-bubble() {
    .mb-icon-font-definition();
    content: "\1022f";
}

.mbi-chart-candle() {
    .mb-icon-font-definition();
    content: "\10230";
}

.mbi-chart-circles() {
    .mb-icon-font-definition();
    content: "\10231";
}

.mbi-chart-donut() {
    .mb-icon-font-definition();
    content: "\10232";
}

.mbi-chart-donut-2() {
    .mb-icon-font-definition();
    content: "\10233";
}

.mbi-chart-donut-3() {
    .mb-icon-font-definition();
    content: "\10234";
}

.mbi-chart-donut-4() {
    .mb-icon-font-definition();
    content: "\10235";
}

.mbi-chart-dots() {
    .mb-icon-font-definition();
    content: "\10236";
}

.mbi-chart-infographic() {
    .mb-icon-font-definition();
    content: "\10237";
}

.mbi-chart-line() {
    .mb-icon-font-definition();
    content: "\10238";
}

.mbi-chart-pie() {
    .mb-icon-font-definition();
    content: "\10239";
}

.mbi-chart-pie-2() {
    .mb-icon-font-definition();
    content: "\1023a";
}

.mbi-chart-pie-3() {
    .mb-icon-font-definition();
    content: "\1023b";
}

.mbi-chart-pie-4() {
    .mb-icon-font-definition();
    content: "\1023c";
}

.mbi-chart-radar() {
    .mb-icon-font-definition();
    content: "\1023d";
}

.mbi-check() {
    .mb-icon-font-definition();
    content: "\1023e";
}

.mbi-checkbox() {
    .mb-icon-font-definition();
    content: "\1023f";
}

.mbi-checks() {
    .mb-icon-font-definition();
    content: "\10240";
}

.mbi-chevron-down() {
    .mb-icon-font-definition();
    content: "\10241";
}

.mbi-chevron-down-left() {
    .mb-icon-font-definition();
    content: "\10242";
}

.mbi-chevron-down-right() {
    .mb-icon-font-definition();
    content: "\10243";
}

.mbi-chevron-left() {
    .mb-icon-font-definition();
    content: "\10244";
}

.mbi-chevron-right() {
    .mb-icon-font-definition();
    content: "\10245";
}

.mbi-chevron-up() {
    .mb-icon-font-definition();
    content: "\10246";
}

.mbi-chevron-up-left() {
    .mb-icon-font-definition();
    content: "\10247";
}

.mbi-chevron-up-right() {
    .mb-icon-font-definition();
    content: "\10248";
}

.mbi-chevrons-down() {
    .mb-icon-font-definition();
    content: "\10249";
}

.mbi-chevrons-down-left() {
    .mb-icon-font-definition();
    content: "\1024a";
}

.mbi-chevrons-down-right() {
    .mb-icon-font-definition();
    content: "\1024b";
}

.mbi-chevrons-left() {
    .mb-icon-font-definition();
    content: "\1024c";
}

.mbi-chevrons-right() {
    .mb-icon-font-definition();
    content: "\1024d";
}

.mbi-chevrons-up() {
    .mb-icon-font-definition();
    content: "\1024e";
}

.mbi-chevrons-up-left() {
    .mb-icon-font-definition();
    content: "\1024f";
}

.mbi-chevrons-up-right() {
    .mb-icon-font-definition();
    content: "\10250";
}

.mbi-christmas-tree() {
    .mb-icon-font-definition();
    content: "\10251";
}

.mbi-circle() {
    .mb-icon-font-definition();
    content: "\10252";
}

.mbi-circle-0() {
    .mb-icon-font-definition();
    content: "\10253";
}

.mbi-circle-1() {
    .mb-icon-font-definition();
    content: "\10254";
}

.mbi-circle-2() {
    .mb-icon-font-definition();
    content: "\10255";
}

.mbi-circle-3() {
    .mb-icon-font-definition();
    content: "\10256";
}

.mbi-circle-4() {
    .mb-icon-font-definition();
    content: "\10257";
}

.mbi-circle-5() {
    .mb-icon-font-definition();
    content: "\10258";
}

.mbi-circle-6() {
    .mb-icon-font-definition();
    content: "\10259";
}

.mbi-circle-7() {
    .mb-icon-font-definition();
    content: "\1025a";
}

.mbi-circle-8() {
    .mb-icon-font-definition();
    content: "\1025b";
}

.mbi-circle-9() {
    .mb-icon-font-definition();
    content: "\1025c";
}

.mbi-circle-check() {
    .mb-icon-font-definition();
    content: "\1025d";
}

.mbi-circle-dashed() {
    .mb-icon-font-definition();
    content: "\1025e";
}

.mbi-circle-dotted() {
    .mb-icon-font-definition();
    content: "\1025f";
}

.mbi-circle-half() {
    .mb-icon-font-definition();
    content: "\10260";
}

.mbi-circle-half-vertical() {
    .mb-icon-font-definition();
    content: "\10261";
}

.mbi-circle-minus() {
    .mb-icon-font-definition();
    content: "\10262";
}

.mbi-circle-off() {
    .mb-icon-font-definition();
    content: "\10263";
}

.mbi-circle-plus() {
    .mb-icon-font-definition();
    content: "\10264";
}

.mbi-circle-square() {
    .mb-icon-font-definition();
    content: "\10265";
}

.mbi-circle-x() {
    .mb-icon-font-definition();
    content: "\10266";
}

.mbi-circles() {
    .mb-icon-font-definition();
    content: "\10267";
}

.mbi-clear-all() {
    .mb-icon-font-definition();
    content: "\10268";
}

.mbi-clear-formatting() {
    .mb-icon-font-definition();
    content: "\10269";
}

.mbi-click() {
    .mb-icon-font-definition();
    content: "\1026a";
}

.mbi-clipboard() {
    .mb-icon-font-definition();
    content: "\1026b";
}

.mbi-clipboard-check() {
    .mb-icon-font-definition();
    content: "\1026c";
}

.mbi-clipboard-list() {
    .mb-icon-font-definition();
    content: "\1026d";
}

.mbi-clipboard-x() {
    .mb-icon-font-definition();
    content: "\1026e";
}

.mbi-clock() {
    .mb-icon-font-definition();
    content: "\1026f";
}

.mbi-cloud() {
    .mb-icon-font-definition();
    content: "\10270";
}

.mbi-cloud-download() {
    .mb-icon-font-definition();
    content: "\10271";
}

.mbi-cloud-fog() {
    .mb-icon-font-definition();
    content: "\10272";
}

.mbi-cloud-off() {
    .mb-icon-font-definition();
    content: "\10273";
}

.mbi-cloud-rain() {
    .mb-icon-font-definition();
    content: "\10274";
}

.mbi-cloud-snow() {
    .mb-icon-font-definition();
    content: "\10275";
}

.mbi-cloud-storm() {
    .mb-icon-font-definition();
    content: "\10276";
}

.mbi-cloud-upload() {
    .mb-icon-font-definition();
    content: "\10277";
}

.mbi-code() {
    .mb-icon-font-definition();
    content: "\10278";
}

.mbi-code-minus() {
    .mb-icon-font-definition();
    content: "\10279";
}

.mbi-code-plus() {
    .mb-icon-font-definition();
    content: "\1027a";
}

.mbi-coin() {
    .mb-icon-font-definition();
    content: "\1027b";
}

.mbi-color-picker() {
    .mb-icon-font-definition();
    content: "\1027c";
}

.mbi-color-swatch() {
    .mb-icon-font-definition();
    content: "\1027d";
}

.mbi-column-insert-left() {
    .mb-icon-font-definition();
    content: "\1027e";
}

.mbi-column-insert-right() {
    .mb-icon-font-definition();
    content: "\1027f";
}

.mbi-columns() {
    .mb-icon-font-definition();
    content: "\10280";
}

.mbi-comet() {
    .mb-icon-font-definition();
    content: "\10281";
}

.mbi-command() {
    .mb-icon-font-definition();
    content: "\10282";
}

.mbi-compass() {
    .mb-icon-font-definition();
    content: "\10283";
}

.mbi-confetti() {
    .mb-icon-font-definition();
    content: "\10284";
}

.mbi-container() {
    .mb-icon-font-definition();
    content: "\10285";
}

.mbi-contrast() {
    .mb-icon-font-definition();
    content: "\10286";
}

.mbi-copy() {
    .mb-icon-font-definition();
    content: "\10287";
}

.mbi-copyleft() {
    .mb-icon-font-definition();
    content: "\10288";
}

.mbi-copyright() {
    .mb-icon-font-definition();
    content: "\10289";
}

.mbi-corner-down-left() {
    .mb-icon-font-definition();
    content: "\1028a";
}

.mbi-corner-down-left-double() {
    .mb-icon-font-definition();
    content: "\1028b";
}

.mbi-corner-down-right() {
    .mb-icon-font-definition();
    content: "\1028c";
}

.mbi-corner-down-right-double() {
    .mb-icon-font-definition();
    content: "\1028d";
}

.mbi-corner-left-down() {
    .mb-icon-font-definition();
    content: "\1028e";
}

.mbi-corner-left-down-double() {
    .mb-icon-font-definition();
    content: "\1028f";
}

.mbi-corner-left-up() {
    .mb-icon-font-definition();
    content: "\10290";
}

.mbi-corner-left-up-double() {
    .mb-icon-font-definition();
    content: "\10291";
}

.mbi-corner-right-down() {
    .mb-icon-font-definition();
    content: "\10292";
}

.mbi-corner-right-down-double() {
    .mb-icon-font-definition();
    content: "\10293";
}

.mbi-corner-right-up() {
    .mb-icon-font-definition();
    content: "\10294";
}

.mbi-corner-right-up-double() {
    .mb-icon-font-definition();
    content: "\10295";
}

.mbi-corner-up-left() {
    .mb-icon-font-definition();
    content: "\10296";
}

.mbi-corner-up-left-double() {
    .mb-icon-font-definition();
    content: "\10297";
}

.mbi-corner-up-right() {
    .mb-icon-font-definition();
    content: "\10298";
}

.mbi-corner-up-right-double() {
    .mb-icon-font-definition();
    content: "\10299";
}

.mbi-credit-card() {
    .mb-icon-font-definition();
    content: "\1029a";
}

.mbi-credit-card-off() {
    .mb-icon-font-definition();
    content: "\1029b";
}

.mbi-crop() {
    .mb-icon-font-definition();
    content: "\1029c";
}

.mbi-crosshair() {
    .mb-icon-font-definition();
    content: "\1029d";
}

.mbi-crown() {
    .mb-icon-font-definition();
    content: "\1029e";
}

.mbi-crown-off() {
    .mb-icon-font-definition();
    content: "\1029f";
}

.mbi-curly-loop() {
    .mb-icon-font-definition();
    content: "\102a0";
}

.mbi-currency-bahraini() {
    .mb-icon-font-definition();
    content: "\102a1";
}

.mbi-currency-bath() {
    .mb-icon-font-definition();
    content: "\102a2";
}

.mbi-currency-bitcoin() {
    .mb-icon-font-definition();
    content: "\102a3";
}

.mbi-currency-cent() {
    .mb-icon-font-definition();
    content: "\102a4";
}

.mbi-currency-dinar() {
    .mb-icon-font-definition();
    content: "\102a5";
}

.mbi-currency-dirham() {
    .mb-icon-font-definition();
    content: "\102a6";
}

.mbi-currency-dollar() {
    .mb-icon-font-definition();
    content: "\102a7";
}

.mbi-currency-dollar-australian() {
    .mb-icon-font-definition();
    content: "\102a8";
}

.mbi-currency-dollar-canadian() {
    .mb-icon-font-definition();
    content: "\102a9";
}

.mbi-currency-dollar-singapore() {
    .mb-icon-font-definition();
    content: "\102aa";
}

.mbi-currency-ethereum() {
    .mb-icon-font-definition();
    content: "\102ab";
}

.mbi-currency-euro() {
    .mb-icon-font-definition();
    content: "\102ac";
}

.mbi-currency-forint() {
    .mb-icon-font-definition();
    content: "\102ad";
}

.mbi-currency-frank() {
    .mb-icon-font-definition();
    content: "\102ae";
}

.mbi-currency-krone-czech() {
    .mb-icon-font-definition();
    content: "\102af";
}

.mbi-currency-krone-danish() {
    .mb-icon-font-definition();
    content: "\102b0";
}

.mbi-currency-krone-swedish() {
    .mb-icon-font-definition();
    content: "\102b1";
}

.mbi-currency-leu() {
    .mb-icon-font-definition();
    content: "\102b2";
}

.mbi-currency-lira() {
    .mb-icon-font-definition();
    content: "\102b3";
}

.mbi-currency-litecoin() {
    .mb-icon-font-definition();
    content: "\102b4";
}

.mbi-currency-naira() {
    .mb-icon-font-definition();
    content: "\102b5";
}

.mbi-currency-pound() {
    .mb-icon-font-definition();
    content: "\102b6";
}

.mbi-currency-real() {
    .mb-icon-font-definition();
    content: "\102b7";
}

.mbi-currency-renminbi() {
    .mb-icon-font-definition();
    content: "\102b8";
}

.mbi-currency-ripple() {
    .mb-icon-font-definition();
    content: "\102b9";
}

.mbi-currency-riyal() {
    .mb-icon-font-definition();
    content: "\102ba";
}

.mbi-currency-rubel() {
    .mb-icon-font-definition();
    content: "\102bb";
}

.mbi-currency-rupee() {
    .mb-icon-font-definition();
    content: "\102bc";
}

.mbi-currency-shekel() {
    .mb-icon-font-definition();
    content: "\102bd";
}

.mbi-currency-taka() {
    .mb-icon-font-definition();
    content: "\102be";
}

.mbi-currency-tugrik() {
    .mb-icon-font-definition();
    content: "\102bf";
}

.mbi-currency-won() {
    .mb-icon-font-definition();
    content: "\102c0";
}

.mbi-currency-yen() {
    .mb-icon-font-definition();
    content: "\102c1";
}

.mbi-currency-zloty() {
    .mb-icon-font-definition();
    content: "\102c2";
}

.mbi-current-location() {
    .mb-icon-font-definition();
    content: "\102c3";
}

.mbi-cursor-text() {
    .mb-icon-font-definition();
    content: "\102c4";
}

.mbi-cut() {
    .mb-icon-font-definition();
    content: "\102c5";
}

.mbi-dashboard() {
    .mb-icon-font-definition();
    content: "\102c6";
}

.mbi-database() {
    .mb-icon-font-definition();
    content: "\102c7";
}

.mbi-database-export() {
    .mb-icon-font-definition();
    content: "\102c8";
}

.mbi-database-import() {
    .mb-icon-font-definition();
    content: "\102c9";
}

.mbi-database-off() {
    .mb-icon-font-definition();
    content: "\102ca";
}

.mbi-details() {
    .mb-icon-font-definition();
    content: "\102cb";
}

.mbi-device-analytics() {
    .mb-icon-font-definition();
    content: "\102cc";
}

.mbi-device-audio-tape() {
    .mb-icon-font-definition();
    content: "\102cd";
}

.mbi-device-cctv() {
    .mb-icon-font-definition();
    content: "\102ce";
}

.mbi-device-computer-camera() {
    .mb-icon-font-definition();
    content: "\102cf";
}

.mbi-device-computer-camera-off() {
    .mb-icon-font-definition();
    content: "\102d0";
}

.mbi-device-desktop() {
    .mb-icon-font-definition();
    content: "\102d1";
}

.mbi-device-desktop-analytics() {
    .mb-icon-font-definition();
    content: "\102d2";
}

.mbi-device-desktop-off() {
    .mb-icon-font-definition();
    content: "\102d3";
}

.mbi-device-floppy() {
    .mb-icon-font-definition();
    content: "\102d4";
}

.mbi-device-gamepad() {
    .mb-icon-font-definition();
    content: "\102d5";
}

.mbi-device-laptop() {
    .mb-icon-font-definition();
    content: "\102d6";
}

.mbi-device-mobile() {
    .mb-icon-font-definition();
    content: "\102d7";
}

.mbi-device-mobile-message() {
    .mb-icon-font-definition();
    content: "\102d8";
}

.mbi-device-mobile-rotated() {
    .mb-icon-font-definition();
    content: "\102d9";
}

.mbi-device-mobile-vibration() {
    .mb-icon-font-definition();
    content: "\102da";
}

.mbi-device-speaker() {
    .mb-icon-font-definition();
    content: "\102db";
}

.mbi-device-tablet() {
    .mb-icon-font-definition();
    content: "\102dc";
}

.mbi-device-tv() {
    .mb-icon-font-definition();
    content: "\102dd";
}

.mbi-device-watch() {
    .mb-icon-font-definition();
    content: "\102de";
}

.mbi-devices() {
    .mb-icon-font-definition();
    content: "\102df";
}

.mbi-devices-2() {
    .mb-icon-font-definition();
    content: "\102e0";
}

.mbi-devices-pc() {
    .mb-icon-font-definition();
    content: "\102e1";
}

.mbi-diamond() {
    .mb-icon-font-definition();
    content: "\102e2";
}

.mbi-dice() {
    .mb-icon-font-definition();
    content: "\102e3";
}

.mbi-dimensions() {
    .mb-icon-font-definition();
    content: "\102e4";
}

.mbi-direction() {
    .mb-icon-font-definition();
    content: "\102e5";
}

.mbi-direction-horizontal() {
    .mb-icon-font-definition();
    content: "\102e6";
}

.mbi-directions() {
    .mb-icon-font-definition();
    content: "\102e7";
}

.mbi-disabled() {
    .mb-icon-font-definition();
    content: "\102e8";
}

.mbi-disabled-2() {
    .mb-icon-font-definition();
    content: "\102e9";
}

.mbi-disc() {
    .mb-icon-font-definition();
    content: "\102ea";
}

.mbi-discount() {
    .mb-icon-font-definition();
    content: "\102eb";
}

.mbi-discount-2() {
    .mb-icon-font-definition();
    content: "\102ec";
}

.mbi-divide() {
    .mb-icon-font-definition();
    content: "\102ed";
}

.mbi-dna() {
    .mb-icon-font-definition();
    content: "\102ee";
}

.mbi-dots() {
    .mb-icon-font-definition();
    content: "\102ef";
}

.mbi-dots-circle-horizontal() {
    .mb-icon-font-definition();
    content: "\102f0";
}

.mbi-dots-diagonal() {
    .mb-icon-font-definition();
    content: "\102f1";
}

.mbi-dots-diagonal-2() {
    .mb-icon-font-definition();
    content: "\102f2";
}

.mbi-dots-vertical() {
    .mb-icon-font-definition();
    content: "\102f3";
}

.mbi-download() {
    .mb-icon-font-definition();
    content: "\102f4";
}

.mbi-drag-drop() {
    .mb-icon-font-definition();
    content: "\102f5";
}

.mbi-drag-drop-2() {
    .mb-icon-font-definition();
    content: "\102f6";
}

.mbi-drone() {
    .mb-icon-font-definition();
    content: "\102f7";
}

.mbi-drone-off() {
    .mb-icon-font-definition();
    content: "\102f8";
}

.mbi-droplet() {
    .mb-icon-font-definition();
    content: "\102f9";
}

.mbi-droplet-filled() {
    .mb-icon-font-definition();
    content: "\102fa";
}

.mbi-droplet-filled-2() {
    .mb-icon-font-definition();
    content: "\102fb";
}

.mbi-droplet-half() {
    .mb-icon-font-definition();
    content: "\102fc";
}

.mbi-droplet-half-2() {
    .mb-icon-font-definition();
    content: "\102fd";
}

.mbi-droplet-off() {
    .mb-icon-font-definition();
    content: "\102fe";
}

.mbi-ear() {
    .mb-icon-font-definition();
    content: "\102ff";
}

.mbi-ear-off() {
    .mb-icon-font-definition();
    content: "\10300";
}

.mbi-edit() {
    .mb-icon-font-definition();
    content: "\10301";
}

.mbi-edit-circle() {
    .mb-icon-font-definition();
    content: "\10302";
}

.mbi-egg() {
    .mb-icon-font-definition();
    content: "\10303";
}

.mbi-emphasis() {
    .mb-icon-font-definition();
    content: "\10304";
}

.mbi-equal() {
    .mb-icon-font-definition();
    content: "\10305";
}

.mbi-equal-not() {
    .mb-icon-font-definition();
    content: "\10306";
}

.mbi-eraser() {
    .mb-icon-font-definition();
    content: "\10307";
}

.mbi-exchange() {
    .mb-icon-font-definition();
    content: "\10308";
}

.mbi-exposure() {
    .mb-icon-font-definition();
    content: "\10309";
}

.mbi-external-link() {
    .mb-icon-font-definition();
    content: "\1030a";
}

.mbi-eye() {
    .mb-icon-font-definition();
    content: "\1030b";
}

.mbi-eye-check() {
    .mb-icon-font-definition();
    content: "\1030c";
}

.mbi-eye-off() {
    .mb-icon-font-definition();
    content: "\1030d";
}

.mbi-eyeglass() {
    .mb-icon-font-definition();
    content: "\1030e";
}

.mbi-eyeglass-2() {
    .mb-icon-font-definition();
    content: "\1030f";
}

.mbi-face-id() {
    .mb-icon-font-definition();
    content: "\10310";
}

.mbi-fall() {
    .mb-icon-font-definition();
    content: "\10311";
}

.mbi-feather() {
    .mb-icon-font-definition();
    content: "\10312";
}

.mbi-file() {
    .mb-icon-font-definition();
    content: "\10313";
}

.mbi-file-alert() {
    .mb-icon-font-definition();
    content: "\10314";
}

.mbi-file-analytics() {
    .mb-icon-font-definition();
    content: "\10315";
}

.mbi-file-certificate() {
    .mb-icon-font-definition();
    content: "\10316";
}

.mbi-file-check() {
    .mb-icon-font-definition();
    content: "\10317";
}

.mbi-file-code() {
    .mb-icon-font-definition();
    content: "\10318";
}

.mbi-file-code-2() {
    .mb-icon-font-definition();
    content: "\10319";
}

.mbi-file-diff() {
    .mb-icon-font-definition();
    content: "\1031a";
}

.mbi-file-dislike() {
    .mb-icon-font-definition();
    content: "\1031b";
}

.mbi-file-download() {
    .mb-icon-font-definition();
    content: "\1031c";
}

.mbi-file-export() {
    .mb-icon-font-definition();
    content: "\1031d";
}

.mbi-file-horizontal() {
    .mb-icon-font-definition();
    content: "\1031e";
}

.mbi-file-import() {
    .mb-icon-font-definition();
    content: "\1031f";
}

.mbi-file-info() {
    .mb-icon-font-definition();
    content: "\10320";
}

.mbi-file-invoice() {
    .mb-icon-font-definition();
    content: "\10321";
}

.mbi-file-like() {
    .mb-icon-font-definition();
    content: "\10322";
}

.mbi-file-minus() {
    .mb-icon-font-definition();
    content: "\10323";
}

.mbi-file-music() {
    .mb-icon-font-definition();
    content: "\10324";
}

.mbi-file-off() {
    .mb-icon-font-definition();
    content: "\10325";
}

.mbi-file-phone() {
    .mb-icon-font-definition();
    content: "\10326";
}

.mbi-file-plus() {
    .mb-icon-font-definition();
    content: "\10327";
}

.mbi-file-report() {
    .mb-icon-font-definition();
    content: "\10328";
}

.mbi-file-search() {
    .mb-icon-font-definition();
    content: "\10329";
}

.mbi-file-shredder() {
    .mb-icon-font-definition();
    content: "\1032a";
}

.mbi-file-symlink() {
    .mb-icon-font-definition();
    content: "\1032b";
}

.mbi-file-text() {
    .mb-icon-font-definition();
    content: "\1032c";
}

.mbi-file-upload() {
    .mb-icon-font-definition();
    content: "\1032d";
}

.mbi-file-x() {
    .mb-icon-font-definition();
    content: "\1032e";
}

.mbi-file-zip() {
    .mb-icon-font-definition();
    content: "\1032f";
}

.mbi-files() {
    .mb-icon-font-definition();
    content: "\10330";
}

.mbi-files-off() {
    .mb-icon-font-definition();
    content: "\10331";
}

.mbi-filter() {
    .mb-icon-font-definition();
    content: "\10332";
}

.mbi-filter-off() {
    .mb-icon-font-definition();
    content: "\10333";
}

.mbi-fingerprint() {
    .mb-icon-font-definition();
    content: "\10334";
}

.mbi-firetruck() {
    .mb-icon-font-definition();
    content: "\10335";
}

.mbi-flag() {
    .mb-icon-font-definition();
    content: "\10336";
}

.mbi-flag-2() {
    .mb-icon-font-definition();
    content: "\10337";
}

.mbi-flag-3() {
    .mb-icon-font-definition();
    content: "\10338";
}

.mbi-flame() {
    .mb-icon-font-definition();
    content: "\10339";
}

.mbi-flare() {
    .mb-icon-font-definition();
    content: "\1033a";
}

.mbi-flask() {
    .mb-icon-font-definition();
    content: "\1033b";
}

.mbi-flip-horizontal() {
    .mb-icon-font-definition();
    content: "\1033c";
}

.mbi-flip-vertical() {
    .mb-icon-font-definition();
    content: "\1033d";
}

.mbi-float-center() {
    .mb-icon-font-definition();
    content: "\1033e";
}

.mbi-float-left() {
    .mb-icon-font-definition();
    content: "\1033f";
}

.mbi-float-none() {
    .mb-icon-font-definition();
    content: "\10340";
}

.mbi-float-right() {
    .mb-icon-font-definition();
    content: "\10341";
}

.mbi-focus() {
    .mb-icon-font-definition();
    content: "\10342";
}

.mbi-focus-2() {
    .mb-icon-font-definition();
    content: "\10343";
}

.mbi-fold() {
    .mb-icon-font-definition();
    content: "\10344";
}

.mbi-fold-down() {
    .mb-icon-font-definition();
    content: "\10345";
}

.mbi-fold-up() {
    .mb-icon-font-definition();
    content: "\10346";
}

.mbi-folder() {
    .mb-icon-font-definition();
    content: "\10347";
}

.mbi-folder-minus() {
    .mb-icon-font-definition();
    content: "\10348";
}

.mbi-folder-off() {
    .mb-icon-font-definition();
    content: "\10349";
}

.mbi-folder-plus() {
    .mb-icon-font-definition();
    content: "\1034a";
}

.mbi-folder-x() {
    .mb-icon-font-definition();
    content: "\1034b";
}

.mbi-folders() {
    .mb-icon-font-definition();
    content: "\1034c";
}

.mbi-forbid() {
    .mb-icon-font-definition();
    content: "\1034d";
}

.mbi-forbid-2() {
    .mb-icon-font-definition();
    content: "\1034e";
}

.mbi-forklift() {
    .mb-icon-font-definition();
    content: "\1034f";
}

.mbi-forms() {
    .mb-icon-font-definition();
    content: "\10350";
}

.mbi-frame() {
    .mb-icon-font-definition();
    content: "\10351";
}

.mbi-friends() {
    .mb-icon-font-definition();
    content: "\10352";
}

.mbi-gas-station() {
    .mb-icon-font-definition();
    content: "\10353";
}

.mbi-gauge() {
    .mb-icon-font-definition();
    content: "\10354";
}

.mbi-geometry() {
    .mb-icon-font-definition();
    content: "\10355";
}

.mbi-ghost() {
    .mb-icon-font-definition();
    content: "\10356";
}

.mbi-gift() {
    .mb-icon-font-definition();
    content: "\10357";
}

.mbi-git-branch() {
    .mb-icon-font-definition();
    content: "\10358";
}

.mbi-git-commit() {
    .mb-icon-font-definition();
    content: "\10359";
}

.mbi-git-compare() {
    .mb-icon-font-definition();
    content: "\1035a";
}

.mbi-git-fork() {
    .mb-icon-font-definition();
    content: "\1035b";
}

.mbi-git-merge() {
    .mb-icon-font-definition();
    content: "\1035c";
}

.mbi-git-pull-request() {
    .mb-icon-font-definition();
    content: "\1035d";
}

.mbi-glass() {
    .mb-icon-font-definition();
    content: "\1035e";
}

.mbi-glass-full() {
    .mb-icon-font-definition();
    content: "\1035f";
}

.mbi-glass-off() {
    .mb-icon-font-definition();
    content: "\10360";
}

.mbi-globe() {
    .mb-icon-font-definition();
    content: "\10361";
}

.mbi-golf() {
    .mb-icon-font-definition();
    content: "\10362";
}

.mbi-gps() {
    .mb-icon-font-definition();
    content: "\10363";
}

.mbi-grain() {
    .mb-icon-font-definition();
    content: "\10364";
}

.mbi-grid-dots() {
    .mb-icon-font-definition();
    content: "\10365";
}

.mbi-grip-horizontal() {
    .mb-icon-font-definition();
    content: "\10366";
}

.mbi-grip-vertical() {
    .mb-icon-font-definition();
    content: "\10367";
}

.mbi-growth() {
    .mb-icon-font-definition();
    content: "\10368";
}

.mbi-h-1() {
    .mb-icon-font-definition();
    content: "\10369";
}

.mbi-h-2() {
    .mb-icon-font-definition();
    content: "\1036a";
}

.mbi-h-3() {
    .mb-icon-font-definition();
    content: "\1036b";
}

.mbi-h-4() {
    .mb-icon-font-definition();
    content: "\1036c";
}

.mbi-h-5() {
    .mb-icon-font-definition();
    content: "\1036d";
}

.mbi-h-6() {
    .mb-icon-font-definition();
    content: "\1036e";
}

.mbi-hand-finger() {
    .mb-icon-font-definition();
    content: "\1036f";
}

.mbi-hand-little-finger() {
    .mb-icon-font-definition();
    content: "\10370";
}

.mbi-hand-middle-finger() {
    .mb-icon-font-definition();
    content: "\10371";
}

.mbi-hand-off() {
    .mb-icon-font-definition();
    content: "\10372";
}

.mbi-hand-ring-finger() {
    .mb-icon-font-definition();
    content: "\10373";
}

.mbi-hand-rock() {
    .mb-icon-font-definition();
    content: "\10374";
}

.mbi-hand-stop() {
    .mb-icon-font-definition();
    content: "\10375";
}

.mbi-hand-three-fingers() {
    .mb-icon-font-definition();
    content: "\10376";
}

.mbi-hand-two-fingers() {
    .mb-icon-font-definition();
    content: "\10377";
}

.mbi-hanger() {
    .mb-icon-font-definition();
    content: "\10378";
}

.mbi-hash() {
    .mb-icon-font-definition();
    content: "\10379";
}

.mbi-heading() {
    .mb-icon-font-definition();
    content: "\1037a";
}

.mbi-headphones() {
    .mb-icon-font-definition();
    content: "\1037b";
}

.mbi-headphones-off() {
    .mb-icon-font-definition();
    content: "\1037c";
}

.mbi-headset() {
    .mb-icon-font-definition();
    content: "\1037d";
}

.mbi-heart() {
    .mb-icon-font-definition();
    content: "\1037e";
}

.mbi-heart-broken() {
    .mb-icon-font-definition();
    content: "\1037f";
}

.mbi-helicopter() {
    .mb-icon-font-definition();
    content: "\10380";
}

.mbi-helicopter-landing() {
    .mb-icon-font-definition();
    content: "\10381";
}

.mbi-help() {
    .mb-icon-font-definition();
    content: "\10382";
}

.mbi-hexagon() {
    .mb-icon-font-definition();
    content: "\10383";
}

.mbi-hexagon-off() {
    .mb-icon-font-definition();
    content: "\10384";
}

.mbi-hierarchy() {
    .mb-icon-font-definition();
    content: "\10385";
}

.mbi-hierarchy-2() {
    .mb-icon-font-definition();
    content: "\10386";
}

.mbi-history() {
    .mb-icon-font-definition();
    content: "\10387";
}

.mbi-home() {
    .mb-icon-font-definition();
    content: "\10388";
}

.mbi-home-2() {
    .mb-icon-font-definition();
    content: "\10389";
}

.mbi-ice-cream() {
    .mb-icon-font-definition();
    content: "\1038a";
}

.mbi-ice-cream-2() {
    .mb-icon-font-definition();
    content: "\1038b";
}

.mbi-id() {
    .mb-icon-font-definition();
    content: "\1038c";
}

.mbi-inbox() {
    .mb-icon-font-definition();
    content: "\1038d";
}

.mbi-indent-decrease() {
    .mb-icon-font-definition();
    content: "\1038e";
}

.mbi-indent-increase() {
    .mb-icon-font-definition();
    content: "\1038f";
}

.mbi-infinity() {
    .mb-icon-font-definition();
    content: "\10390";
}

.mbi-info-circle() {
    .mb-icon-font-definition();
    content: "\10391";
}

.mbi-info-square() {
    .mb-icon-font-definition();
    content: "\10392";
}

.mbi-italic() {
    .mb-icon-font-definition();
    content: "\10393";
}

.mbi-jump-rope() {
    .mb-icon-font-definition();
    content: "\10394";
}

.mbi-karate() {
    .mb-icon-font-definition();
    content: "\10395";
}

.mbi-key() {
    .mb-icon-font-definition();
    content: "\10396";
}

.mbi-keyboard() {
    .mb-icon-font-definition();
    content: "\10397";
}

.mbi-keyboard-hide() {
    .mb-icon-font-definition();
    content: "\10398";
}

.mbi-keyboard-off() {
    .mb-icon-font-definition();
    content: "\10399";
}

.mbi-keyboard-show() {
    .mb-icon-font-definition();
    content: "\1039a";
}

.mbi-language() {
    .mb-icon-font-definition();
    content: "\1039b";
}

.mbi-layers-difference() {
    .mb-icon-font-definition();
    content: "\1039c";
}

.mbi-layers-intersect() {
    .mb-icon-font-definition();
    content: "\1039d";
}

.mbi-layers-linked() {
    .mb-icon-font-definition();
    content: "\1039e";
}

.mbi-layers-subtract() {
    .mb-icon-font-definition();
    content: "\1039f";
}

.mbi-layers-union() {
    .mb-icon-font-definition();
    content: "\103a0";
}

.mbi-layout() {
    .mb-icon-font-definition();
    content: "\103a1";
}

.mbi-layout-2() {
    .mb-icon-font-definition();
    content: "\103a2";
}

.mbi-layout-align-bottom() {
    .mb-icon-font-definition();
    content: "\103a3";
}

.mbi-layout-align-center() {
    .mb-icon-font-definition();
    content: "\103a4";
}

.mbi-layout-align-left() {
    .mb-icon-font-definition();
    content: "\103a5";
}

.mbi-layout-align-middle() {
    .mb-icon-font-definition();
    content: "\103a6";
}

.mbi-layout-align-right() {
    .mb-icon-font-definition();
    content: "\103a7";
}

.mbi-layout-align-top() {
    .mb-icon-font-definition();
    content: "\103a8";
}

.mbi-layout-bottombar() {
    .mb-icon-font-definition();
    content: "\103a9";
}

.mbi-layout-cards() {
    .mb-icon-font-definition();
    content: "\103aa";
}

.mbi-layout-columns() {
    .mb-icon-font-definition();
    content: "\103ab";
}

.mbi-layout-distribute-horizontal() {
    .mb-icon-font-definition();
    content: "\103ac";
}

.mbi-layout-distribute-vertical() {
    .mb-icon-font-definition();
    content: "\103ad";
}

.mbi-layout-grid() {
    .mb-icon-font-definition();
    content: "\103ae";
}

.mbi-layout-grid-add() {
    .mb-icon-font-definition();
    content: "\103af";
}

.mbi-layout-kanban() {
    .mb-icon-font-definition();
    content: "\103b0";
}

.mbi-layout-list() {
    .mb-icon-font-definition();
    content: "\103b1";
}

.mbi-layout-navbar() {
    .mb-icon-font-definition();
    content: "\103b2";
}

.mbi-layout-rows() {
    .mb-icon-font-definition();
    content: "\103b3";
}

.mbi-layout-sidebar() {
    .mb-icon-font-definition();
    content: "\103b4";
}

.mbi-layout-sidebar-right() {
    .mb-icon-font-definition();
    content: "\103b5";
}

.mbi-leaf() {
    .mb-icon-font-definition();
    content: "\103b6";
}

.mbi-lego() {
    .mb-icon-font-definition();
    content: "\103b7";
}

.mbi-letter-a() {
    .mb-icon-font-definition();
    content: "\103b8";
}

.mbi-letter-b() {
    .mb-icon-font-definition();
    content: "\103b9";
}

.mbi-letter-c() {
    .mb-icon-font-definition();
    content: "\103ba";
}

.mbi-letter-case() {
    .mb-icon-font-definition();
    content: "\103bb";
}

.mbi-letter-case-lower() {
    .mb-icon-font-definition();
    content: "\103bc";
}

.mbi-letter-case-toggle() {
    .mb-icon-font-definition();
    content: "\103bd";
}

.mbi-letter-case-upper() {
    .mb-icon-font-definition();
    content: "\103be";
}

.mbi-letter-d() {
    .mb-icon-font-definition();
    content: "\103bf";
}

.mbi-letter-e() {
    .mb-icon-font-definition();
    content: "\103c0";
}

.mbi-letter-f() {
    .mb-icon-font-definition();
    content: "\103c1";
}

.mbi-letter-g() {
    .mb-icon-font-definition();
    content: "\103c2";
}

.mbi-letter-h() {
    .mb-icon-font-definition();
    content: "\103c3";
}

.mbi-letter-i() {
    .mb-icon-font-definition();
    content: "\103c4";
}

.mbi-letter-j() {
    .mb-icon-font-definition();
    content: "\103c5";
}

.mbi-letter-k() {
    .mb-icon-font-definition();
    content: "\103c6";
}

.mbi-letter-l() {
    .mb-icon-font-definition();
    content: "\103c7";
}

.mbi-letter-m() {
    .mb-icon-font-definition();
    content: "\103c8";
}

.mbi-letter-n() {
    .mb-icon-font-definition();
    content: "\103c9";
}

.mbi-letter-o() {
    .mb-icon-font-definition();
    content: "\103ca";
}

.mbi-letter-p() {
    .mb-icon-font-definition();
    content: "\103cb";
}

.mbi-letter-q() {
    .mb-icon-font-definition();
    content: "\103cc";
}

.mbi-letter-r() {
    .mb-icon-font-definition();
    content: "\103cd";
}

.mbi-letter-s() {
    .mb-icon-font-definition();
    content: "\103ce";
}

.mbi-letter-spacing() {
    .mb-icon-font-definition();
    content: "\103cf";
}

.mbi-letter-t() {
    .mb-icon-font-definition();
    content: "\103d0";
}

.mbi-letter-u() {
    .mb-icon-font-definition();
    content: "\103d1";
}

.mbi-letter-v() {
    .mb-icon-font-definition();
    content: "\103d2";
}

.mbi-letter-w() {
    .mb-icon-font-definition();
    content: "\103d3";
}

.mbi-letter-x() {
    .mb-icon-font-definition();
    content: "\103d4";
}

.mbi-letter-y() {
    .mb-icon-font-definition();
    content: "\103d5";
}

.mbi-letter-z() {
    .mb-icon-font-definition();
    content: "\103d6";
}

.mbi-letters-case() {
    .mb-icon-font-definition();
    content: "\103d7";
}

.mbi-license() {
    .mb-icon-font-definition();
    content: "\103d8";
}

.mbi-lifebuoy() {
    .mb-icon-font-definition();
    content: "\103d9";
}

.mbi-line() {
    .mb-icon-font-definition();
    content: "\103da";
}

.mbi-line-dashed() {
    .mb-icon-font-definition();
    content: "\103db";
}

.mbi-line-dotted() {
    .mb-icon-font-definition();
    content: "\103dc";
}

.mbi-line-height() {
    .mb-icon-font-definition();
    content: "\103dd";
}

.mbi-link() {
    .mb-icon-font-definition();
    content: "\103de";
}

.mbi-list() {
    .mb-icon-font-definition();
    content: "\103df";
}

.mbi-list-check() {
    .mb-icon-font-definition();
    content: "\103e0";
}

.mbi-list-search() {
    .mb-icon-font-definition();
    content: "\103e1";
}

.mbi-live-photo() {
    .mb-icon-font-definition();
    content: "\103e2";
}

.mbi-live-view() {
    .mb-icon-font-definition();
    content: "\103e3";
}

.mbi-loader() {
    .mb-icon-font-definition();
    content: "\103e4";
}

.mbi-loader-quarter() {
    .mb-icon-font-definition();
    content: "\103e5";
}

.mbi-location() {
    .mb-icon-font-definition();
    content: "\103e6";
}

.mbi-lock() {
    .mb-icon-font-definition();
    content: "\103e7";
}

.mbi-lock-access() {
    .mb-icon-font-definition();
    content: "\103e8";
}

.mbi-lock-off() {
    .mb-icon-font-definition();
    content: "\103e9";
}

.mbi-lock-open() {
    .mb-icon-font-definition();
    content: "\103ea";
}

.mbi-login() {
    .mb-icon-font-definition();
    content: "\103eb";
}

.mbi-logout() {
    .mb-icon-font-definition();
    content: "\103ec";
}

.mbi-macro() {
    .mb-icon-font-definition();
    content: "\103ed";
}

.mbi-magnet() {
    .mb-icon-font-definition();
    content: "\103ee";
}

.mbi-mail() {
    .mb-icon-font-definition();
    content: "\103ef";
}

.mbi-mail-forward() {
    .mb-icon-font-definition();
    content: "\103f0";
}

.mbi-mail-opened() {
    .mb-icon-font-definition();
    content: "\103f1";
}

.mbi-mailbox() {
    .mb-icon-font-definition();
    content: "\103f2";
}

.mbi-man() {
    .mb-icon-font-definition();
    content: "\103f3";
}

.mbi-manual-gearbox() {
    .mb-icon-font-definition();
    content: "\103f4";
}

.mbi-map() {
    .mb-icon-font-definition();
    content: "\103f5";
}

.mbi-map-2() {
    .mb-icon-font-definition();
    content: "\103f6";
}

.mbi-map-pin() {
    .mb-icon-font-definition();
    content: "\103f7";
}

.mbi-map-pin-off() {
    .mb-icon-font-definition();
    content: "\103f8";
}

.mbi-map-pins() {
    .mb-icon-font-definition();
    content: "\103f9";
}

.mbi-markdown() {
    .mb-icon-font-definition();
    content: "\103fa";
}

.mbi-marquee() {
    .mb-icon-font-definition();
    content: "\103fb";
}

.mbi-marquee-2() {
    .mb-icon-font-definition();
    content: "\103fc";
}

.mbi-mars() {
    .mb-icon-font-definition();
    content: "\103fd";
}

.mbi-mask() {
    .mb-icon-font-definition();
    content: "\103fe";
}

.mbi-mask-off() {
    .mb-icon-font-definition();
    content: "\103ff";
}

.mbi-massage() {
    .mb-icon-font-definition();
    content: "\10400";
}

.mbi-math() {
    .mb-icon-font-definition();
    content: "\10401";
}

.mbi-math-function() {
    .mb-icon-font-definition();
    content: "\10402";
}

.mbi-math-symbols() {
    .mb-icon-font-definition();
    content: "\10403";
}

.mbi-maximize() {
    .mb-icon-font-definition();
    content: "\10404";
}

.mbi-medal() {
    .mb-icon-font-definition();
    content: "\10405";
}

.mbi-medical-cross() {
    .mb-icon-font-definition();
    content: "\10406";
}

.mbi-menu() {
    .mb-icon-font-definition();
    content: "\10407";
}

.mbi-menu-2() {
    .mb-icon-font-definition();
    content: "\10408";
}

.mbi-message() {
    .mb-icon-font-definition();
    content: "\10409";
}

.mbi-message-2() {
    .mb-icon-font-definition();
    content: "\1040a";
}

.mbi-message-circle() {
    .mb-icon-font-definition();
    content: "\1040b";
}

.mbi-message-circle-2() {
    .mb-icon-font-definition();
    content: "\1040c";
}

.mbi-message-circle-off() {
    .mb-icon-font-definition();
    content: "\1040d";
}

.mbi-message-dots() {
    .mb-icon-font-definition();
    content: "\1040e";
}

.mbi-message-off() {
    .mb-icon-font-definition();
    content: "\1040f";
}

.mbi-message-plus() {
    .mb-icon-font-definition();
    content: "\10410";
}

.mbi-message-report() {
    .mb-icon-font-definition();
    content: "\10411";
}

.mbi-messages() {
    .mb-icon-font-definition();
    content: "\10412";
}

.mbi-messages-off() {
    .mb-icon-font-definition();
    content: "\10413";
}

.mbi-microphone() {
    .mb-icon-font-definition();
    content: "\10414";
}

.mbi-microphone-off() {
    .mb-icon-font-definition();
    content: "\10415";
}

.mbi-minimize() {
    .mb-icon-font-definition();
    content: "\10416";
}

.mbi-minus() {
    .mb-icon-font-definition();
    content: "\10417";
}

.mbi-minus-vertical() {
    .mb-icon-font-definition();
    content: "\10418";
}

.mbi-mist() {
    .mb-icon-font-definition();
    content: "\10419";
}

.mbi-mood-boy() {
    .mb-icon-font-definition();
    content: "\1041a";
}

.mbi-mood-confuzed() {
    .mb-icon-font-definition();
    content: "\1041b";
}

.mbi-mood-crazy-happy() {
    .mb-icon-font-definition();
    content: "\1041c";
}

.mbi-mood-cry() {
    .mb-icon-font-definition();
    content: "\1041d";
}

.mbi-mood-empty() {
    .mb-icon-font-definition();
    content: "\1041e";
}

.mbi-mood-happy() {
    .mb-icon-font-definition();
    content: "\1041f";
}

.mbi-mood-kid() {
    .mb-icon-font-definition();
    content: "\10420";
}

.mbi-mood-neutral() {
    .mb-icon-font-definition();
    content: "\10421";
}

.mbi-mood-sad() {
    .mb-icon-font-definition();
    content: "\10422";
}

.mbi-mood-smile() {
    .mb-icon-font-definition();
    content: "\10423";
}

.mbi-mood-suprised() {
    .mb-icon-font-definition();
    content: "\10424";
}

.mbi-mood-tongue() {
    .mb-icon-font-definition();
    content: "\10425";
}

.mbi-moon() {
    .mb-icon-font-definition();
    content: "\10426";
}

.mbi-moon-2() {
    .mb-icon-font-definition();
    content: "\10427";
}

.mbi-moon-stars() {
    .mb-icon-font-definition();
    content: "\10428";
}

.mbi-moped() {
    .mb-icon-font-definition();
    content: "\10429";
}

.mbi-motorbike() {
    .mb-icon-font-definition();
    content: "\1042a";
}

.mbi-mouse() {
    .mb-icon-font-definition();
    content: "\1042b";
}

.mbi-movie() {
    .mb-icon-font-definition();
    content: "\1042c";
}

.mbi-mug() {
    .mb-icon-font-definition();
    content: "\1042d";
}

.mbi-music() {
    .mb-icon-font-definition();
    content: "\1042e";
}

.mbi-new-section() {
    .mb-icon-font-definition();
    content: "\1042f";
}

.mbi-news() {
    .mb-icon-font-definition();
    content: "\10430";
}

.mbi-nfc() {
    .mb-icon-font-definition();
    content: "\10431";
}

.mbi-note() {
    .mb-icon-font-definition();
    content: "\10432";
}

.mbi-notebook() {
    .mb-icon-font-definition();
    content: "\10433";
}

.mbi-notes() {
    .mb-icon-font-definition();
    content: "\10434";
}

.mbi-notification() {
    .mb-icon-font-definition();
    content: "\10435";
}

.mbi-number-0() {
    .mb-icon-font-definition();
    content: "\10436";
}

.mbi-number-1() {
    .mb-icon-font-definition();
    content: "\10437";
}

.mbi-number-2() {
    .mb-icon-font-definition();
    content: "\10438";
}

.mbi-number-3() {
    .mb-icon-font-definition();
    content: "\10439";
}

.mbi-number-4() {
    .mb-icon-font-definition();
    content: "\1043a";
}

.mbi-number-5() {
    .mb-icon-font-definition();
    content: "\1043b";
}

.mbi-number-6() {
    .mb-icon-font-definition();
    content: "\1043c";
}

.mbi-number-7() {
    .mb-icon-font-definition();
    content: "\1043d";
}

.mbi-number-8() {
    .mb-icon-font-definition();
    content: "\1043e";
}

.mbi-number-9() {
    .mb-icon-font-definition();
    content: "\1043f";
}

.mbi-octagon() {
    .mb-icon-font-definition();
    content: "\10440";
}

.mbi-octagon-off() {
    .mb-icon-font-definition();
    content: "\10441";
}

.mbi-old() {
    .mb-icon-font-definition();
    content: "\10442";
}

.mbi-olympics() {
    .mb-icon-font-definition();
    content: "\10443";
}

.mbi-omega() {
    .mb-icon-font-definition();
    content: "\10444";
}

.mbi-outlet() {
    .mb-icon-font-definition();
    content: "\10445";
}

.mbi-overline() {
    .mb-icon-font-definition();
    content: "\10446";
}

.mbi-package() {
    .mb-icon-font-definition();
    content: "\10447";
}

.mbi-pacman() {
    .mb-icon-font-definition();
    content: "\10448";
}

.mbi-page-break() {
    .mb-icon-font-definition();
    content: "\10449";
}

.mbi-paint() {
    .mb-icon-font-definition();
    content: "\1044a";
}

.mbi-palette() {
    .mb-icon-font-definition();
    content: "\1044b";
}

.mbi-panorama-horizontal() {
    .mb-icon-font-definition();
    content: "\1044c";
}

.mbi-panorama-vertical() {
    .mb-icon-font-definition();
    content: "\1044d";
}

.mbi-paperclip() {
    .mb-icon-font-definition();
    content: "\1044e";
}

.mbi-parachute() {
    .mb-icon-font-definition();
    content: "\1044f";
}

.mbi-parentheses() {
    .mb-icon-font-definition();
    content: "\10450";
}

.mbi-parking() {
    .mb-icon-font-definition();
    content: "\10451";
}

.mbi-peace() {
    .mb-icon-font-definition();
    content: "\10452";
}

.mbi-pencil() {
    .mb-icon-font-definition();
    content: "\10453";
}

.mbi-pennant() {
    .mb-icon-font-definition();
    content: "\10454";
}

.mbi-percentage() {
    .mb-icon-font-definition();
    content: "\10455";
}

.mbi-perspective() {
    .mb-icon-font-definition();
    content: "\10456";
}

.mbi-phone() {
    .mb-icon-font-definition();
    content: "\10457";
}

.mbi-phone-call() {
    .mb-icon-font-definition();
    content: "\10458";
}

.mbi-phone-calling() {
    .mb-icon-font-definition();
    content: "\10459";
}

.mbi-phone-check() {
    .mb-icon-font-definition();
    content: "\1045a";
}

.mbi-phone-incoming() {
    .mb-icon-font-definition();
    content: "\1045b";
}

.mbi-phone-off() {
    .mb-icon-font-definition();
    content: "\1045c";
}

.mbi-phone-outgoing() {
    .mb-icon-font-definition();
    content: "\1045d";
}

.mbi-phone-pause() {
    .mb-icon-font-definition();
    content: "\1045e";
}

.mbi-phone-plus() {
    .mb-icon-font-definition();
    content: "\1045f";
}

.mbi-phone-x() {
    .mb-icon-font-definition();
    content: "\10460";
}

.mbi-photo() {
    .mb-icon-font-definition();
    content: "\10461";
}

.mbi-photo-off() {
    .mb-icon-font-definition();
    content: "\10462";
}

.mbi-physotherapist() {
    .mb-icon-font-definition();
    content: "\10463";
}

.mbi-picture-in-picture() {
    .mb-icon-font-definition();
    content: "\10464";
}

.mbi-picture-in-picture-off() {
    .mb-icon-font-definition();
    content: "\10465";
}

.mbi-picture-in-picture-on() {
    .mb-icon-font-definition();
    content: "\10466";
}

.mbi-pill() {
    .mb-icon-font-definition();
    content: "\10467";
}

.mbi-pin() {
    .mb-icon-font-definition();
    content: "\10468";
}

.mbi-pinned() {
    .mb-icon-font-definition();
    content: "\10469";
}

.mbi-pinned-off() {
    .mb-icon-font-definition();
    content: "\1046a";
}

.mbi-pizza() {
    .mb-icon-font-definition();
    content: "\1046b";
}

.mbi-plane() {
    .mb-icon-font-definition();
    content: "\1046c";
}

.mbi-plane-arrival() {
    .mb-icon-font-definition();
    content: "\1046d";
}

.mbi-plane-departure() {
    .mb-icon-font-definition();
    content: "\1046e";
}

.mbi-planet() {
    .mb-icon-font-definition();
    content: "\1046f";
}

.mbi-plant() {
    .mb-icon-font-definition();
    content: "\10470";
}

.mbi-plant-2() {
    .mb-icon-font-definition();
    content: "\10471";
}

.mbi-play-card() {
    .mb-icon-font-definition();
    content: "\10472";
}

.mbi-player-pause() {
    .mb-icon-font-definition();
    content: "\10473";
}

.mbi-player-play() {
    .mb-icon-font-definition();
    content: "\10474";
}

.mbi-player-record() {
    .mb-icon-font-definition();
    content: "\10475";
}

.mbi-player-skip-back() {
    .mb-icon-font-definition();
    content: "\10476";
}

.mbi-player-skip-forward() {
    .mb-icon-font-definition();
    content: "\10477";
}

.mbi-player-stop() {
    .mb-icon-font-definition();
    content: "\10478";
}

.mbi-player-track-next() {
    .mb-icon-font-definition();
    content: "\10479";
}

.mbi-player-track-prev() {
    .mb-icon-font-definition();
    content: "\1047a";
}

.mbi-playlist() {
    .mb-icon-font-definition();
    content: "\1047b";
}

.mbi-plug() {
    .mb-icon-font-definition();
    content: "\1047c";
}

.mbi-plus() {
    .mb-icon-font-definition();
    content: "\1047d";
}

.mbi-point() {
    .mb-icon-font-definition();
    content: "\1047e";
}

.mbi-pokeball() {
    .mb-icon-font-definition();
    content: "\1047f";
}

.mbi-polaroid() {
    .mb-icon-font-definition();
    content: "\10480";
}

.mbi-pool() {
    .mb-icon-font-definition();
    content: "\10481";
}

.mbi-power() {
    .mb-icon-font-definition();
    content: "\10482";
}

.mbi-pray() {
    .mb-icon-font-definition();
    content: "\10483";
}

.mbi-presentation() {
    .mb-icon-font-definition();
    content: "\10484";
}

.mbi-presentation-analytics() {
    .mb-icon-font-definition();
    content: "\10485";
}

.mbi-printer() {
    .mb-icon-font-definition();
    content: "\10486";
}

.mbi-prompt() {
    .mb-icon-font-definition();
    content: "\10487";
}

.mbi-propeller() {
    .mb-icon-font-definition();
    content: "\10488";
}

.mbi-puzzle() {
    .mb-icon-font-definition();
    content: "\10489";
}

.mbi-pyramid() {
    .mb-icon-font-definition();
    content: "\1048a";
}

.mbi-qrcode() {
    .mb-icon-font-definition();
    content: "\1048b";
}

.mbi-question-mark() {
    .mb-icon-font-definition();
    content: "\1048c";
}

.mbi-radioactive() {
    .mb-icon-font-definition();
    content: "\1048d";
}

.mbi-radius-bottom-left() {
    .mb-icon-font-definition();
    content: "\1048e";
}

.mbi-radius-bottom-right() {
    .mb-icon-font-definition();
    content: "\1048f";
}

.mbi-radius-top-left() {
    .mb-icon-font-definition();
    content: "\10490";
}

.mbi-radius-top-right() {
    .mb-icon-font-definition();
    content: "\10491";
}

.mbi-rainbow() {
    .mb-icon-font-definition();
    content: "\10492";
}

.mbi-receipt() {
    .mb-icon-font-definition();
    content: "\10493";
}

.mbi-receipt-2() {
    .mb-icon-font-definition();
    content: "\10494";
}

.mbi-receipt-off() {
    .mb-icon-font-definition();
    content: "\10495";
}

.mbi-receipt-refund() {
    .mb-icon-font-definition();
    content: "\10496";
}

.mbi-receipt-tax() {
    .mb-icon-font-definition();
    content: "\10497";
}

.mbi-recharging() {
    .mb-icon-font-definition();
    content: "\10498";
}

.mbi-record-mail() {
    .mb-icon-font-definition();
    content: "\10499";
}

.mbi-rectangle() {
    .mb-icon-font-definition();
    content: "\1049a";
}

.mbi-rectangle-vertical() {
    .mb-icon-font-definition();
    content: "\1049b";
}

.mbi-recycle() {
    .mb-icon-font-definition();
    content: "\1049c";
}

.mbi-refresh() {
    .mb-icon-font-definition();
    content: "\1049d";
}

.mbi-refresh-alert() {
    .mb-icon-font-definition();
    content: "\1049e";
}

.mbi-registered() {
    .mb-icon-font-definition();
    content: "\1049f";
}

.mbi-relation-many-to-many() {
    .mb-icon-font-definition();
    content: "\104a0";
}

.mbi-relation-one-to-many() {
    .mb-icon-font-definition();
    content: "\104a1";
}

.mbi-relation-one-to-one() {
    .mb-icon-font-definition();
    content: "\104a2";
}

.mbi-repeat() {
    .mb-icon-font-definition();
    content: "\104a3";
}

.mbi-repeat-once() {
    .mb-icon-font-definition();
    content: "\104a4";
}

.mbi-replace() {
    .mb-icon-font-definition();
    content: "\104a5";
}

.mbi-report() {
    .mb-icon-font-definition();
    content: "\104a6";
}

.mbi-report-analytics() {
    .mb-icon-font-definition();
    content: "\104a7";
}

.mbi-report-medical() {
    .mb-icon-font-definition();
    content: "\104a8";
}

.mbi-report-money() {
    .mb-icon-font-definition();
    content: "\104a9";
}

.mbi-resize() {
    .mb-icon-font-definition();
    content: "\104aa";
}

.mbi-ripple() {
    .mb-icon-font-definition();
    content: "\104ab";
}

.mbi-road-sign() {
    .mb-icon-font-definition();
    content: "\104ac";
}

.mbi-rocket() {
    .mb-icon-font-definition();
    content: "\104ad";
}

.mbi-rotate() {
    .mb-icon-font-definition();
    content: "\104ae";
}

.mbi-rotate-2() {
    .mb-icon-font-definition();
    content: "\104af";
}

.mbi-rotate-clockwise() {
    .mb-icon-font-definition();
    content: "\104b0";
}

.mbi-rotate-clockwise-2() {
    .mb-icon-font-definition();
    content: "\104b1";
}

.mbi-rotate-rectangle() {
    .mb-icon-font-definition();
    content: "\104b2";
}

.mbi-route() {
    .mb-icon-font-definition();
    content: "\104b3";
}

.mbi-router() {
    .mb-icon-font-definition();
    content: "\104b4";
}

.mbi-row-insert-bottom() {
    .mb-icon-font-definition();
    content: "\104b5";
}

.mbi-row-insert-top() {
    .mb-icon-font-definition();
    content: "\104b6";
}

.mbi-rss() {
    .mb-icon-font-definition();
    content: "\104b7";
}

.mbi-ruler() {
    .mb-icon-font-definition();
    content: "\104b8";
}

.mbi-ruler-2() {
    .mb-icon-font-definition();
    content: "\104b9";
}

.mbi-run() {
    .mb-icon-font-definition();
    content: "\104ba";
}

.mbi-sailboat() {
    .mb-icon-font-definition();
    content: "\104bb";
}

.mbi-satellite() {
    .mb-icon-font-definition();
    content: "\104bc";
}

.mbi-scale() {
    .mb-icon-font-definition();
    content: "\104bd";
}

.mbi-scan() {
    .mb-icon-font-definition();
    content: "\104be";
}

.mbi-school() {
    .mb-icon-font-definition();
    content: "\104bf";
}

.mbi-scissors() {
    .mb-icon-font-definition();
    content: "\104c0";
}

.mbi-scooter() {
    .mb-icon-font-definition();
    content: "\104c1";
}

.mbi-scooter-electric() {
    .mb-icon-font-definition();
    content: "\104c2";
}

.mbi-screen-share() {
    .mb-icon-font-definition();
    content: "\104c3";
}

.mbi-screen-share-off() {
    .mb-icon-font-definition();
    content: "\104c4";
}

.mbi-scuba-mask() {
    .mb-icon-font-definition();
    content: "\104c5";
}

.mbi-search() {
    .mb-icon-font-definition();
    content: "\104c6";
}

.mbi-section() {
    .mb-icon-font-definition();
    content: "\104c7";
}

.mbi-seeding() {
    .mb-icon-font-definition();
    content: "\104c8";
}

.mbi-select() {
    .mb-icon-font-definition();
    content: "\104c9";
}

.mbi-selector() {
    .mb-icon-font-definition();
    content: "\104ca";
}

.mbi-send() {
    .mb-icon-font-definition();
    content: "\104cb";
}

.mbi-separator() {
    .mb-icon-font-definition();
    content: "\104cc";
}

.mbi-separator-horizontal() {
    .mb-icon-font-definition();
    content: "\104cd";
}

.mbi-separator-vertical() {
    .mb-icon-font-definition();
    content: "\104ce";
}

.mbi-server() {
    .mb-icon-font-definition();
    content: "\104cf";
}

.mbi-servicemark() {
    .mb-icon-font-definition();
    content: "\104d0";
}

.mbi-settings() {
    .mb-icon-font-definition();
    content: "\104d1";
}

.mbi-settings-automation() {
    .mb-icon-font-definition();
    content: "\104d2";
}

.mbi-shadow() {
    .mb-icon-font-definition();
    content: "\104d3";
}

.mbi-shadow-off() {
    .mb-icon-font-definition();
    content: "\104d4";
}

.mbi-shape() {
    .mb-icon-font-definition();
    content: "\104d5";
}

.mbi-shape-2() {
    .mb-icon-font-definition();
    content: "\104d6";
}

.mbi-shape-3() {
    .mb-icon-font-definition();
    content: "\104d7";
}

.mbi-share() {
    .mb-icon-font-definition();
    content: "\104d8";
}

.mbi-shield() {
    .mb-icon-font-definition();
    content: "\104d9";
}

.mbi-shield-check() {
    .mb-icon-font-definition();
    content: "\104da";
}

.mbi-shield-lock() {
    .mb-icon-font-definition();
    content: "\104db";
}

.mbi-shield-off() {
    .mb-icon-font-definition();
    content: "\104dc";
}

.mbi-shield-x() {
    .mb-icon-font-definition();
    content: "\104dd";
}

.mbi-ship() {
    .mb-icon-font-definition();
    content: "\104de";
}

.mbi-shirt() {
    .mb-icon-font-definition();
    content: "\104df";
}

.mbi-shopping-cart() {
    .mb-icon-font-definition();
    content: "\104e0";
}

.mbi-shopping-cart-discount() {
    .mb-icon-font-definition();
    content: "\104e1";
}

.mbi-shopping-cart-off() {
    .mb-icon-font-definition();
    content: "\104e2";
}

.mbi-shopping-cart-plus() {
    .mb-icon-font-definition();
    content: "\104e3";
}

.mbi-shopping-cart-x() {
    .mb-icon-font-definition();
    content: "\104e4";
}

.mbi-shredder() {
    .mb-icon-font-definition();
    content: "\104e5";
}

.mbi-signature() {
    .mb-icon-font-definition();
    content: "\104e6";
}

.mbi-sitemap() {
    .mb-icon-font-definition();
    content: "\104e7";
}

.mbi-skateboard() {
    .mb-icon-font-definition();
    content: "\104e8";
}

.mbi-slice() {
    .mb-icon-font-definition();
    content: "\104e9";
}

.mbi-slideshow() {
    .mb-icon-font-definition();
    content: "\104ea";
}

.mbi-smart-home() {
    .mb-icon-font-definition();
    content: "\104eb";
}

.mbi-smoking() {
    .mb-icon-font-definition();
    content: "\104ec";
}

.mbi-smoking-no() {
    .mb-icon-font-definition();
    content: "\104ed";
}

.mbi-snowflake() {
    .mb-icon-font-definition();
    content: "\104ee";
}

.mbi-soccer-field() {
    .mb-icon-font-definition();
    content: "\104ef";
}

.mbi-social() {
    .mb-icon-font-definition();
    content: "\104f0";
}

.mbi-sock() {
    .mb-icon-font-definition();
    content: "\104f1";
}

.mbi-sort-ascending() {
    .mb-icon-font-definition();
    content: "\104f2";
}

.mbi-sort-ascending-2() {
    .mb-icon-font-definition();
    content: "\104f3";
}

.mbi-sort-descending() {
    .mb-icon-font-definition();
    content: "\104f4";
}

.mbi-sort-descending-2() {
    .mb-icon-font-definition();
    content: "\104f5";
}

.mbi-space() {
    .mb-icon-font-definition();
    content: "\104f6";
}

.mbi-speakerphone() {
    .mb-icon-font-definition();
    content: "\104f7";
}

.mbi-speedboat() {
    .mb-icon-font-definition();
    content: "\104f8";
}

.mbi-sport-billard() {
    .mb-icon-font-definition();
    content: "\104f9";
}

.mbi-square() {
    .mb-icon-font-definition();
    content: "\104fa";
}

.mbi-square-0() {
    .mb-icon-font-definition();
    content: "\104fb";
}

.mbi-square-1() {
    .mb-icon-font-definition();
    content: "\104fc";
}

.mbi-square-2() {
    .mb-icon-font-definition();
    content: "\104fd";
}

.mbi-square-3() {
    .mb-icon-font-definition();
    content: "\104fe";
}

.mbi-square-4() {
    .mb-icon-font-definition();
    content: "\104ff";
}

.mbi-square-5() {
    .mb-icon-font-definition();
    content: "\10500";
}

.mbi-square-6() {
    .mb-icon-font-definition();
    content: "\10501";
}

.mbi-square-7() {
    .mb-icon-font-definition();
    content: "\10502";
}

.mbi-square-8() {
    .mb-icon-font-definition();
    content: "\10503";
}

.mbi-square-9() {
    .mb-icon-font-definition();
    content: "\10504";
}

.mbi-square-check() {
    .mb-icon-font-definition();
    content: "\10505";
}

.mbi-square-dot() {
    .mb-icon-font-definition();
    content: "\10506";
}

.mbi-square-forbid() {
    .mb-icon-font-definition();
    content: "\10507";
}

.mbi-square-forbid-2() {
    .mb-icon-font-definition();
    content: "\10508";
}

.mbi-square-minus() {
    .mb-icon-font-definition();
    content: "\10509";
}

.mbi-square-off() {
    .mb-icon-font-definition();
    content: "\1050a";
}

.mbi-square-plus() {
    .mb-icon-font-definition();
    content: "\1050b";
}

.mbi-square-root() {
    .mb-icon-font-definition();
    content: "\1050c";
}

.mbi-square-root-2() {
    .mb-icon-font-definition();
    content: "\1050d";
}

.mbi-square-rotated() {
    .mb-icon-font-definition();
    content: "\1050e";
}

.mbi-square-rotated-off() {
    .mb-icon-font-definition();
    content: "\1050f";
}

.mbi-square-toggle() {
    .mb-icon-font-definition();
    content: "\10510";
}

.mbi-square-toggle-horizontal() {
    .mb-icon-font-definition();
    content: "\10511";
}

.mbi-square-x() {
    .mb-icon-font-definition();
    content: "\10512";
}

.mbi-squares-diagonal() {
    .mb-icon-font-definition();
    content: "\10513";
}

.mbi-squares-filled() {
    .mb-icon-font-definition();
    content: "\10514";
}

.mbi-stack() {
    .mb-icon-font-definition();
    content: "\10515";
}

.mbi-stack-2() {
    .mb-icon-font-definition();
    content: "\10516";
}

.mbi-stairs() {
    .mb-icon-font-definition();
    content: "\10517";
}

.mbi-stairs-down() {
    .mb-icon-font-definition();
    content: "\10518";
}

.mbi-stairs-up() {
    .mb-icon-font-definition();
    content: "\10519";
}

.mbi-star() {
    .mb-icon-font-definition();
    content: "\1051a";
}

.mbi-star-half() {
    .mb-icon-font-definition();
    content: "\1051b";
}

.mbi-star-off() {
    .mb-icon-font-definition();
    content: "\1051c";
}

.mbi-stars() {
    .mb-icon-font-definition();
    content: "\1051d";
}

.mbi-steering-wheel() {
    .mb-icon-font-definition();
    content: "\1051e";
}

.mbi-step-into() {
    .mb-icon-font-definition();
    content: "\1051f";
}

.mbi-step-out() {
    .mb-icon-font-definition();
    content: "\10520";
}

.mbi-stethoscope() {
    .mb-icon-font-definition();
    content: "\10521";
}

.mbi-sticker() {
    .mb-icon-font-definition();
    content: "\10522";
}

.mbi-strikethrough() {
    .mb-icon-font-definition();
    content: "\10523";
}

.mbi-submarine() {
    .mb-icon-font-definition();
    content: "\10524";
}

.mbi-subscript() {
    .mb-icon-font-definition();
    content: "\10525";
}

.mbi-subtask() {
    .mb-icon-font-definition();
    content: "\10526";
}

.mbi-sum() {
    .mb-icon-font-definition();
    content: "\10527";
}

.mbi-sun() {
    .mb-icon-font-definition();
    content: "\10528";
}

.mbi-sun-off() {
    .mb-icon-font-definition();
    content: "\10529";
}

.mbi-sunset() {
    .mb-icon-font-definition();
    content: "\1052a";
}

.mbi-sunshine() {
    .mb-icon-font-definition();
    content: "\1052b";
}

.mbi-superscript() {
    .mb-icon-font-definition();
    content: "\1052c";
}

.mbi-swimming() {
    .mb-icon-font-definition();
    content: "\1052d";
}

.mbi-switch() {
    .mb-icon-font-definition();
    content: "\1052e";
}

.mbi-switch-2() {
    .mb-icon-font-definition();
    content: "\1052f";
}

.mbi-switch-3() {
    .mb-icon-font-definition();
    content: "\10530";
}

.mbi-switch-horizontal() {
    .mb-icon-font-definition();
    content: "\10531";
}

.mbi-switch-vertical() {
    .mb-icon-font-definition();
    content: "\10532";
}

.mbi-table() {
    .mb-icon-font-definition();
    content: "\10533";
}

.mbi-table-export() {
    .mb-icon-font-definition();
    content: "\10534";
}

.mbi-table-import() {
    .mb-icon-font-definition();
    content: "\10535";
}

.mbi-table-off() {
    .mb-icon-font-definition();
    content: "\10536";
}

.mbi-tag() {
    .mb-icon-font-definition();
    content: "\10537";
}

.mbi-tallymark-1() {
    .mb-icon-font-definition();
    content: "\10538";
}

.mbi-tallymark-2() {
    .mb-icon-font-definition();
    content: "\10539";
}

.mbi-tallymark-3() {
    .mb-icon-font-definition();
    content: "\1053a";
}

.mbi-tallymark-4() {
    .mb-icon-font-definition();
    content: "\1053b";
}

.mbi-tallymarks() {
    .mb-icon-font-definition();
    content: "\1053c";
}

.mbi-tank() {
    .mb-icon-font-definition();
    content: "\1053d";
}

.mbi-target() {
    .mb-icon-font-definition();
    content: "\1053e";
}

.mbi-temperature() {
    .mb-icon-font-definition();
    content: "\1053f";
}

.mbi-temperature-celsius() {
    .mb-icon-font-definition();
    content: "\10540";
}

.mbi-temperature-fahrenheit() {
    .mb-icon-font-definition();
    content: "\10541";
}

.mbi-temperature-minus() {
    .mb-icon-font-definition();
    content: "\10542";
}

.mbi-temperature-plus() {
    .mb-icon-font-definition();
    content: "\10543";
}

.mbi-template() {
    .mb-icon-font-definition();
    content: "\10544";
}

.mbi-tent() {
    .mb-icon-font-definition();
    content: "\10545";
}

.mbi-terminal() {
    .mb-icon-font-definition();
    content: "\10546";
}

.mbi-terminal-2() {
    .mb-icon-font-definition();
    content: "\10547";
}

.mbi-test-pipe() {
    .mb-icon-font-definition();
    content: "\10548";
}

.mbi-text-direction-ltr() {
    .mb-icon-font-definition();
    content: "\10549";
}

.mbi-text-direction-rtl() {
    .mb-icon-font-definition();
    content: "\1054a";
}

.mbi-text-wrap() {
    .mb-icon-font-definition();
    content: "\1054b";
}

.mbi-text-wrap-disabled() {
    .mb-icon-font-definition();
    content: "\1054c";
}

.mbi-thumb-down() {
    .mb-icon-font-definition();
    content: "\1054d";
}

.mbi-thumb-up() {
    .mb-icon-font-definition();
    content: "\1054e";
}

.mbi-ticket() {
    .mb-icon-font-definition();
    content: "\1054f";
}

.mbi-tilt-shift() {
    .mb-icon-font-definition();
    content: "\10550";
}

.mbi-tir() {
    .mb-icon-font-definition();
    content: "\10551";
}

.mbi-toggle-left() {
    .mb-icon-font-definition();
    content: "\10552";
}

.mbi-toggle-right() {
    .mb-icon-font-definition();
    content: "\10553";
}

.mbi-tool() {
    .mb-icon-font-definition();
    content: "\10554";
}

.mbi-tools() {
    .mb-icon-font-definition();
    content: "\10555";
}

.mbi-tools-kitchen() {
    .mb-icon-font-definition();
    content: "\10556";
}

.mbi-tools-kitchen-2() {
    .mb-icon-font-definition();
    content: "\10557";
}

.mbi-tornado() {
    .mb-icon-font-definition();
    content: "\10558";
}

.mbi-tournament() {
    .mb-icon-font-definition();
    content: "\10559";
}

.mbi-track() {
    .mb-icon-font-definition();
    content: "\1055a";
}

.mbi-tractor() {
    .mb-icon-font-definition();
    content: "\1055b";
}

.mbi-trademark() {
    .mb-icon-font-definition();
    content: "\1055c";
}

.mbi-traffic-cone() {
    .mb-icon-font-definition();
    content: "\1055d";
}

.mbi-traffic-lights() {
    .mb-icon-font-definition();
    content: "\1055e";
}

.mbi-train() {
    .mb-icon-font-definition();
    content: "\1055f";
}

.mbi-trash() {
    .mb-icon-font-definition();
    content: "\10560";
}

.mbi-trash-off() {
    .mb-icon-font-definition();
    content: "\10561";
}

.mbi-tree() {
    .mb-icon-font-definition();
    content: "\10562";
}

.mbi-trees() {
    .mb-icon-font-definition();
    content: "\10563";
}

.mbi-trending-down() {
    .mb-icon-font-definition();
    content: "\10564";
}

.mbi-trending-down-2() {
    .mb-icon-font-definition();
    content: "\10565";
}

.mbi-trending-down-3() {
    .mb-icon-font-definition();
    content: "\10566";
}

.mbi-trending-up() {
    .mb-icon-font-definition();
    content: "\10567";
}

.mbi-trending-up-2() {
    .mb-icon-font-definition();
    content: "\10568";
}

.mbi-trending-up-3() {
    .mb-icon-font-definition();
    content: "\10569";
}

.mbi-triangle() {
    .mb-icon-font-definition();
    content: "\1056a";
}

.mbi-triangle-off() {
    .mb-icon-font-definition();
    content: "\1056b";
}

.mbi-triangle-square-circle() {
    .mb-icon-font-definition();
    content: "\1056c";
}

.mbi-trident() {
    .mb-icon-font-definition();
    content: "\1056d";
}

.mbi-trophy() {
    .mb-icon-font-definition();
    content: "\1056e";
}

.mbi-truck() {
    .mb-icon-font-definition();
    content: "\1056f";
}

.mbi-truck-delivery() {
    .mb-icon-font-definition();
    content: "\10570";
}

.mbi-truck-off() {
    .mb-icon-font-definition();
    content: "\10571";
}

.mbi-truck-return() {
    .mb-icon-font-definition();
    content: "\10572";
}

.mbi-typography() {
    .mb-icon-font-definition();
    content: "\10573";
}

.mbi-umbrella() {
    .mb-icon-font-definition();
    content: "\10574";
}

.mbi-underline() {
    .mb-icon-font-definition();
    content: "\10575";
}

.mbi-unlink() {
    .mb-icon-font-definition();
    content: "\10576";
}

.mbi-upload() {
    .mb-icon-font-definition();
    content: "\10577";
}

.mbi-urgent() {
    .mb-icon-font-definition();
    content: "\10578";
}

.mbi-user() {
    .mb-icon-font-definition();
    content: "\10579";
}

.mbi-user-check() {
    .mb-icon-font-definition();
    content: "\1057a";
}

.mbi-user-exclamation() {
    .mb-icon-font-definition();
    content: "\1057b";
}

.mbi-user-minus() {
    .mb-icon-font-definition();
    content: "\1057c";
}

.mbi-user-off() {
    .mb-icon-font-definition();
    content: "\1057d";
}

.mbi-user-plus() {
    .mb-icon-font-definition();
    content: "\1057e";
}

.mbi-user-x() {
    .mb-icon-font-definition();
    content: "\1057f";
}

.mbi-users() {
    .mb-icon-font-definition();
    content: "\10580";
}

.mbi-vaccine() {
    .mb-icon-font-definition();
    content: "\10581";
}

.mbi-variable() {
    .mb-icon-font-definition();
    content: "\10582";
}

.mbi-vector() {
    .mb-icon-font-definition();
    content: "\10583";
}

.mbi-vector-beizer() {
    .mb-icon-font-definition();
    content: "\10584";
}

.mbi-vector-beizer-2() {
    .mb-icon-font-definition();
    content: "\10585";
}

.mbi-vector-triangle() {
    .mb-icon-font-definition();
    content: "\10586";
}

.mbi-venus() {
    .mb-icon-font-definition();
    content: "\10587";
}

.mbi-versions() {
    .mb-icon-font-definition();
    content: "\10588";
}

.mbi-video() {
    .mb-icon-font-definition();
    content: "\10589";
}

.mbi-video-minus() {
    .mb-icon-font-definition();
    content: "\1058a";
}

.mbi-video-off() {
    .mb-icon-font-definition();
    content: "\1058b";
}

.mbi-video-plus() {
    .mb-icon-font-definition();
    content: "\1058c";
}

.mbi-view-360() {
    .mb-icon-font-definition();
    content: "\1058d";
}

.mbi-viewfinder() {
    .mb-icon-font-definition();
    content: "\1058e";
}

.mbi-viewport-narrow() {
    .mb-icon-font-definition();
    content: "\1058f";
}

.mbi-viewport-wide() {
    .mb-icon-font-definition();
    content: "\10590";
}

.mbi-virus() {
    .mb-icon-font-definition();
    content: "\10591";
}

.mbi-virus-off() {
    .mb-icon-font-definition();
    content: "\10592";
}

.mbi-virus-search() {
    .mb-icon-font-definition();
    content: "\10593";
}

.mbi-volume() {
    .mb-icon-font-definition();
    content: "\10594";
}

.mbi-volume-2() {
    .mb-icon-font-definition();
    content: "\10595";
}

.mbi-volume-3() {
    .mb-icon-font-definition();
    content: "\10596";
}

.mbi-walk() {
    .mb-icon-font-definition();
    content: "\10597";
}

.mbi-wallet() {
    .mb-icon-font-definition();
    content: "\10598";
}

.mbi-wand() {
    .mb-icon-font-definition();
    content: "\10599";
}

.mbi-wave-saw-tool() {
    .mb-icon-font-definition();
    content: "\1059a";
}

.mbi-wave-sine() {
    .mb-icon-font-definition();
    content: "\1059b";
}

.mbi-wave-square() {
    .mb-icon-font-definition();
    content: "\1059c";
}

.mbi-wifi() {
    .mb-icon-font-definition();
    content: "\1059d";
}

.mbi-wifi-0() {
    .mb-icon-font-definition();
    content: "\1059e";
}

.mbi-wifi-1() {
    .mb-icon-font-definition();
    content: "\1059f";
}

.mbi-wifi-2() {
    .mb-icon-font-definition();
    content: "\105a0";
}

.mbi-wifi-off() {
    .mb-icon-font-definition();
    content: "\105a1";
}

.mbi-wind() {
    .mb-icon-font-definition();
    content: "\105a2";
}

.mbi-windmill() {
    .mb-icon-font-definition();
    content: "\105a3";
}

.mbi-window() {
    .mb-icon-font-definition();
    content: "\105a4";
}

.mbi-wiper() {
    .mb-icon-font-definition();
    content: "\105a5";
}

.mbi-wiper-wash() {
    .mb-icon-font-definition();
    content: "\105a6";
}

.mbi-woman() {
    .mb-icon-font-definition();
    content: "\105a7";
}

.mbi-world() {
    .mb-icon-font-definition();
    content: "\105a8";
}

.mbi-world-latitude() {
    .mb-icon-font-definition();
    content: "\105a9";
}

.mbi-world-longitude() {
    .mb-icon-font-definition();
    content: "\105aa";
}

.mbi-wrecking-ball() {
    .mb-icon-font-definition();
    content: "\105ab";
}

.mbi-writing() {
    .mb-icon-font-definition();
    content: "\105ac";
}

.mbi-writing-sign() {
    .mb-icon-font-definition();
    content: "\105ad";
}

.mbi-x() {
    .mb-icon-font-definition();
    content: "\105ae";
}

.mbi-yin-yang() {
    .mb-icon-font-definition();
    content: "\105af";
}

.mbi-zodiac-aquarius() {
    .mb-icon-font-definition();
    content: "\105b0";
}

.mbi-zodiac-aries() {
    .mb-icon-font-definition();
    content: "\105b1";
}

.mbi-zodiac-cancer() {
    .mb-icon-font-definition();
    content: "\105b2";
}

.mbi-zodiac-capricorn() {
    .mb-icon-font-definition();
    content: "\105b3";
}

.mbi-zodiac-gemini() {
    .mb-icon-font-definition();
    content: "\105b4";
}

.mbi-zodiac-leo() {
    .mb-icon-font-definition();
    content: "\105b5";
}

.mbi-zodiac-libra() {
    .mb-icon-font-definition();
    content: "\105b6";
}

.mbi-zodiac-pisces() {
    .mb-icon-font-definition();
    content: "\105b7";
}

.mbi-zodiac-sagittarius() {
    .mb-icon-font-definition();
    content: "\105b8";
}

.mbi-zodiac-scorpio() {
    .mb-icon-font-definition();
    content: "\105b9";
}

.mbi-zodiac-taurus() {
    .mb-icon-font-definition();
    content: "\105ba";
}

.mbi-zodiac-virgo() {
    .mb-icon-font-definition();
    content: "\105bb";
}

.mbi-zoom-cancel() {
    .mb-icon-font-definition();
    content: "\105bc";
}

.mbi-zoom-check() {
    .mb-icon-font-definition();
    content: "\105bd";
}

.mbi-zoom-in() {
    .mb-icon-font-definition();
    content: "\105be";
}

.mbi-zoom-money() {
    .mb-icon-font-definition();
    content: "\105bf";
}

.mbi-zoom-out() {
    .mb-icon-font-definition();
    content: "\105c0";
}