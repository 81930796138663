@mb-badge-status-background--empty: @mb-primary-white-color;

// Custom class for empty variants of status dot
.@{badge-prefix-cls} {
    &.mb-badge-status-dot--empty {
        .@{badge-prefix-cls}-status-dot {
            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 25%;
                left: 25%;
                height: 50%;
                width: 50%;
                background-color: @mb-badge-status-background--empty;
                border-radius: 50%;
            }
        }
    }
}