@mb-steps__item-title-spacing-x: @mb-spacing[xs];

@mb-steps__item-icon-font-size: @mb-text-h5-size;
@mb-steps__item-icon-line-height: @mb-text-h5-line-height - 2 !important;

@mb-steps-horizontal__item-spacing-x: @mb-spacing[xs];

// Background color for indicators/icons changed to transparent through variables

// Adjusting icon position for completed steps
.@{steps-prefix-cls} {
    &-item {
        &-title {
            padding-right: @mb-steps__item-title-spacing-x;
        }
    }
}

.@{steps-prefix-cls}-horizontal {
    &:not(.@{steps-prefix-cls}-label-vertical) .@{steps-prefix-cls}-item {
        padding-left: @mb-steps-horizontal__item-spacing-x;
    }
}