// Custom error status for upload control
.mb-upload--error {

    // Set background color for drag and drop area
    &.@{upload-prefix-cls}-drag {
        background-color: @mb-upload-dropzone-background--error;

        // Set icon color
        p.@{upload-prefix-cls}-drag-icon {
            color: @mb-upload-dropzone__icon-color--error;
        }
    }

    // Remove border for this status
    &.@{upload-prefix-cls}-drag,
    &.@{upload-prefix-cls}-drag:not(.@{upload-prefix-cls}-disabled):hover,
    &.@{upload-prefix-cls}-drag:hover {
        border-color: transparent;
    }
}