// Container objects
///////////////////////////////////////////////////////////
.mb-container {
    // bug CS-2652 --> deleted display: block;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    padding-left: @mb-container-spacing-x--mobile;
    padding-right: @mb-container-spacing-x--mobile;
    max-width: @mb-container-max-width + @mb-container-spacing-x--mobile + 8;

    @media screen and (min-width: @screen-sm) {
        // 576px
        padding-left: @mb-container-spacing-x--tablet;
        padding-right: @mb-container-spacing-x--tablet;
        max-width: @mb-container-max-width + @mb-container-spacing-x--tablet * 2;
    }

    // Overrides for max-width property
    &--full-width {
        max-width: none;
    }

    &--sm {
        max-width: @mb-container-max-width--sm;
    }

    &--md {
        max-width: @mb-container-max-width--md;
    }

    // Apply spacing to top and bottom of container
    &--spacing {
        padding-top: @mb-container-spacing--xxl;
        padding-bottom: @mb-container-spacing--xxl;
    }

    // Apply spacing to top and bottom of container
    &--spacing {

        // Width fix for horizontal spacing classes
        &-both,
        &-horizontal {
            max-width: @mb-container-max-width + @mb-spacing[md] * 2;
        }

        // Apply spacing to all sides
        &-both {
            padding: @mb-container-spacing--xxl;
        }

        // Apply spacing to container left and right
        &--horizontal {
            padding-left: @mb-container-spacing--xxl;
            padding-right: @mb-container-spacing--xxl;
        }

        // Apply spacing to container top and bottom
        &--vertical {
            padding-top: @mb-container-spacing--xxl;
            padding-bottom: @mb-container-spacing--xxl;
        }
    }
}