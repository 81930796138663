// ///////////////////////////////////
.@{btn-prefix-cls} {

    // Fix for buttons that contain links for some reason
    a,
    a:hover,
    a:focus {
        text-decoration: none;
    }

    // Default button adjustments
    &:not(.@{btn-prefix-cls}-primary):not(.@{btn-prefix-cls}-link):not(.@{btn-prefix-cls}-text):not(.mb-btn--warning):not(.mb-btn--success) {
        &:hover {
            color: @btn-default-color;
            border-color: @btn-default-border;
            background: @mb-btn-background--hover;
        }

        &:focus {
            color: @btn-default-color;
            border-color: @mb-primary-deepblue-color;
            background: @mb-btn-background--focus;
        }

        // Disabled state for default buttons
        &[disabled],
        &[disabled]:hover,
        &[disabled]:focus,
        &.@{btn-prefix-cls}-loading {
            color: @mb-btn-color--disabled;
            background-color: @mb-btn-background--disabled;
            border-color: transparent;
        }
    }

    // Primary buttons
    &-primary {
        &:hover {
            background-color: @mb-btn-primary-background--hover;
            border-color: @mb-btn-primary-border-color--hover;
        }

        &:focus {
            background-color: @mb-btn-primary-background--focus;
            border-color: @mb-btn-primary-border-color--focus;
        }

        &[disabled]:not(.@{btn-prefix-cls}-dangerous),
        &[disabled]:not(.@{btn-prefix-cls}-dangerous):hover,
        &[disabled]:not(.@{btn-prefix-cls}-dangerous):focus,
        &.@{btn-prefix-cls}-loading:not(.@{btn-prefix-cls}-dangerous) {
            background-color: @mb-btn-primary-background--disabled;
            color: @btn-primary-color;
            border-color: transparent;
        }

        // Dangerous buttons
        &.@{btn-prefix-cls}-dangerous {
            &:hover {
                background-color: @mb-btn-danger-background--hover;
                border-color: @mb-btn-danger-border-color--hover;
            }

            &:focus {
                background-color: @mb-btn-danger-background--focus;
                border-color: @mb-btn-danger-border-color--focus;
            }

            // Disabled state for dangerous buttons
            &[disabled],
            &[disabled]:hover,
            &[disabled]:focus,
            &.@{btn-prefix-cls}-loading {
                background-color: @mb-btn-danger-background--disabled;
                color: @mb-btn-danger-color--disabled;
                border-color: transparent;
            }
        }
    }

    // Disabled state for link buttons
    &-link {

        &[disabled],
        &[disabled]:hover,
        &[disabled]:focus,
        &.@{btn-prefix-cls}-loading {
            color: @mb-btn-link-color--disabled;
        }
    }

    // Hover/focus fix for dangerous ghost buttons
    &-dangerous.@{btn-prefix-cls}-background-ghost {

        &:hover,
        &:focus {
            background: transparent;
        }
    }


    //Spinner for buttons in loading state
    &-loading {

        &,
        &:not(:disabled) {
            cursor: auto;
        }

        // Change color for default buttons
        &:not(.@{btn-prefix-cls}-primary):not(.@{btn-prefix-cls}-dangerous):not(.@{btn-prefix-cls}-link):not(.mb-btn--warning):not(.mb-btn--success) .@{btn-prefix-cls}-loading-icon .anticon:before {
            border-color: @btn-disable-color;
        }

        // Change color for link buttons
        &.@{btn-prefix-cls}-link .@{btn-prefix-cls}-loading-icon .anticon:before {
            border-color: @mb-btn-link-color--disabled;
        }
    }
}

.ant-btn {
    &.ant-btn-link {
        [class^="mbi-"],
        [class*=" mbi-"] {
            border: 1px solid transparent;
            border-radius: 4px;
        }
        &:hover {
            color: @mb-cobalt[700];
        }
        &:focus {
            color: @mb-cobalt[700];
            [class^="mbi-"],
        [class*=" mbi-"] {
                border-color: @mb-cobalt[600];
            }
        }
    }
}