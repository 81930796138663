// Adjustments for switches - match with design in Figma
//-----------------------------------------------------------------
// Reset default styles for switches
.@{switch-prefix-cls} {
    height: @switch-min-width;
    border-radius: 0;
    background-color: transparent;

    .ant-click-animating-node {
        display: none;
    }

    &:focus {
        outline: 0;
        box-shadow: none;

        &:hover {
            box-shadow: none;
        }
    }

    // Use the existing handle to create custom shape
    &-handle {
        width: 100%;
        height: @mb-switch__track-size;
        left: 0;
        top: 50%;
        transform: translateY(-50%);

        // Custom track (background part)
        &:before {
            width: 100%;
            height: @mb-switch__track-size;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            border-radius: @mb-switch__track-border-radius;
            background-color: @mb-switch__track-color;
        }

        // Custom handle
        &:after {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: @mb-switch__handle-size;
            height: @mb-switch__handle-size;
            border-radius: 50%;
            background-color: @mb-switch-color;
            transition: all @switch-duration;
            border: @mb-switch__handle-border-width solid @mb-switch__handle-border-color;
        }
    }

    // Set styles for checked state
    &-checked {
        .@{switch-prefix-cls}-handle {
            left: 0;

            &:before {
                background-color: @mb-switch--checked__track-color;
            }

            &:after {
                left: calc(100% - 20px);
                background-color: @mb-switch-color--checked;
                border-color: @mb-switch-color--checked;
                transition: all @switch-duration;
            }
        }

        &:focus {
            box-shadow: none;
        }

        &.@{switch-prefix-cls}-disabled, &[disabled] {
            .@{switch-prefix-cls}-handle {
    
                &:before {
                    background-color: @mb-switch--checked-disabled__track-color;
                }
    
                &:after {
                    left: calc(100% - 20px);
                    background-color: @mb-switch-color--checked-disabled;
                    border-color:@mb-switch-color--checked-disabled;
                    transition: all @switch-duration;
                }
            }
        }
    }
    &.@{switch-prefix-cls}-disabled:not(&.@{switch-prefix-cls}-checked), &[disabled]:not(&.@{switch-prefix-cls}-checked) {
        .@{switch-prefix-cls}-handle {

            &:before {
                background-color:@mb-switch__track-color;
            }

            &:after {
                left: calc(100% - 20px);
                background-color: @mb-switch-color--disabled;
                border-color: @mb-switch-color--disabled;
                transition: all @switch-duration;
            }
        }
    }

    // Hover state for switch
    &:not[disabled]:hover,
    &:not(.@{switch-prefix-cls}-disabled):hover {
        .@{switch-prefix-cls}-handle {
            &:before {
                opacity: 0.5;
                border-radius: @mb-switch__track-border-radius;
            }

            &:after {
                box-shadow: @mb-switch__handle-box-shadow--hover;
            }
        }
    }

    // Focus state for switch
    &:not[disabled]:focus,
    &:not(.@{switch-prefix-cls}-disabled):focus {
        outline: none;
        border: none;
        box-shadow: none;

        .@{switch-prefix-cls}-handle {
            &:before {
                opacity: 0.25;
            }

            &:after {
                border-color: @mb-switch__handle-border-color--focus;
            }
        }
    }

    &:not(.@{switch-prefix-cls}-disabled):active.@{switch-prefix-cls}-checked .@{switch-prefix-cls}-handle::before {
        left: 0;
    }


    // Customized loading icon - the existing loading icon was too thin and barely visible on our design
    &-loading-icon {
        position: absolute;
        z-index: 3; // Move the loader in front of the handle mocked with ::after
        color: @mb-switch__loader-color;
        top: -(@mb-switch__loader-position);
        left: 0;
        width: @mb-switch__handle-size;
        height: @mb-switch__handle-size;
        padding: @mb-switch__loader-spacing;


        // Custom spinner for loading state
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: @mb-switch__loader-position;
            left: @mb-switch__loader-position;
            width: @mb-switch__loader-size;
            height: @mb-switch__loader-size;
            border: @mb-switch__loader-stroke solid transparent;
            border-left-color: @mb-switch__loader-color;
            border-top-color: @mb-switch__loader-color;
            border-radius: 50%;
        }

        // Hide existing spinner
        svg {
            display: none;
        }
    }

    // Adjust spinner position for checked switches
    &-checked {
        .@{switch-prefix-cls}-loading-icon {
            position: relative;
            left: @mb-switch__loader-position--checked;
        }
    }
}