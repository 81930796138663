// Custom page header
.mb-page-header {
    padding-top: @mb-page-header-spacing-top;
    padding-left: @mb-page-header-spacing-bottom;

    // Fix that ensures status badges don't break layout
    .ant-badge-status-text {
        display: none;
    }

    .@{pageheader-prefix-cls} {
        position: static;
        padding-left: 0;
        padding-right: 0;

        &-heading-extra {
            .@{tag-prefix-cls} {
                float: right;
                margin-right: 0;
            }
        }
    }

    .@{pageheader-prefix-cls}-heading {
        &-title {
            font-weight: @mb-page-header__title-font-weight;
            font-size: @mb-page-header__title-font-size;
            line-height: @mb-page-header__title-line-height;
            margin-right: @mb-page-header__spacing-right;
        }

        &-left {
            margin-top: 0;
            margin-bottom: 0;
        }

        &-extra {
            margin: 0;
        }

        .@{pageheader-prefix-cls}-back {
            position: absolute;
            top: 50%;
            right: calc(100% - @mb-default-icon-size);
            transform: translateY(-50%);
            margin: 0;

            &-button {
                width: @mb-default-icon-size;
                height: @mb-default-icon-size;
                position: relative;
            }

            // 576px
            @media screen and (min-width: @screen-sm) {
                right: calc(100% - (@mb-spacing[xxs] * 10.5));
            }
        }
    }

    .ant-tabs,
    .ant-menu {
        margin-top: @mb-spacing[xs];
    }

    .mb-page-header {
        &--subtitle {
            .@{pageheader-prefix-cls} {
                &-heading {
                    &-left {
                        margin-bottom: 0;
                    }
                }

                &-content {
                    padding-top: 0;
                }
            }

            .ant-page-header-content {
                padding-top: 0;
            }
        }

        // Since breadcrumbs aren't rendered on mobile screen, add padding
        // that takes up as much space as they usually do to keep everything
        // 7in place
        &--breadcrumbs {
            padding-top: @mb-page-header__subtitle-line-height;

            @media screen and (min-width: @screen-sm-max) {
                padding-top: 0;
            }
        }
    }

    .mb-breadcrumbs,
    &-subtitle {
        font-size: @mb-page-header__subtitle-font-size;
        line-height: @mb-page-header__subtitle-line-height;
        color: @mb-page-header__subtitle-color;
    }

    .@{pageheader-prefix-cls}-footer {
        margin: -(@mb-page-footer__spacing) 0 0 0;

        .ant-tabs-tab {
            padding: @mb-page-footer__tab-spacing;
            margin: @mb-page-footer__tab-spacing-around;

            .ant-tabs-tab-btn {
                font-weight: @mb-page-footer__tab-font-weight;
                font-size: @mb-page-footer__tab-font-size;
                line-height: @mb-page-footer__tab-line-height;
            }
        }
    }

    .ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar {
        height: @mb-page-footer__tab-ink-bar-height;
    }
}

//@Viki -> razlika između primary i secondary text ne postoji, provjeriti hovere 
.mb-breadcrumbs {

    // Each item in breadcrumbs - consists of a link and separator that
    // is toggled through code
    &-link {
        padding-right: @mb-breadcrumbs__link-spacing;

        .ant-typography {
            color: @mb-breadcrumbs__link-color;

            &:hover {
                cursor: default;
            }
        }

        .ant-typography+span {
            color: @mb-breadcrumbs__secondary-color;
        }

        .ant-typography-secondary .ant-typography {
            color: @mb-breadcrumbs__secondary-color;
            cursor: pointer;
        }

        &:hover {
            .ant-typography .ant-typography {
                color: @mb-breadcrumbs__primary-color;
            }
        }

        .ant-typography,
        .ant-typography-secondary {
            .ant-typography:focus {
                color: @mb-breadcrumbs__primary-color;
            }
        }

        .ant-typography.ant-typography-disabled,
        .ant-typography.ant-typography-disabled {
            opacity: 0.5;
        }

        &[disabled],
        &:disabled,
        &.disabled {


            .ant-typography,
            .ant-typography+span {
                color: @mb-breadcrumbs__secondary-color;
            }


            &:hover {
                cursor: default;
            }
        }
    }
}