// Icon font definition - used for defining icon font properties
// Created for easier replacement of Ant icons in components
.mb-icon-font-definition() {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'mb-icons' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    // Default font size and line height
    font-size: @mb-default-icon-size;
    line-height: @mb-default-icon-line-height;

    // Make sure before inherits the values
    &:before {
        font-size: inherit;    
        line-height: inherit;
    }
}

[class^="mbi-"],
[class*=" mbi-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'mb-icons' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    // Default font size and line height
    font-size: @mb-default-icon-size;
    line-height: @mb-default-icon-line-height;

    // Make sure before inherits the values
    &:before {
        font-size: inherit;    
        line-height: inherit;
    }
}