// Source: https://gist.github.com/IceCreamYou/cd517596e5847a88e2bb0a091da43fb4
.force-show-scrollbars ::-webkit-scrollbar-track:vertical {
    border-left: none;
    box-shadow: @mb-scrollbar-v-shadow;
}

.force-show-scrollbars ::-webkit-scrollbar-track:horizontal {
    border-top: none;
    box-shadow: @mb-scrollbar-h-shadow;
}

.force-show-scrollbars ::-webkit-scrollbar {
    -webkit-appearance: none;
    background-color: @mb-scrollbar-background;
    width: @mb-scrollbar-width;
}

.force-show-scrollbars ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: @mb-scrollbar__thumb-background;
    border-color: transparent;
    border-radius: @mb-scrollbar-border-radius;
    border-style: solid;
    border-width: @mb-scrollbar-margin;
    /* Workaround because margins aren't supported */
}

/* Unfortunately scrollbars can't use CSS transitions. Also, it's not possible
   to highlight the thumb when the scrollbar track is hovered without some
   JavaScript acrobatics; https://jsfiddle.net/QcqBM/6/ is a start, but you
   also have to check whether the element has a scrollbar and if so how wide
   it is. */
.force-show-scrollbars ::-webkit-scrollbar-thumb:hover {
    background-color: @mb-scrollbar__thumb-background--hover;
}