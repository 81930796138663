.@{collapse-prefix-cls} {
    background-color: transparent;
    border: none;

    .@{collapse-prefix-cls}-header {
        background-color: @mb-collapse__header-background;
    }

    &-content {
        border-top-color: @mb-collapse__item-border-color;
    }

    .@{collapse-prefix-cls}-header {
        &:hover {
            background-color: @mb-collapse__header-background--hover;
        }
    }

    &:focus {
        >.@{collapse-prefix-cls}-item {
            border-color: @mb-collapse__header-border-color--focus;
        }

        .@{collapse-prefix-cls}-header {
            background-color: @mb-collapse__header-background--focus;
        }
    }

    &-disabled {
        opacity: 0.5;

        >.@{collapse-prefix-cls}-item {
            background-color: @mb-collapse__item-background--disabled;
        }

        >.ant-collapse-header {
            color: @mb-primary-black-color;

            >.arrow {
                color: @mb-collapse__item-icon-color;
            }
        }
    }

    >.@{collapse-prefix-cls}-item {
        background-color: @mb-primary-white-color;
        border: @mb-collapse-border-size solid @mb-collapse__item-border-color;

        >.@{collapse-prefix-cls}-header {
            padding: @mb-collapse__header-padding;

            .anticon {
                left: @mb-collapse__item-icon-left;
                color: @mb-collapse__item-icon-color;
            }
        }

        +.@{collapse-prefix-cls}-item {
            margin-top: @mb-collapse__item-margin-between;
        }
    }
}
.ant-drawer-content {
    .ant-drawer-wrapper-body {
        .ant-drawer-body {
            .ant-collapse {
                .ant-collapse-item {
                    border-radius: 4px;
                    .ant-collapse-header {
                        border-radius: 4px;
                    }
                    .ant-collapse-content {
                        border-bottom-left-radius: 4px;
                        border-bottom-right-radius: 4px;
                    }
                }
            }
        }
    }
}