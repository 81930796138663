/**
 * @license
 *
 * Font Family: Satoshi
 * Designed by: Deni Anggara
 * URL: https://www.fontshare.com/fonts/satoshi
 * © 2022 Indian Type Foundry
 *
 * Font Styles:
 * Satoshi Regular
 * Satoshi Italic
 * Satoshi Medium
 * Satoshi Medium Italic
 * Satoshi Bold
 * Satoshi Bold Italic
 * Satoshi Black
 * Satoshi Black Italic
 *
*/

@font-face {
  font-family: 'Satoshi-Regular';
  src: url('../../../font-files/Satoshi/Satoshi-Regular.woff2') format('woff2'), url('../../../font-files/Satoshi/Satoshi-Regular.woff') format('woff'), url('../../../font-files/Satoshi/Satoshi-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'Satoshi';
  src: url('../../../font-files/Satoshi/Satoshi-Italic.woff2') format('woff2'), url('../../../font-files/Satoshi/Satoshi-Italic.woff') format('woff'), url('../../../font-files/Satoshi/Satoshi-Italic.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'Satoshi';
  src: url('../../../font-files/Satoshi/Satoshi-Medium.woff2') format('woff2'), url('../../../font-files/Satoshi/Satoshi-Medium.woff') format('woff'), url('../../../font-files/Satoshi/Satoshi-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'Satoshi';
  src: url('../../../font-files/Satoshi/Satoshi-MediumItalic.woff2') format('woff2'), url('../../../font-files/Satoshi/Satoshi-MediumItalic.woff') format('woff'), url('../../../font-files/Satoshi/Satoshi-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'Satoshi';
  src: url('../../../font-files/Satoshi/Satoshi-Bold.woff2') format('woff2'), url('../../../font-files/Satoshi/Satoshi-Bold.woff') format('woff'), url('../../../font-files/Satoshi/Satoshi-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'Satoshi';
  src: url('../../../font-files/Satoshi/Satoshi-BoldItalic.woff2') format('woff2'), url('../../../font-files/Satoshi/Satoshi-BoldItalic.woff') format('woff'), url('../../../font-files/Satoshi/Satoshi-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: italic;
}


@font-face {
  font-family: 'Satoshi';
  src: url('../../../font-files/Satoshi/Satoshi-Black.woff2') format('woff2'), url('../../../font-files/Satoshi/Satoshi-Black.woff') format('woff'), url('../../../font-files/Satoshi/Satoshi-Black.ttf') format('truetype');
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'Satoshi';
  src: url('../../../font-files/Satoshi/Satoshi-BlackItalic.woff2') format('woff2'), url('../../../font-files/Satoshi/Satoshi-BlackItalic.woff') format('woff'), url('../../../font-files/Satoshi/Satoshi-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-display: swap;
  font-style: italic;
}