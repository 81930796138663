// Upload component adjustments
.@{upload-prefix-cls} {

    // ----- Drag and drop component -----
    // Set background and border and remove border radius
    &.@{upload-prefix-cls}-drag {
        background-color: @mb-upload-dropzone-background;
        border: @mb-upload-dropzone-border-width dashed @mb-upload-dropzone-border-color;
        border-radius: @mb-upload-dropzone-border-radius;

        .@{upload-prefix-cls} {

            // This element is the actual drop area. Added padding, removed borders, margins and backgrounds
            &.@{upload-prefix-cls}-btn {
                padding: @mb-upload-dropzone-spacing;
                background-color: transparent;
                margin: 0;
                border: 0;
            }

            // Center all text within the component
            &-drag-icon,
            &-text,
            &-hint {
                text-align: center;
            }

            // Adjustments for main (heading) text
            &-text {
                margin-bottom: @mb-upload-dropzone__text-spacing-y;
                font-weight: @mb-upload-dropzone__text-font-weight;
                letter-spacing: @mb-upload-dropzone__text-letter-spacing;

                // Blue part of text mimicking a link (whole area is clickable)
                span {
                    font-weight: @mb-upload-dropzone__link-font-weight;
                    letter-spacing: @mb-upload-dropzone__link-letter-spacing;
                }
            }
        }

        // Size and color adjustments for upload icon
        p.@{upload-prefix-cls}-drag-icon {
            font-size: @mb-upload-dropzone__icon-size;
            line-height: @mb-upload-dropzone__icon-size;
            margin-bottom: @mb-upload-dropzone__icon-spacing-y;

            .mbi-upload {
                color: @mb-upload-dropzone__icon-color;
            }
        }

        // "Focus" variant - applied when user drags a file over the control, since it has no actual focus state
        &.@{upload-prefix-cls}-drag-hover:not(.ant-upload-disabled) {
            border: @mb-upload-dropzone-border-width--focus solid @mb-upload-dropzone-border-color--focus;
        }

        // Hover variant
        &:not(.@{upload-prefix-cls}-disabled):hover {
            border: @mb-upload-dropzone-border-width--hover dashed @mb-upload-dropzone-border-color--hover;
        }

        // Disabled state reduces opacity
        &.@{upload-prefix-cls}-disabled {
            opacity: 0.5;
        }
    }
}