.mb-table--scrollable {
    height: 100%;

    .ant-spin-nested-loading,
    .ant-spin-container {
        height: inherit;
    }

    // This is where parts of table are actually nested
    .ant-spin-container {
        display: flex;
        flex-direction: column;
    }

    .ant-table {
        .mb-flex__child-lg();
        .mb-flex__child--nested();

        &-container {
            height: 100%;
            display: flex;
            flex-direction: column;
        }

        &-header {
            .mb-flex__child-sm();
        }

        &-body {
            .mb-flex__child-lg();
            overflow-y: auto !important;
        }
    }
}