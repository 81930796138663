// ================================================================
// =                      Children Component                      =
// ================================================================
.@{ant-prefix} {

    // Ant radio
    &-radio {
        .@{ant-prefix}-radio-inner {
            &:after {
                top: @mb-radio__knob-position;
                left: @mb-radio__knob-position;
                width: @mb-radio__knob-size;
                height: @mb-radio__knob-size;
                transform: none;
                margin: 0;
            }
        }

        &-group {
            float: left;

            .ant-radio-wrapper {
                margin-right: @mb-radio-group-spacing-between;

                >span {
                    float: left;
                    line-height: @mb-radio-group-line-height;
                }
            }
        }
    }



    // Checkbox
    &-checkbox {
        &-inner {
            box-shadow: @mb-checkbox-shadow;
        }

        &-input {
            &:focus+.@{ant-prefix}-checkbox-inner {
                outline: @mb-checkbox-outline--focus;
            }
        }

        &-wrapper:not(.@{ant-prefix}-checkbox-wrapper-disabled):hover {
            .@{ant-prefix}-checkbox:not(.@{ant-prefix}-checkbox-checked) {
                .@{ant-prefix}-checkbox-inner {
                    border-color: @mb-checkbox-border-color--hover  !important;
                }
            }
        }

        &-checked {
            .@{ant-prefix}-checkbox-inner {
                border-color: @mb-checkbox-border-color--checked  !important;
            }
        }

        &-disabled {
            .@{ant-prefix}-checkbox-inner {
                background-color: @mb-checkbox-background--disabled;
                border-color: @mb-checkbox-border-color--disabled  !important;
            }

            &.@{ant-prefix}-checkbox-checked {
                .@{ant-prefix}-checkbox-inner {
                    background-color: @mb-checkbox-background--checked-disabled;
                    border-color: @mb-checkbox-border-color--checked-disabled  !important;
                }
            }
        }

        //     +span {
        //         font-size: @mb-checkbox__label-font-size;
        //         line-height: @mb-checkbox__label-line-height;
        //     }
    }
}

// Fix for inputs with suffix icons
.@{form-prefix-cls}-item-control {
    .@{input-prefix-cls}-suffix {
        color: @mb-input__suffix-color;
    }
}

// Vertical form
.@{form-prefix-cls}-vertical {
    .@{form-prefix-cls} {
        &-item {
            &-control-input {
                min-height: 0;
            }

            &-label {
                line-height: @mb-form-vertical__label-line-height;
                padding-bottom: @mb-form-vertical__label-spacing-y;
            }

            &-extra {
                margin-top: @mb-form-vertical__extra-spacing-y;
            }
        }
    }
}

// Form items
.@{form-prefix-cls} {

    // Validation messages
    &-item-explain {
        margin-top: @mb-form-vertical__extra-spacing-y;
    }
}

// Ant select component
.@{ant-prefix}-select {
    &-dropdown {
        box-shadow: @mb-input-select-shadow;
        padding: 0;
        border-radius: @mb-input-select-border-radius;
        border: @mb-input-select-border-width solid @mb-input-select-border-color;

        .@{ant-prefix}-select-item {
            padding: @mb-input-select-spacing-y @mb-input-select-spacing-x;

            &-option {
                &-selected {
                    background-color: @mb-input-select__option-background--selected;
                    color: @mb-input-select__option-color--selected;
                    font-weight: normal;
                }

                &-active {
                    background-color: @mb-input-select__option-background--active;
                }
            }
        }
    }

    &-selection-placeholder {
        opacity: 1;
        color: @input-placeholder-color;
    }
}

// Input
.ant-input {
    &-prefix {
        color: @mb-input__prefix-color;
    }
}

// Checkboxes - basic and tree
.@{ant-prefix}-checkbox .@{ant-prefix},
.@{tree-prefix-cls}-checked .@{tree-prefix-cls} {

    // Defining icons and modifying settings
    &-checkbox-inner:after {
        .mbi-check();
        border: none;
        transform: translate(-50%, -50%);
        left: 50%;
        color: @mb-checkbox__check-color;
        font-size: @mb-checkbox__check-font-size;
    }
}

.@{ant-prefix}-dropdown-trigger {
    a& {
        text-decoration: none;
    }
}

.ant-select,
.ant-select-multiple {
    &:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) {
        .ant-select-selector {
            outline: 3px solid transparent;
            border: 1px solid;
            border-color: @mb-gray[200];
        }
    }
    &:hover {
        &:not(.ant-select-disabled, .ant-select-focused).ant-select:not(.ant-select-customize-input) {
            .ant-select-selector {
                outline-color: @mb-gray[200];
                border-color: @mb-gray[400];
            }
        }
    }
    &-focused, &:focus {
        &:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) {
            .ant-select-selector {
                outline-color: @mb-cobalt[100];
                border-color: @mb-primary-blue-color;
            }
        }  
    }
    &.ant-select-disabled.ant-select:not(.ant-select-customize-input) {
        .ant-select-selector {
            background-color: @mb-gray[50];
            border: 1px solid  @mb-gray[200];
            color: @mb-gray[400];
            outline: 3px solid transparent;
        }
    }
}

.ant-form-item-has-error {
    .ant-select,
    .ant-select-multiple {
        &:hover, &-focused, &:focus {
            &:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) {
                    .ant-select-selector {
                        outline: 3px solid @mb-rose[200];
                    }
            }
        }
    }
}

.ant-form {
    .ant-form-item {
        .ant-form-item-control-input {
            .ant-input:not(.ant-input-disabled), .ant-input-number:not(.ant-input-number-disabled), .ant-picker:not(.ant-picker-disabled) {
                outline: 3px solid transparent;
                border: 1px solid;
                border-color: @mb-gray[200];
                &:hover {
                    outline-color: @mb-gray[200];
                    border-color: @mb-gray[400];  
                }
                &:focus {
                    outline-color: @mb-cobalt[100];
                    border-color: @mb-primary-blue-color;
                }                
            }
            .ant-picker:not(.ant-picker-disabled) {
                &.ant-picker-focused {
                    outline-color: @mb-cobalt[100];
                    border-color: @mb-primary-blue-color;
                }                
            }
            .ant-input-number:not(.ant-input-number-disabled) {
                &.ant-input-number-focused {
                    outline-color: @mb-cobalt[100];
                    border-color: @mb-primary-blue-color;
                }                
            }
            .ant-input-disabled, .ant-input-number-disabled, .ant-picker-disabled {
                background-color: @mb-gray[50];
                border: 1px solid  @mb-gray[200];
                color: @mb-gray[400];
                box-shadow: @mb-shadow-base;
                outline: 3px solid transparent;
            }

            .ant-input-affix-wrapper {
               
                .ant-input, .ant-input-number, .ant-picker {
                    border: none;
                    outline: none;
                    box-shadow: none;
                }
            }
        }
        &.ant-form-item-has-error {
            .ant-form-item-control-input {
                .ant-input:not(.ant-input-disabled), .ant-input-number:not(.ant-input-number-disabled), .ant-picker:not(.ant-picker-disabled) {
                    outline: 3px solid transparent;
                    border-color: @mb-rose[600];
                    &-focused {
                        outline-color: @mb-rose[200];
                        border-color: @mb-rose[600];
                    }
                }
                .ant-input:not(.ant-input-disabled), .ant-input-number:not(.ant-input-number-disabled), .ant-picker:not(.ant-picker-disabled) {
                    &:hover, &:focus {
                        outline-color: @mb-rose[200];
                        border-color: @mb-rose[600];
                    }
                }
                .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled) {
                    outline: 3px solid transparent;
                    border-color: @mb-rose[600];
                    &:hover, &.ant-input-affix-wrapper-focused, &.ant-input-affix-wrapper-focused:hover {
                        outline: 3px solid @mb-rose[200];
                        border-color: @mb-rose[600];
                    }
                    .ant-input, .ant-input-number, .ant-picker {
                        outline: none;
                        border-color: none;
                        box-shadow: none;
                        &:focus, &:hover, &-focused {
                            outline: none;
                            border-color: none;
                            box-shadow: none;
                        }
                    }
                }
            }
        }
    }    
}

.ant-input-affix-wrapper {
    &:not(.ant-input-affix-wrapper-disabled) {
        outline: 3px solid transparent;
        box-shadow: @mb-shadow-base;
        border: 1px solid;
        border-color: @mb-gray[200];
        .ant-input, .ant-input-number, .ant-picker {
            border: none;
            outline: none;
            box-shadow: none;
        }
        
        &:hover {
            outline-color: @mb-gray[200];
            border-color: @mb-gray[400];  
        }

        &.ant-input-affix-wrapper-focused {
            outline-color: @mb-cobalt[100];
            border-color: @mb-primary-blue-color;
            &:hover {
                outline-color: @mb-cobalt[100];
                border-color: @mb-primary-blue-color;
            }
        }
    }
    &.ant-input-affix-wrapper-disabled {
        background-color: @mb-gray[50];
        border: 1px solid @mb-gray[200];
        box-shadow: @mb-shadow-base;
    }
}