@mb-pagination__btn-size: 32px;
@mb-pagination__btn-margin: @mb-spacing[xs];

@mb-pagination__btn-link-padding: 0 @mb-spacing[xs];
@mb-pagination__btn-link-color: @mb-primary-blue-color;

@mb-pagination__btn-icon-size: @mb-text-h4-size;

@mb-pagination__item-border-color--active: @mb-primary-black-color;

.@{pagination-prefix-cls} {

    &-item,
    &-prev,
    &-next {
        padding: 0;
        line-height: @mb-pagination__btn-size;
        margin-right: @mb-pagination__btn-margin;

        a {
            padding: @mb-pagination__btn-link-padding;
            text-align: center;
        }
    }

    &-prev,
    &-next {
        min-width: @mb-pagination__btn-size;

        .@{pagination-prefix-cls}-item-link {
            color: @mb-pagination__btn-link-color;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &-prev .@{pagination-prefix-cls}-item-link,
    &-next .@{pagination-prefix-cls}-item-link,
    &-item {
        border-color: transparent;
        border-radius: 0;
    }

    &-item-active {
        border-color: @mb-pagination__item-border-color--active;
    }

    &-options {
        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
            border-radius: 0;
        }
    }

    &-disabled {

        &,
        &:hover,
        &:focus {

            .@{pagination-prefix-cls}-item-link,
            .@{pagination-prefix-cls}-item {
                border: none;
            }
        }
    }
}