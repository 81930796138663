// Using mixins to mimic regular checkboxes used in forms because tree
// checkboxes don't have a hidden input field.
// Selector .ant-tree has been added as an additional level of selectors
// to ensure the extended properties don't get overridden.
// Ant tree
.@{tree-prefix-cls} {

    // Treenode - checkbox wrapper
    .@{tree-prefix-cls}-treenode {
        padding: @mb-tree-node-spacing;
        width: 100%;

        >span:not(.ant-tree-node-content-wrapper) {
            .mb-flex__child-sm();
        }

        .ant-tree-node-content-wrapper {
            .mb-flex__child-lg();
        }

        // Hover state for checkbox
        &:hover {
            background-color: @mb-tree-node__checkbox-background;
            cursor: pointer;
            transition: .2s linear all;
        }

        .@{tree-prefix-cls}-node-content-wrapper {
            min-height: @mb-tree-node__content-size;
            line-height: @mb-tree-node__content-size;
            padding: 0 @mb-tree-node__content-spacing-x;
            background-color: transparent;
        }
    }

    // Collapse/expand arrow
    &-switcher {
        width: @mb-tree-node__expand-size;
        line-height: @mb-tree-node__expand-size;
        margin-right: @mb-tree-node__expand-spacing-x;

        .anticon {
            transition: .2s all linear;
            float: left;

            &:after {
                .mbi-chevron-right();
                color: @mb-tree-node__expand-color;
            }

            svg {
                display: none;
            }
        }

        &_open {
            .anticon {
                transform: rotate(-90deg);
            }
        }
    }

    // Checkbox component alignment 
    .@{tree-prefix-cls}-checkbox {
        border-radius :@mb-tree-node__checkbox-border-radius;
        
        top: 0;

        &-wrapper {
            align-items: flex-start;
        }

        &-disabled {
            .@{tree-prefix-cls}-checkbox-inner {
                background-color: @mb-tree-node__checkbox-background--disabled;
                border-color:@mb-tree-node__checkbox-border-color--disabled;
            }

            &.@{tree-prefix-cls}-checkbox-checked {
                .@{tree-prefix-cls}-checkbox-inner {
                    background-color: @mb-tree-node__checkbox-background--checked-disabled;
                    border-color:@mb-tree-node__checkbox-border-color--checked-disabled;
                }
            }
            &.@{tree-prefix-cls}-checkbox-indeterminate {
                .@{tree-prefix-cls}-checkbox-inner {
                    background-color: @mb-tree-node__checkbox-background--checked-disabled;
                    border-color:@mb-tree-node__checkbox-border-color--checked-disabled !important;
                }
            }
        }

        &.@{tree-prefix-cls}-checkbox-indeterminate {
            background-color: transparent;
        }

        // Checkbox default background and border

        &-inner {
            border-radius :@mb-tree-node__checkbox-border-radius;
            border-color: @mb-tree-node__checkbox-border-color;
            width: @mb-tree-node__checkbox-size;
            height: @mb-tree-node__checkbox-size;

            &:after {
                .mbi-check();
                color: @mb-tree-node__checkbox-mark-color;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                border: none;
                transition: none;
            }
        }
    }

    // Checkbox checked behavior
    .@{tree-prefix-cls}-checkbox-checked {
        background-color: @mb-tree-node__checkbox-background--checked;

        .@{tree-prefix-cls}-checkbox-inner {
            background-color: @mb-tree-node__checkbox-background--checked;
            border-color: @mb-tree-node__checkbox-background--checked;
        }
    }

    // Checkbox hover
    .@{tree-prefix-cls}-checkbox {
        &:hover {
            .@{tree-prefix-cls}-checkbox-inner {
                border-color: @mb-tree-node__checkbox-border-color--hover;
            }
        }

        &-checked {
            &:hover {
                .@{tree-prefix-cls}-checkbox-inner {
                    border-color: @mb-tree-node__checkbox-border-color--checked-hover;
                    box-shadow: @mb-tree-node__checkbox-shadow--checked-hover;
                }

                &:after {
                    border-color: @mb-tree-node__checkbox-border-color--checked-hover;
                }
            }
        }
    }

    // Checkbox shape
    .@{tree-prefix-cls}-checkbox {
        &:extend(.ant-checkbox);
    }

    // Indeterminate state - when some children are selected
    .@{tree-prefix-cls}-checkbox-indeterminate {
        background-color: @mb-tree-node__checkbox-background--checked;

        .@{tree-prefix-cls}-checkbox-inner {
            background-color: @mb-tree-node__checkbox-background--checked;
            border-color: @mb-tree-node__checkbox-background--checked;

            &:after {
                content: "";
                transform: translate(-50%, -50%);
                background-color: @mb-tree-node__checkbox-mark-color;
                height: @mb-tree-node__checkbox-mark-indeterminate-height;
                width: @mb-tree-node__checkbox-mark-indeterminate-width;
                border-radius: @mb-tree-node__checkbox-mark-indeterminate-border-radius;
                transition: none;
            }
        }

        &:hover {
            .@{tree-prefix-cls}-checkbox-inner {
                border-color: @mb-tree-node__checkbox-border-color--checked-hover;
                box-shadow: @mb-tree-node__checkbox-shadow--checked-hover;
            }
        }
        &.@{tree-prefix-cls}-checkbox-disabled {
            &:hover {
            	box-shadow: transparent;

            }
        }
    }

    // Disabled states for checkboxes
    .@{tree-prefix-cls}-checkbox {
        &-disabled {
            &.@{tree-prefix-cls}-checkbox-checked {
                .@{tree-prefix-cls}-checkbox-inner {
                    border-color: @mb-tree-node__checkbox-background--checked  !important;

                    &:after {
                        border-color: @mb-tree-node__checkbox-mark-color;
                    }
                }
            }
        }
    }

    // Treenode - checkbox wrapper
    .@{tree-prefix-cls}-treenode-disabled {
        opacity: .5;

        &:hover {
            background: transparent;
        }

        &,
        &.@{tree-prefix-cls}-treenode,
        .@{tree-prefix-cls}-switcher,
        .@{tree-prefix-cls}-checkbox-disabled,
        .@{tree-prefix-cls}-node-content-wrapper {
            cursor: default;
        }
    }
}