// Typography overrides
///////////////////////////////////////////////////////////
// Default text
html,
body {
    font-size: @mb-text-paragraph-size;
    line-height: @mb-text-paragraph-line-height;
}

// Defining common properties for headings, paragraphs and captions
h1,
h2,
h3,
h4,
h5,
h6,
p,
caption,
a {
    font-style: normal;
    font-weight: 500;
    text-align: left;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 0;
}

// Defining letter spacing for headings
h1,
h2 {
    letter-spacing: -0.02em;
}

h3,
h4 {
    letter-spacing: -0.01em;
}

// Defining font sizes and line heights for headings, paragraphs and captions
h1 {
    font-size: @mb-text-h1-size; // 56px
    line-height: @mb-text-h1-line-height; // 68px - identical to box height, or 121%
}

h2 {
    font-size: @mb-text-h2-size; // 40px
    line-height: @mb-text-h2-line-height; // 52px - identical to box height, or 130%
}

h3 {
    font-size: @mb-text-h3-size; // 24px
    line-height: @mb-text-h3-line-height; // 32px - identical to box height, or 133%
}

h4 {
    font-size: @mb-text-h4-size; // 20px
    line-height: @mb-text-h4-line-height; // 20px - identical to box height, or 100%
}

h5 {
    font-size: @mb-text-h5-size; // 16px
    line-height: @mb-text-h5-line-height; // 24px - identical to box height, or 150%
}

p {
    font-weight: 400;
    font-size: @mb-text-paragraph-size; // 14px
    line-height: @mb-text-paragraph-line-height; // 20px - identical to box height, or 143%
}

b,
strong {
    font-weight: 600;
}

caption {
    font-size: @mb-text-caption-size; // 12px
    line-height: @mb-text-caption-line-height; // 16px - identical to box height, or 133%
}

a {
    color: @mb-primary-blue-color;
}

// Highlighting text using mark
mark {
    background-color: @mb-mark-background;
    color: @mb-mark-color;
    padding: 0;

    // Accessibility additions for screen readers
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/mark
    &:before,
    &:after {
        clip-path: inset(100%);
        clip: rect(1px, 1px, 1px, 1px);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }

    &:before {
        content: " [highlight start] ";
    }

    &:after {
        content: " [highlight end] ";
    }
}