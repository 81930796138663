// Custom class that adds minimum width to buttons
.@{btn-prefix-cls}.mb-btn--min-width {
    min-width: 126px;
}

// Custom warning variant
.mb-btn--warning {

    &,
    &.@{btn-prefix-cls}-loading {
        background-color: @mb-btn-warning-background;
        color: @mb-btn-warning-color;
        border-color: transparent;
    }

    &:not(.@{btn-prefix-cls}-loading):hover {
        background-color: @mb-btn-warning-background--hover;
        color: @mb-btn-warning-color--hover;
        border-color: transparent;
    }

    &:not(.@{btn-prefix-cls}-loading):focus {
        background-color: @mb-btn-warning-background--focus;
        color: @mb-btn-warning-color--focus;
        border-color: @mb-btn-warning-border-color--focus;
    }

    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus {
        background-color: @mb-btn-warning-background--disabled;
        color: @mb-btn-warning-color--disabled;
        border-color: transparent;
    }
}

// Custom success variant
.mb-btn--success {

    &,
    &.@{btn-prefix-cls}-loading {
        background-color: @mb-btn-success-background;
        color: @mb-btn-success-color;
        border-color: transparent;
    }

    &:not(.@{btn-prefix-cls}-loading):hover {
        background-color: @mb-btn-success-background--hover;
        color: @mb-btn-success-color--hover;
        border-color: transparent;
    }

    &:not(.@{btn-prefix-cls}-loading):focus {
        background-color: @mb-btn-success-background--focus;
        color: @mb-btn-success-color--focus;
        border-color: @mb-btn-success-border-color--focus;
    }

    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus,
    &.@{btn-prefix-cls}-loading-icon {
        background-color: @mb-btn-success-background--disabled;
        color: @mb-btn-success-color--disabled;
        border-color: transparent;
    }
}

.mb-btn--warning,
.mb-btn--success {

    // Change color for link buttons
    .@{btn-prefix-cls}-loading-icon .anticon:before {
        border-color: @mb-btn-success-color;
    }
}