// Custom timeline class for timelines with compact/styled bullets - DHC license grid
.mb-timeline--compact {

    &:first-child {
        .ant-timeline-item {
            padding-top: @mb-timeline__content-spacing-y;
        }
    }

    .ant-timeline-item {
        padding-bottom: 0;

        &-head {
            left: @mb-timeline-compact__head-position-x;
            top: @mb-timeline-compact__head-position-y;
            width: @mb-timeline-compact__head-size;
            height: @mb-timeline-compact__head-size;
            border: none;
        }

        &-content {
            margin-left: @mb-timeline__content-spacing-x;
            top: 0;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        &-tail {
            height: 100%;
            left: @mb-timeline-compact__tail-position-x;
        }
    }

    .mb-timeline__key {

        .ant-typography-copy {
            margin-left: @mb-spacing[xs];
            color: @mb-primary-blue-color;
        }

        [class^="mbi-"],
        [class*=" mbi-"] {
            font-size: @mb-text-h4-size - 2;
        }
    }

    .ant-typography-copy {
        width: @mb-default-icon-size;
        height: @mb-default-icon-size;
    }

    .mb-timeline-item--version {
        .ant-timeline-item-head {
            opacity: 0;
            visibility: hidden;
        }
    }

    .mb-timeline__subitem {
        width: auto;
        padding: @mb-spacing[xxs] * 1.5 @mb-spacing[xs];
        margin-top: @mb-spacing[xxs];

        &-text {
            max-width: 100px;
        }
    }

    .ant-timeline-item-last>.ant-timeline-item-tail {
        display: block;
    }
}

// Custom class for timelines used on license history
.mb-timeline--history {
    position: relative;

    .ant-timeline-item {
        padding-top: @mb-spacing[xl];
        padding-bottom: 0;
        display: flex;

        &-last>.ant-timeline-item-tail {
            display: block;
        }

        &.mb-timeline-item--even {
            .ant-timeline-item-content {

                >div,
                .mb-timeline-item-content__card {
                    background-color: @mb-timeline-history__card-background;
                    box-shadow: @mb-timeline-history__card-shadow;
                }
            }

            &.mb-timeline-item--child {
                .ant-timeline-item-content {

                    >div,
                    .mb-timeline-item-content__card {
                        border: none;
                    }
                }
            }

            &.mb-timeline-item--child {
                padding-top: @mb-spacing[md];

                .ant-timeline-item-head {
                    top: @mb-spacing[xxs] * 12;
                }

                .ant-timeline-item-label {
                    top: @mb-spacing[xxs] * 11;
                }
            }
        }
    }

    &.ant-timeline-alternate,
    &.ant-timeline-right,
    &.ant-timeline-label {
        .ant-timeline-item-right {
            .ant-timeline-item-content {
                top: 0;
                width: calc(@mb-timeline-history__card-width - 20px);
                text-align: left;
            }
        }
    }

    &.ant-timeline-label {
        .ant-timeline-item-label {
            top: @mb-spacing[xl] * 2;
        }

        .ant-timeline-item-right .ant-timeline-item-label {
            width: calc(100% - @mb-timeline-history__card-width - 12px);
            left: calc(@mb-timeline-history__card-width + 12px);
        }
    }


    &.ant-timeline-alternate,
    &.ant-timeline-right,
    &.ant-timeline-label {

        .ant-timeline-item-head,
        .ant-timeline-item-head-custom {
            left: @mb-timeline-history__card-width;
            top: @mb-spacing[xxs] * 13;
        }

        .ant-timeline-item-tail {
            left: calc(@mb-timeline-history__card-width - 1px);
            top: 0;
            height: 100%;
        }
    }
}