// Tag styled
.@{tag-prefix-cls} {

    // Custom primary variant
    &.mb-tag--primary {
        background-color: @mb-tag-background--primary;
    }

    &.mb-tag--active {

        [class^="mbi-"],
        [class*=" mbi-"] {
            color: @mb-tag-icon-color--active;
        }
    }

    &.mb-tag--interactive {
        cursor: pointer;

        &:hover {
            background-color: @mb-tag-background--interactive-hover;
        }

        &:focus {
            background-color: @mb-tag-background--interactive-focus;
            border-color: @mb-tag-border-color--interactive-focus;
        }

        // Primary variant
        &.mb-tag--primary {
            &:hover {
                background-color: @mb-tag-background--primary-hover;
            }

            &:focus {
                background-color: @mb-tag-background--primary-focus;
                border-color: @mb-tag-border-color--primary-focus;
            }
        }

        // Success variant
        &.@{tag-prefix-cls}-success {
            &:hover {
                background-color: @mb-tag-background--success-hover;
            }

            &:focus {
                background-color: @mb-tag-background--success-focus;
                border-color: @mb-tag-border-color--success-focus;
            }
        }

        // Error variant
        &.@{tag-prefix-cls}-error {
            &:hover {
                background-color: @mb-tag-background--error-hover;
            }

            &:focus {
                background-color: @mb-tag-background--error-focus;
                border-color: @mb-tag-border-color--error-focus;
            }
        }

        // Temporary warning variant
        &.@{tag-prefix-cls}-warning {
            &:hover {
                background-color: @mb-tag-background--warning-hover;
            }

            &:focus {
                background-color: @mb-tag-background--warning-focus;
                border-color: @mb-tag-border-color--warning-focus;
            }
        }

        // Temporary info/processing variant
        &.@{tag-prefix-cls}-processing {
            &:hover {
                background-color: @mb-tag-background--processing-hover;
            }

            &:focus {
                background-color: @mb-tag-background--processing-focus;
                border-color: @mb-tag-border-color--processing-focus;
            }
        }
    }
}