// Logo
///////////////////////////////////////////////////////////
.mb-logo {

    // Logo used in side navigation
    &-navigation {
        width: @mb-logo-width;
        height: @mb-logo-height;
        background: @mb-logo-image-url no-repeat center center;
        background-size: 23.97px 24px;
        border-radius: @mb-logo-border-radius;
    }

    &-md {
        // Logo size for old error pages and login screen
        height: 56px;
        width: auto;
    }
}