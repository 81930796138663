@mb-list__item-spacing-between: @mb-spacing[md];
@mb-list__bullet-margin: @mb-spacing[xxs] / 2 0;
@mb-list__bullet-size: @mb-spacing[xs];
@mb-list__bullet-background: @mb-primary-blue-color;
@mb-list__bullet-border-radius: 50%;

@mb-list__bullet-margin--sm: @mb-spacing[xs] @mb-spacing[md];

.mb-list {
    list-style-type: none;
    padding: 0;

    li {
        display: flex;

        +li {
            margin-top: @mb-list__item-spacing-between;
        }

        span {
            margin: @mb-list__bullet-margin;
            display: inline-block;
        }

        &:before {
            content: "";
            display: inline-block;
            background: @mb-list__bullet-background;
            width: @mb-list__bullet-size;
            height: @mb-list__bullet-size;
            margin: @mb-list__bullet-size;
            border-radius: @mb-list__bullet-border-radius;

            //.mb-flex__child-sm
            flex-basis: auto;
            flex-shrink: 0;
            flex-grow: 0;
            min-height: 0;
            min-width: 0;
        }
    }

    &--small {
        li {
            +li {
                margin-top: 0;
            }

            &:before {
                margin: @mb-list__bullet-margin--sm;
            }
        }
    }
}