@mb-progress-color-default: @mb-text-primary-color;

@mb-progress__circle-color: @mb-progress-color-default;

@mb-progress__circle-path-color--success: @mb-emerald[200];

@mb-progress__circle-color--exception: @mb-error-variant-color[25];
@mb-progress__circle-path-color--exception: @mb-error-variant-color[25];

@mb-progress__circle-color--warning: @mb-yellow[200];
@mb-progress__circle-path-color--warning: @mb-warning-color;


.mb-progress {
    &-circle {
        color: @mb-progress__circle-color;

        &.@{progress-prefix-cls}-status-exception,
        &.@{progress-prefix-cls}-status-success {
            .ant-progress-text {
                color: @mb-progress__circle-color;
            }
        }

        &--exception-filled {
            .@{progress-prefix-cls}-inner {
                background-color: @mb-progress__circle-color--exception;
            }

            svg.ant-progress-circle {
                display: none;
            }
        }

        &--warning {
            .ant-progress-inner .ant-progress-circle {
                .ant-progress-circle-trail {
                    stroke: @mb-progress__circle-color--warning;
                }

                .ant-progress-circle-path {
                    stroke: @mb-progress__circle-path-color--warning;

                }
            }
        }
    }
}

.@{progress-prefix-cls}-status-success {
    .@{progress-prefix-cls}-circle-trail {
        stroke: @mb-progress__circle-path-color--success;
    }
}

.@{progress-prefix-cls}-status-exception {
    .@{progress-prefix-cls}-circle-trail {
        stroke: @mb-progress__circle-path-color--exception;
    }
}