// Custom spinner with SVGs
.mb-spin {
    display: block;
    position: relative;
    width: @mb-spinner-size;
    height: @mb-spinner-size;

    // Align both SVGs and set same base color and stroke
    svg {
        position: absolute;
        left: 0;
        top: 0;
        width: inherit;
        height: inherit;
        stroke: @mb-spinner-color;
        stroke-linecap: @mb-spinner-stroke-linecap;
        stroke-width: @mb-spinner-stroke-width;
        fill: transparent;
    }

    // Class for spinning SVG
    &__animated {
        animation: mb-spin 1s linear infinite;
    }

    // Small spinner size
    &--sm {
        width: @mb-spinner-sm-size;
        height: @mb-spinner-sm-size;

        svg {

            stroke-width: @mb-spinner-sm-stroke-width;
        }
    }

    // Large spinner size
    &--lg {
        width: @mb-spinner-lg-size;
        height: @mb-spinner-lg-size;

    }
}