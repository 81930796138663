@import './components';
@import './status';
@import './mixin';
@import './_custom.less';


// ==============================================================
// =                            Label                           =
// ==============================================================
.@{form-item-prefix-cls} {
    &-label {
        >label {

            &.@{form-item-prefix-cls}-required,
            &.@{form-item-prefix-cls}-required:not(.ant-form-item-required-mark-optional) {
                position: relative;
                width: 100%;

                &::before {
                    content: 'Required';
                    display: block;
                    position: absolute;
                    top: 0;
                    right: 0;
                    margin-right: 0;
                    font-family: @font-family;
                    line-height: @mb-label-line-height;
                    color: @mb-label-color;

                    .@{form-prefix-cls}-hide-required-mark & {
                        display: none;
                    }
                }
            }
        }
    }
}

// Input icons
.@{ant-prefix}-input {
    box-shadow: @mb-input-shadow;
}

.ant-select-single,
.ant-picker,
.ant-select,
.ant-input-number, .ant-input {
    box-shadow: @mb-shadow-base;
    border-radius: @mb-input-border-radius;
}