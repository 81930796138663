.mb-avatar {

    // Custom class that sets avatar width to 100%
    &--full {
        .avatar-size(100%, @mb-avatar-font-size--full-width);
        position: relative;
        height: auto;

        &:before {
            content: "";
            display: block;
            padding-top: 100%;
        }

        >span {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }

        >img {
            position: absolute;
            left: 0;
            top: 0;
        }

        [class^="mbi-"],
        [class*=" mbi-"] {
            &:before {
                position: absolute;
                top: 50%;
                left: 50%;
                font-size: inherit;
                transform: translate(-50%, -50%);

                @media screen and (min-width: 768px) {
                    font-size: @mb-avatar__icon-size--full-width;
                }
            }
        }

        &:not(.mb-avatar--xs),
        &:not(.mb-avatar--user-profile) {
            font-size: 20vw;
        }
    }

    // Custom class for very small avatars
    &--xs {
        &.ant-avatar-icon {
            width: @mb-avatar-width--xs;
            height: @mb-avatar-height--xs;
            font-size: @mb-avatar-font-size--xs;
            line-height: @mb-avatar-line-height--xs;
        }
    }


    // Primary brand color as avatar color
    &--brand {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: @mb-avatar-background--brand;
        border: @mb-avatar-border-size-default solid @mb-avatar-border-color--brand;
    }

    // Custom statuses
    // --------------------
    // Success outline
    &--success {
        &-outline {
            color: @mb-avatar-color--success-outline;
            border: @mb-avatar-border-size-default solid @mb-avatar-color--success-outline;
            background: transparent;
        }
    }

    // Default outline
    &--default {
        &-outline {
            color: @mb-avatar-color--default-outline;
            border: @mb-avatar-border-size-default solid @mb-avatar-color--default-outline;
            background: transparent;
        }
    }

    // Default outline
    &--primary {
        color: @mb-avatar-color--primary;
        border: @mb-avatar-border-size-default solid transparent;
        background-color: @mb-avatar-background--primary;
    }

    // --------------------

    // Avatars used as user profile picture
    &--user-profile {
        background-color: transparent;
        background: @mb-avatar__user-profile-image transparent center center no-repeat;
        background-size: cover;
    }

    //
    // Local avatar classes for demo and documentation cards
    &-card {
        width: 48px;
        height: 48px;
        padding: 0;
        background-color: @mb-gray[100];
        border-radius: 50%;
        text-align: center;
        color: @mb-primary-deepblue-color;

        >span {
            font-size: 24px;
            line-height: 48px;
        }

        &--blue {
            color: @mb-gray[600];
            background-color: @mb-cobalt[100];
        }

        &--orange {
            color: @mb-gray[600];
            background-color: @mb-yellow[100];
        }

        &__chevron {
            font-size: 18px;
            line-height: 20px;
        }
    }
}