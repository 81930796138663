// Custom classes for form components

// Custom class for expanding inputs to 100% width
.mb-input {
    &--cover-width {
        width: 100%;
    }
}

// Custom class for vertical radio button group
.mb-radio-group {
    &--vertical {
        .ant-radio-wrapper {
            display: flex;
            width: 100%;

            + .ant-radio-wrapper {
                margin-top: @mb-spacing[xs];
            }
        }
    }
}

// Expand form item to 100% height
.mb-form-item--cover-height {
    &,
    .ant-form-item-control-input,
    .ant-form-item-control-input-content {
        height: 100%;
    }
}