// Dark theme custom tooltip
.mb-tooltip {
    &--dark {
        padding-left: @mb-spacing[xl];

        .@{tooltip-prefix-cls} {

            transform: translateX(16px);

            // Change bubble's style to match Figma
            &-inner {
                box-shadow: @mb-tooltip-dark-shadow;
                border: @mb-tooltip-dark-border-width solid @mb-tooltip-dark-border-color;
                background-color: @mb-tooltip-dark-background;
                color: @mb-tooltip-dark-color;
                line-height: @mb-tooltip-dark-line-height;

                // Define the light mimic arrow
                &:after {
                    background-color: @mb-tooltip-dark-background;
                }
            }

            &-arrow {
                left: 16px;

                &-content {
                    background-color: transparent;
                }
            }

            // Remove shadow from the original arrow and replace it
            // with one that imitates borders
            &-placement-top &-arrow,
            &-placement-topLeft &-arrow,
            &-placement-topRight &-arrow,
            &-placement-right &-arrow,
            &-placement-rightTop &-arrow,
            &-placement-rightBottom &-arrow,
            &-placement-left &-arrow,
            &-placement-leftTop &-arrow,
            &-placement-leftBottom &-arrow,
            &-placement-bottom &-arrow,
            &-placement-bottomLeft &-arrow,
            &-placement-bottomRight &-arrow {

                &-content {
                    box-shadow: @mb-tooltip__arrow-shadow;
                }
            }
        }
    }
}